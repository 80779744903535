<template :key="">
  <v-dialog v-model="isOpen" :persistent="persistent" :width="700">
    <v-card class="pa-6">
      <!-- <v-card-title style="font-size: 32px">{{ title }}</v-card-title> -->
      <v-row>
        <v-col>
          <span>{{ title }}</span>
        </v-col>
      </v-row>
      <v-form v-model="validForm" ref="formValidation">
        <input-row
          class="pl-6 pt-4 pb-4"
          :inTitle="$tc('adminComponents.inPassword')"
          v-model="modalData.password"
          :rules="[password]"
        ></input-row
      ></v-form>
      <!-- TODO: MAKE INTO A SELECT -->
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn color="primary" outlined block @click="closeModal">
              {{ $tc("general.cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn color="primary" block @click="submitResetPassword">
              {{ $tc("general.saveChanges") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { adminService } from "@/services/api/admin.service";
import { Component, Vue, Prop, VModel } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { UserResetPassword } from "@/models/entities/admin/admin.interface";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
import { required, password } from "@/helpers/rules";
const snackbarModule = getModule(SnackbarModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component
export default class DialogUserNew extends Vue {
  @VModel({ default: false })
  isOpen: boolean | undefined;
  // Defines wether dialog is persistent.
  @Prop({ required: false, type: Boolean, default: false })
  persistent: boolean | undefined;

  @Prop({ required: false, type: String, default: "" })
  title!: string;

  // Defines variables, case edit.
  @Prop({ required: true, type: Number })
  _userId: number;

  modalData = {} as UserResetPassword;
  validForm = false;
  password = password;

  // Emitted events:
  async created(): Promise<void> {
    let data = await adminService.getDefaultPassword();
    let defaultPassword = data.data;
    this.modalData = {
      userId: this._userId,
      password: defaultPassword,
      oldPassword: "",
    };
  }

  closeModal(): void {
    this.isOpen = false;
    this.$emit("closeModal");
  }

  async submitResetPassword(): Promise<void> {
    if (this.validForm) {
      try {
        overlayModule.showOverlay();
        await adminService.usersResetPasswordAdmin(this.modalData);
        overlayModule.hideOverlay();
        snackbarModule.appearSnackbar({
          message: this.$tc("general.savedSuccess"),
          type: "success",
        });
        this.closeModal();
      } catch {
        overlayModule.hideOverlay();
      }
    } else {
      snackbarModule.appearSnackbar({
        message: this.$tc("userManagement.passwordControl"),
        type: "error",
      });
    }
  }
}
</script>
