<template>
  <div>
    <v-row>
      <v-col cols="12" class="pb-12">
        <title-bar
          :title="$tc('smallMaintenance.title')"
          :is-parent-element="false"
          :forced-previous-component="'maintenance'"
        ></title-bar>
      </v-col>
      <div class="header-container">
        <v-col cols="3" style="display: contents">
          <select-row
            style="margin: 0"
            v-model="selectedDepartment"
            :inTitle="''"
            :label="$tc('smallMaintenance.DropdownDepartmentsPlaceholder')"
            :inItems="departments"
            hideTitle
            :padding-zero="true"
            :clearable="true"
            :return-object="true"
            :item-text="'denominazione'"
            :item-value="'codreparto'"
            @inChanged="loadAssetsByDepartment"
            @click:clear="assetList = []"
          ></select-row
        ></v-col>
        <v-row no-gutters justify="center" class="mt-6 px-4">
          <v-col cols="6">
            <v-row no-gutters justify="center">
              <input-center
                style="width: 450px"
                need-label
                :inPlaceHolder="
                  $tc('smallMaintenance.LocationTextFieldPlaceholder')
                "
                v-model="requestAssetsByDepartment.location"
                type="number"
                @onBlur="loadAssetsByDepartment"
              ></input-center>
            </v-row>
          </v-col>
          <v-col cols="6">
            <select-row
              style="margin: 0; justify-content: center"
              v-model="requestCompilation.assetId"
              :inTitle="''"
              :label="$tc('smallMaintenance.DropdownAssetsPlaceholder')"
              :inItems="assetList"
              hideTitle
              :padding-zero="true"
              :clearable="true"
              @inChanged="getPlanByAsset"
              @click:clear="requestCompilation.smallMaintenancePlan = null"
            ></select-row>
          </v-col>
        </v-row>
        <v-row no-gutters justify="center">
          <v-col :cols="isDesktop ? 9 : 11" class="px-4">
            <v-checkbox
              class="mt-0"
              style="max-width: fit-content"
              hide-details
              :label="$tc('smallMaintenance.PlumbingPlanLabel')"
              v-model="requestAssetsByDepartment.plumbingPlan"
              @change="loadAssetsByDepartment"
            ></v-checkbox
          ></v-col>
        </v-row>
        <v-row no-gutters justify="center" class="px-4">
          <v-col cols="6">
            <select-row
              style="margin: 0; justify-content: center"
              v-model="requestCompilation.smallMaintenancePlan"
              :inTitle="''"
              :label="$tc('smallMaintenance.SmallMaintenancePlanPlaceholder')"
              :inItems="planItems"
              hideTitle
              item-text="descrizione"
              item-value="idPicMan"
              :padding-zero="true"
              :clearable="true"
              @inChanged="changeDescription"
              @click:clear="requestCompilation.smallMaintenancePlan = null"
            ></select-row>
          </v-col>
          <v-col cols="6">
            <v-row no-gutters justify="center">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div style="width: 450px">
                    <v-text-field
                      v-model="planDateToShow"
                      :label="$tc('smallMaintenance.PlanDatePlaceholder')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </div>
                </template>
                <v-date-picker
                  v-model="requestCompilation.planDate"
                  :first-day-of-week="1"
                  locale="it-IT"
                  @input="menu = false"
                ></v-date-picker>
                <!--                  :allowed-dates="limitAllowedDate"-->
              </v-menu>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters justify="end">
          <v-col cols="6">
            <v-row justify="center">
              <v-btn x-large color="primary" @click="submitSelection">{{
                $tc("general.confirm")
              }}</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-row>
    <choice-plan-dialog
      v-if="showChoicePlanDialog"
      v-model="showChoicePlanDialog"
      :plans="planItems"
      @onChoicePlan="onChoicePlan"
    ></choice-plan-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Ref, Vue } from "vue-property-decorator";
import { DepartmentItemInterface } from "@/models/entities/maintenance/departmentItem.interface";
import {
  AssetsByDepartmentRequest,
  CheckAllowPlanCompilationRequest,
} from "@/models/entities/maintenance/smallMaintenanceRequests.interface";
import { maintenanceService } from "@/services/api/maintenance.service";
import ChoicePlanDialog from "@/components/smallMaintenance/ChoicePlanDialog.vue";
import { SmallMaintenancePlan } from "@/models/entities/maintenance/smallMaintenancePlan.interface";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
import { SmallMaintenanceHeader } from "@/models/entities/maintenance/smallMaintenanceHeader.interface";
import AuthModule from "@/store/modules/auth.module";
import MaintenanceModule from "@/store/modules/maintenance.module";
import SelectRow from "@/components/SelectRow.vue";

const snackbarModule = getModule(SnackbarModule, store);
const authModule = getModule(AuthModule, store);
const maintenanceModule = getModule(MaintenanceModule, store);

@Component({
  components: { ChoicePlanDialog },
})
export default class LgSmallMaintenance extends Vue {
  @Ref("planDescription")
  refPlanDescription: InstanceType<typeof SelectRow>;

  departments: DepartmentItemInterface[] = [];
  selectedDepartment: DepartmentItemInterface = null;
  requestAssetsByDepartment: AssetsByDepartmentRequest = {
    location: null,
    plumbingPlan: null,
  };
  planDescription = "";
  requestCompilation: CheckAllowPlanCompilationRequest = {
    assetId: "",
    smallMaintenancePlan: null,
    planDate: new Date().toISOString().split("T")[0],
  };
  assetList: string[] = [];
  planItems: SmallMaintenancePlan[] = [];
  menu = false;

  showChoicePlanDialog = false;

  get planDateToShow(): string {
    const date = new Date(this.requestCompilation.planDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Mese con zero iniziale
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}/${month}/${year}`;
  }

  get isDesktop(): boolean {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  async created() {
    const response = await maintenanceService.getDepartmentsByUser();
    if (response.data.length === 1) {
      this.selectedDepartment = response.data[0];
      this.loadAssetsByDepartment();
    }
    this.departments = response.data;
  }

  async loadAssetsByDepartment(): Promise<void> {
    if (!this.selectedDepartment?.codreparto) {
      return;
    }
    const response =
      await maintenanceService.getAssetsByDepartmentSmallMaintenance(
        this.selectedDepartment.codreparto,
        this.requestAssetsByDepartment
      );
    this.assetList = response.data;
    this.resetAssetIfNotPresent();
  }

  resetAssetIfNotPresent() {
    if (!this.assetList) {
      this.assetList = [];
    }
    const idx = this.assetList.findIndex(
      (el) => el === this.requestCompilation.assetId
    );

    if (idx === -1) {
      this.requestCompilation.assetId = "";
      this.requestCompilation.smallMaintenancePlan = null;
    }
  }

  async getPlanByAsset() {
    const response = await maintenanceService.getSmallMaintenancePlan(
      this.requestCompilation.assetId
    );
    if (response.status === 204) {
      snackbarModule.appearSnackbar({
        message: this.$tc("smallMaintenance.PlanNotFound"),
        type: "error",
      });
      this.requestCompilation.smallMaintenancePlan = null;
      return;
    }
    this.planItems = response.data;
    if (response.data?.length === 1) {
      this.planDescription = response.data[0].descrizione;
      this.requestCompilation.smallMaintenancePlan = response.data[0].idPicMan;
    } else {
      this.showChoicePlanDialog = true;
    }
  }

  onChoicePlan(plan: SmallMaintenancePlan) {
    this.requestCompilation.smallMaintenancePlan = plan.idPicMan;
    this.planDescription = plan.descrizione;
    this.showChoicePlanDialog = false;
  }

  async submitSelection() {
    if (
      !this.requestCompilation.assetId ||
      !this.requestCompilation.smallMaintenancePlan
    ) {
      snackbarModule.appearSnackbar({
        message: this.$tc("smallMaintenance.MissingRequiredFields"),
        type: "error",
      });
    } else {
      const canCompilePlan = await maintenanceService.checkAllowPlanCompilation(
        this.requestCompilation
      );
      if (canCompilePlan) {
        this.insertIfNotExists();
      }
    }
  }

  async insertIfNotExists() {
    await maintenanceService.insertIfNotExists(this.requestCompilation);
    const header: SmallMaintenanceHeader = {
      ...this.requestCompilation,
      resourceType: this.planDescription,
      user: authModule.userName,
    };
    maintenanceModule.setSmallMaintenanceHeader(header);
    this.$router.push({
      name: "smallMaintenancePlan",
    });
  }

  changeDescription() {
    this.planDescription = this.planItems.find(
      (el) => el.idPicMan === this.requestCompilation.smallMaintenancePlan
    ).descrizione;
  }
  //
  // limitAllowedDate(val): boolean {
  //   const today = new Date();
  //   //Testing con data prima del 10 del mese
  //   // const fourDaysAgo = new Date(today);
  //   // fourDaysAgo.setDate(today.getDate() - 4);
  //   const isGreaterThan10 = today.getDate() >= 10;
  //   const startLimit = this.getStartLimit(today, isGreaterThan10);
  //   const endLimit = this.getEndLimit(today);
  //   return val >= startLimit && val <= endLimit;
  // }
  //
  // getStartLimit(today: Date, isGreaterThan10: boolean): string {
  //   const startingMonth = isGreaterThan10
  //     ? today.getMonth()
  //     : today.getMonth() - 1;
  //   const firstDay = new Date(
  //     today.getFullYear(),
  //     startingMonth,
  //     1
  //   ).toLocaleString();
  //   let [day, month, year] = firstDay.slice(0, 10).split("/");
  //   return year + "-" + month + "-" + day;
  // }
  //
  // getEndLimit(today: Date): string {
  //   const lastDay = new Date(
  //     today.getFullYear(),
  //     today.getMonth() + 1,
  //     0
  //   ).toLocaleString();
  //   const [day, month, year] = lastDay.slice(0, 10).split("/");
  //   return year + "-" + month + "-" + day;
  // }
}
</script>

<style scoped>
.header-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}
</style>
