var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-content',{ref:"orientationDiv",style:({
    background: _vm.isOrderButtonsOn ? '#cccccc' : '',
    padding: 0,
  })},[(_vm.isOrderButtonsOn)?_c('draggable',{staticClass:"row",attrs:{"group":"buttons","sort":_vm.isOrderButtonsOn},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.buttonListEnabled),callback:function ($$v) {_vm.buttonListEnabled=$$v},expression:"buttonListEnabled"}},_vm._l((_vm.buttonListEnabled),function(btn,i){return _c('v-col',{key:btn.id,staticStyle:{"text-align":"center"},attrs:{"cols":_vm.isOrientationPortrait ? 4 : 3}},[_c('v-img',{key:i,attrs:{"src":btn.id == 'MSA'
            ? require('@/assets/msa.svg')
            : btn.id == 'SAP'
            ? require('@/assets/sap.svg')
            : btn.id == 'USR'
            ? require('@/assets/usr.svg')
            : btn.id == 'SGQ'
            ? require('@/assets/SGQ.png')
            : btn.id == 'ST'
            ? require('@/assets/Schede tecniche.png')
            : btn.id == 'MNT'
            ? require('@/assets/MANUTENZIONE.png')
            : btn.id == 'HSE'
            ? require('@/assets/HSE.png')
            : btn.id == 'BO'
            ? require('@/assets/BUSINESS OBJECT.png')
            : btn.id == 'STAIN'
            ? require('@/assets/DASHBOARD STAIN.png')
            : btn.id == 'WEB'
            ? require('@/assets/STUDIOWEB.png')
            : btn.id == 'UTILITY'
            ? require('@/assets/UTILITY.png')
            : btn.id == 'EQPMNG'
            ? require('@/assets/GESTIONE ATTREZZATURE.png')
            : ''}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$tc(btn.t)))])],1)}),1):_vm._e(),(!_vm.isOrderButtonsOn)?_c('v-row',_vm._l((_vm.buttonListEnabled),function(btn,i){return _c('v-col',{key:btn.id,staticStyle:{"text-align":"center"},attrs:{"cols":_vm.isOrientationPortrait ? 4 : 3},on:{"click":function($event){return _vm.goToPage(btn.r, btn.id)}}},[_c('v-img',{key:i,attrs:{"src":btn.id == 'MSA'
            ? require('@/assets/msa.svg')
            : btn.id == 'SAP'
            ? require('@/assets/sap.svg')
            : btn.id == 'USR'
            ? require('@/assets/usr.svg')
            : btn.id == 'SGQ'
            ? require('@/assets/SGQ.png')
            : btn.id == 'ST'
            ? require('@/assets/Schede tecniche.png')
            : btn.id == 'MNT'
            ? require('@/assets/MANUTENZIONE.png')
            : btn.id == 'HSE'
            ? require('@/assets/HSE.png')
            : btn.id == 'BO'
            ? require('@/assets/BUSINESS OBJECT.png')
            : btn.id == 'STAIN'
            ? require('@/assets/DASHBOARD STAIN.png')
            : btn.id == 'WEB'
            ? require('@/assets/STUDIOWEB.png')
            : btn.id == 'UTILITY'
            ? require('@/assets/UTILITY.png')
            : btn.id == 'EQPMNG'
            ? require('@/assets/GESTIONE ATTREZZATURE.png')
            : ''}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$tc(btn.t)))])],1)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }