import { FileResponse } from "@/models/entities/fileFormats/fileResponse.interface";
import {
  ProductFilterItem,
  ProductSearchItem,
  ProductCheckItem,
  ProductFilterRequest,
  ProductItemElabbed,
  ProductFileSearchItem,
} from "@/models/entities/product/product.interface";
import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";

const CONTROLLER_BASE = "product";
const CONTROLLER_FILTER = CONTROLLER_BASE + "/filters";
class ProductService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }

  getFiltersSuppliers() {
    return this.getAll<string[]>(`${CONTROLLER_FILTER}/getsuppliers`);
  }

  getFiltersProducts(supplier: string) {
    return this.getAll<ProductFilterItem[]>(
      `${CONTROLLER_FILTER}/getproducts?supplier=${supplier}`
    );
  }

  getChecksFilters(checkRequest: ProductFilterRequest) {
    return this.getAll<ProductCheckItem>(
      `${CONTROLLER_FILTER}/getchecks?productId=${checkRequest.productId}&supplier=${checkRequest.supplier}`
    );
  }

  getProducts(searchItem: ProductSearchItem) {
    return this.post<ProductSearchItem, ProductItemElabbed[]>(
      `${CONTROLLER_BASE}/getproducts`,
      searchItem
    );
  }

  getFilePath(item: ProductFileSearchItem) {
    return this.post<ProductFileSearchItem, FileResponse>(
      `${CONTROLLER_BASE}/getFilePath`,
      item
    );
  }
}

export const productService = new ProductService();
