<template>
  <v-row no-gutters>
    <span> {{ transcript }}</span>
    <v-btn @click="toggleSpeech">{{ isListening ? "end" : "start" }}</v-btn>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { useSpeechRecognition } from "@vueuse/core";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";

const snackbarModule = getModule(SnackbarModule, store);

@Component({})
export default class SpeechToText extends Vue {
  speechRecognition = useSpeechRecognition({
    lang: "it-IT",
  });

  toggleSpeech() {
    if (!this.isSupported) {
      snackbarModule.appearSnackbar({
        message: this.$tc("speechToText.NotSupported"),
        type: "error",
      });
    }
    setTimeout(() => {
      if (this.isListening) this.speechRecognition.stop();
      else this.speechRecognition.start();
    }, 0);
  }

  get isSupported() {
    return this.speechRecognition.isSupported;
  }

  get isListening(): boolean {
    return this.speechRecognition.isListening;
  }

  get transcript() {
    return this.speechRecognition.result;
  }
}
</script>

<style scoped></style>
