<template>
  <v-row>
    <v-col cols="4">
      <v-icon
        v-if="iconName"
        :size="38"
        class="mod--icon mr-2"
        :color="iconColor"
      >
        {{ iconName }}
      </v-icon>
      <strong
        :style="
          $vuetify.breakpoint.mdAndDown ? 'font-size: 26px' : 'font-size: 18px'
        "
      >
        {{ inTitle }}</strong
      >
    </v-col>
    <v-col cols="8">
      <v-text-field
        v-model="valueModel"
        :label="inPlaceHolder"
        solo
        outlined
        :readonly="inIsReadonly"
        @input="inChanged"
        :rules="rules"
        hide-spin-buttons
        hint=""
        :type="inType"
        :class="
          $vuetify.breakpoint.mdAndDown
            ? 'mod--input; v-text-field--filled-big'
            : 'mod--input; v-text-field--filled-small'
        "
        :height="$vuetify.breakpoint.mdAndDown ? 96 : 40"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Vue, Prop, VModel } from "vue-property-decorator";
@Component
export default class InputRow extends Vue {
  @VModel({ default: null })
  valueModel: undefined;
  // Defines input title.
  @Prop({ required: true, type: String, default: "" })
  private inTitle: string | undefined;
  // Defines input placeholder.
  // TODO: MAKE AS i18n STRING
  @Prop({ required: false, type: String, default: "Inserisci un valore" })
  private inPlaceHolder: string | undefined;
  // Defines input type.
  @Prop({ required: false, type: String, default: "text" })
  private inType: string | undefined;
  // Defines if input is readonly.
  @Prop({ required: false, type: Boolean, default: false })
  private inIsReadonly: boolean | undefined;
  // Defines icon name & image.
  @Prop({ required: false, type: String, default: "" })
  private iconName: string | undefined;
  // Defines icon color.
  @Prop({ required: false, type: String, default: "primary" })
  private iconColor: string | undefined;
  // Defines rules on input.
  @Prop({ required: false, type: Array, default: () => [] })
  private rules!: Array<(v: string) => string | boolean>;

  private togglePassword = false;

  private inChanged(): void {
    this.$emit("inChanged");
  }
}
</script>
<style lang="scss">
.v-text-field--filled-small > .v-input__control > .v-input__slot {
  min-height: 18px;
  border-radius: 24px;
  box-shadow: #{0px 2px 4px};
}
.v-text-field--filled-big > .v-input__control > .v-input__slot {
  border-radius: 24px;
  box-shadow: #{0px 2px 4px};
  font-size: 24px;
}
</style>
