import {
  GroupAuthInfoElab,
  RequestResetPasswordByRecovery,
  RolesItem,
  UserAuthInfoElab,
  UserResetPassword,
} from "@/models/entities/admin/admin.interface";
import { GroupEditItem } from "@/models/entities/admin/groupItem.interface";
import { Plant } from "@/models/entities/admin/plant.interface";
import { UserCreateItem } from "@/models/entities/admin/userItem.interface";
import { AuthItemNamed } from "@/models/entities/auth/authItem.interface";
import { AuthorizationsUserUpdateRequest } from "@/models/entities/auth/authRequest.interface";

import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";
import { UserImportResponseInterface } from "@/models/entities/admin/UserImportResponse.interface";
import { UserManagementFiltersInterface } from "@/models/entities/admin/UserManagementFilters.interface";
import {
  BaseDepartment,
  UserDepartmentBaseItem,
  UserDepartmentEditItem,
} from "@/models/entities/admin/Departments/DepartmentsItems.interface";
import { DepartmentItemInterface } from "@/models/entities/maintenance/departmentItem.interface";

const CONTROLLER = "admin";
const CONTROLLER_AUTH = "authorizations";
const CONTROLLER_USER = `${CONTROLLER}/users`;
const CONTROLLER_GROUP = `${CONTROLLER}/groups`;
const CONTROLLER_PLANS = `${CONTROLLER}/plans`;
const CONTROLLER_DEPARTMENTS = `${CONTROLLER}/departments`;
class AdminService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }
  // Region users:

  usersCreate(item: UserCreateItem) {
    return this.post<UserCreateItem, number>(`${CONTROLLER_USER}/create`, item);
  }
  usersResetPassword(item: UserResetPassword) {
    return this.post<UserResetPassword, void>(
      `${CONTROLLER_USER}/reset_password`,
      item
    );
  }

  usersResetPasswordAdmin(item: UserResetPassword) {
    return this.post<UserResetPassword, void>(
      `${CONTROLLER_USER}/reset_passwordadmin`,
      item
    );
  }
  usersDelete(userId: number) {
    return this.delete<number, void>(`${CONTROLLER_USER}/delete`, userId);
  }
  usersEdit(item: UserCreateItem) {
    return this.post<UserCreateItem, void>(`${CONTROLLER_USER}/edit`, item);
  }
  usersUpdateAuths(item: AuthorizationsUserUpdateRequest[]) {
    return this.post<AuthorizationsUserUpdateRequest[], void>(
      `${CONTROLLER_USER}/updateAuths`,
      item
    );
  }
  // Region groups:
  groupsGetAll() {
    return this.getAll<GroupEditItem[]>(`${CONTROLLER_GROUP}/getall`);
  }
  // Region page management:
  getGroupDefault() {
    return this.getAll<GroupAuthInfoElab[]>(`${CONTROLLER}/groupsgetall`);
  }
  getUserDefault(filters: UserManagementFiltersInterface) {
    return this.getAll<UserAuthInfoElab[]>(`${CONTROLLER}/usersgetall`, {
      params: filters,
    });
  }
  getRoles() {
    return this.getAll<RolesItem[]>(`${CONTROLLER}/rolesgetall`);
  }
  // Get auths:
  getAllAuths() {
    return this.getAll<AuthItemNamed[]>(`${CONTROLLER_AUTH}/getAll`);
  }

  getAllPlants() {
    return this.getAll<Plant[]>(`${CONTROLLER_PLANS}/getAll`);
  }

  getPlanById(userId: number) {
    return this.getAll<number>(
      `${CONTROLLER_PLANS}/getPlanById?userId=${userId}`
    );
  }

  deleteGroup(groupId: number) {
    return this.delete<number, void>(`${CONTROLLER_AUTH}/deleteGroup`, groupId);
  }

  getDefaultPassword() {
    return this.getAll<string>(`${CONTROLLER}/getDefaultPassword`);
  }

  saveDefaultPassword(defaultPassword: string) {
    return this.post<string, void>(
      `${CONTROLLER}/saveDefaultPassword`,
      defaultPassword,
      { headers: { "Content-Type": "application/json" } }
    );
  }

  exportUsers(filters: UserManagementFiltersInterface) {
    return this.getAll<ArrayBuffer>(`${CONTROLLER}/export`, {
      params: filters,
      responseType: "arraybuffer",
    });
  }

  importUsers(file: any) {
    const form = new FormData();
    form.append("file", file);
    return this.post<FormData, UserImportResponseInterface>(
      `${CONTROLLER}/import`,
      form
    );
  }

  resetPasswordByRecovery(request: RequestResetPasswordByRecovery) {
    return this.post<RequestResetPasswordByRecovery, void>(
      `${CONTROLLER_USER}/resetpasswordbyrecovery`,
      request
    );
  }

  getAllUserDepartments() {
    return this.getAll<UserDepartmentBaseItem[]>(
      `${CONTROLLER_DEPARTMENTS}/getall`
    );
  }

  getDepartmentsByUser(userId: number) {
    return this.getAll<BaseDepartment[]>(
      `${CONTROLLER_DEPARTMENTS}/getbyuser/${userId}`
    );
  }

  insertOrUpdateDepartment(item: UserDepartmentEditItem) {
    return this.post<UserDepartmentEditItem, null>(
      `${CONTROLLER_DEPARTMENTS}/insertorupdate`,
      item
    );
  }

  deleteDepartment(userId: number, departmentId: number) {
    return this.delete<number, void>(
      `${CONTROLLER_DEPARTMENTS}/remove/${userId}`,
      departmentId
    );
  }

  loadDepartments() {
    return this.getAll<DepartmentItemInterface[]>(`${CONTROLLER_DEPARTMENTS}`);
  }
}

export const adminService = new AdminService();
