<template>
  <v-row v-if="userDepartments.length > 0">
    <v-col class="px-0">
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :items="userDepartments"
        :items-per-page="10"
        :class="
          $vuetify.breakpoint.mdAndDown
            ? 'elevation-1 mod--table_admin'
            : 'elevation-1 mod--table_admin v-data-table-small'
        "
      >
        <!-- Departments list -->
        <template v-slot:[`item.departmentsList`]="{ item }">
          <div style="display: flex; flex-direction: row; gap: 12px">
            <v-chip
              v-for="(department, idx) in item.departments"
              :key="idx"
              :style="{
                background: '#5dacf5',
              }"
              >{{ department.codreparto }}-{{
                department.denominazione
              }}</v-chip
            >
          </div>
        </template>
        <!-- Show departments -->
        <template v-slot:[`item.actionsDepartments`]="{ item }">
          <v-btn
            text
            @click="openUserDepartmentsDialog(item)"
            class="mod--btn_text pl-0"
          >
            {{ $tc("userManagement.btnDetails") }}
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog
      v-model="showUserDepartmentsDialog"
      :width="700"
      @click:outside="loadUserDepartments"
    >
      <v-card class="pa-6">
        <v-card-title style="padding: 16px">
          <v-row no-gutters>
            <v-col cols="3" align-self="center">
              <span>
                {{ $tc("userManagement.DepartmentTabDialogTitle") }}
              </span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" align="right">
              <v-btn
                color="primary"
                @click="toggleAddDepartmentDialog(false)"
                >{{ $tc("userManagement.btnAddDepartment") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-row no-gutters>
          <v-col style="padding: 16px">
            <v-data-table
              :headers="headersUserDepartments"
              :items="departmentsByUser"
              :items-per-page="5"
            >
              <template v-slot:[`item.actionsCRUD`]="{ item }">
                <div style="display: flex; flex-direction: row; gap: 12px">
                  <v-icon color="red" @click="openDeleteDialog(item)">
                    mdi-delete
                  </v-icon>
                  <v-icon @click="toggleAddDepartmentDialog(true, item)">
                    mdi-pencil
                  </v-icon>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showAddDepartmentDialog"
      v-model="showAddDepartmentDialog"
      :width="700"
    >
      <v-card class="pa-6">
        <v-card-title style="padding: 16px">
          {{ departmentDialogTitle }}
        </v-card-title>
        <v-row no-gutters>
          <v-col style="padding: 16px">
            <select-row
              v-model="addDepartmentItem"
              :inTitle="$tc('manuals.dropdownTitle')"
              :inItems="departmentList"
              hideTitle
              :in-place-holder="$tc('manuals.dropdownTitle')"
              :padding-zero="true"
              :clearable="true"
              :return-object="true"
              :item-text="'denominazione'"
              :item-value="'codreparto'"
              class="select-row-fixed"
            ></select-row>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                color="primary"
                outlined
                block
                @click="showAddDepartmentDialog = false"
              >
                {{ $tc("general.cancel") }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn color="primary" block @click="insertOrUpdateDepartment">
                {{ $tc("general.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showConfirmDeleteDepartmentDialog" :width="700">
      <v-card class="pa-6">
        <v-card-title style="padding: 16px">
          {{ $tc("userManagement.DeleteDepartmentDialogTitle") }}
        </v-card-title>
        <v-card-text style="padding: 16px; color: black; font-size: 1rem">
          {{
            $tc("userManagement.DeleteDepartmentDialogText", 1, {
              departmentId: departmentToDelete?.codreparto,
              departmentName: departmentToDelete?.denominazione,
              user: activeUserDepartmentItem?.userName,
            })
          }}
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                color="primary"
                outlined
                block
                @click="showConfirmDeleteDepartmentDialog = false"
              >
                {{ $tc("general.cancel") }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn color="primary" block @click="deleteDepartment">
                {{ $tc("general.Remove") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { adminService } from "@/services/api/admin.service";
import {
  BaseDepartment,
  UserDepartmentBaseItem,
  UserDepartmentEditItem,
} from "@/models/entities/admin/Departments/DepartmentsItems.interface";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
import { DepartmentItemInterface } from "@/models/entities/maintenance/departmentItem.interface";

const snackbarModule = getModule(SnackbarModule, store);

@Component({})
export default class DepartmentTab extends Vue {
  isLoading = false;
  userDepartments: UserDepartmentBaseItem[] = [];
  departmentList: DepartmentItemInterface[] = [];
  headers = [
    {
      text: this.$tc("userManagement.tblHeadUserName"),
      align: "start",
      value: "userName",
    },
    {
      text: this.$tc("userManagement.tblHeadName"),
      align: "start",
      sortable: false,
      value: "name",
    },
    {
      text: this.$tc("userManagement.tblHeadSurname"),
      align: "start",
      sortable: false,
      value: "surname",
    },
    {
      text: this.$tc("userManagement.tblHeadDepartments"),
      align: "start",
      sortable: false,
      value: "departmentsList",
    },
    {
      text: this.$tc("userManagement.btnSeeUserDepartments"),
      align: "start",
      sortable: false,
      value: "actionsDepartments",
    },
  ];
  headersUserDepartments = [
    {
      text: this.$tc("userManagement.tblHeadUserDepartmentCode"),
      align: "start",
      value: "codreparto",
    },
    {
      text: this.$tc("userManagement.tblHeadUserDepartmentName"),
      align: "start",
      value: "denominazione",
    },
    {
      text: "",
      align: "start",
      value: "actionsCRUD",
    },
  ];
  showUserDepartmentsDialog = false;
  showAddDepartmentDialog = false;
  showConfirmDeleteDepartmentDialog = false;
  activeUserDepartmentItem: UserDepartmentBaseItem = null;
  departmentsByUser: BaseDepartment[] = [];
  addDepartmentItem: BaseDepartment;
  addDepartmentItemId: number = null;
  departmentToDelete: BaseDepartment = null;
  isEdit = false;

  get departmentDialogTitle(): string {
    return this.isEdit
      ? this.$tc("userManagement.EditDepartmentDialogTitle")
      : this.$tc("userManagement.AddDepartmentDialogTitle");
  }

  async created() {
    this.isLoading = true;
    await Promise.all([this.loadUserDepartments(), this.loadDepartments()]);
    this.isLoading = false;
  }

  async loadUserDepartments(): Promise<void> {
    const response = await adminService.getAllUserDepartments();
    this.userDepartments = response.data;
  }

  async loadDepartments(): Promise<void> {
    const response = await adminService.loadDepartments();
    this.departmentList = response.data;
  }

  async openUserDepartmentsDialog(user: UserDepartmentBaseItem) {
    this.showUserDepartmentsDialog = true;
    this.activeUserDepartmentItem = user;

    await this.loadDepartmentsByUser();
  }

  async loadDepartmentsByUser() {
    const response = await adminService.getDepartmentsByUser(
      this.activeUserDepartmentItem.userId
    );
    this.departmentsByUser = response.data;
  }

  async insertOrUpdateDepartment() {
    if (!this.addDepartmentItem.denominazione.length) {
      snackbarModule.appearSnackbar({
        message: this.$tc("userManagement.AddDepartmentNameNeeded"),
        type: "warning",
      });
      return;
    }
    const item: UserDepartmentEditItem = {
      userId: this.activeUserDepartmentItem.userId,
      denominazione: this.addDepartmentItem.denominazione,
      codreparto: this.addDepartmentItem.codreparto,
      departmentId: this.addDepartmentItemId,
    };

    await adminService.insertOrUpdateDepartment(item);
    await this.loadDepartmentsByUser();
    this.showAddDepartmentDialog = false;
  }

  toggleAddDepartmentDialog(
    isEdit: boolean,
    addDepartmentItem: BaseDepartment = {
      departmentId: null,
      denominazione: "",
      codreparto: "",
    }
  ) {
    this.isEdit = isEdit;
    this.addDepartmentItem = addDepartmentItem;
    this.addDepartmentItemId = addDepartmentItem.departmentId;
    this.showAddDepartmentDialog = !this.showAddDepartmentDialog;
  }

  openDeleteDialog(department: BaseDepartment) {
    this.showConfirmDeleteDepartmentDialog = true;
    this.departmentToDelete = department;
  }

  async deleteDepartment() {
    await adminService.deleteDepartment(
      this.activeUserDepartmentItem.userId,
      this.departmentToDelete.departmentId
    );
    this.showConfirmDeleteDepartmentDialog = false;
    await this.loadDepartmentsByUser();
  }
}
</script>

<style scoped>
.select-row-fixed {
  margin: 0;
}

.select-row-fixed :deep(.col) {
  width: 100% !important;
}
</style>
