<template>
  <div>
    <v-row no-gutters class="align-center pt-2" v-if="!hideTitile">
      <v-col cols="2">
        <v-btn
          v-if="!hideBackwardsNavigation"
          class="mod--btn reduce-back-btn"
          x-large
          @click="goToRoute"
        >
          <v-icon left :size="32" class="mod--icon"> mdi-arrow-left </v-icon>
        </v-btn>
      </v-col>
      <v-col
        v-if="!titleDown"
        :cols="hideBackwardsNavigation ? 12 : 8"
        :class="hideBackwardsNavigation ? 'text-left' : 'text-center'"
      >
        <h1 class="mod--h1 title-h1-refactor">{{ title }}</h1>
      </v-col>
      <v-col cols="2"> </v-col>
    </v-row>
    <v-row v-if="titleDown && !hideTitile">
      <v-col cols="12" class="text-center pt-12">
        <h1 class="mod--h1 title-h1-refactor-down font-weight: bold">
          {{ title }}
        </h1>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class TitleBar extends Vue {
  @Prop({ required: true, type: String, default: "" })
  title: string;
  @Prop({ required: false, type: Boolean, default: false })
  hideBackwardsNavigation: string;
  @Prop({ required: false, type: Boolean, default: false })
  titleDown: boolean;
  @Prop({ required: false, type: Boolean, default: false })
  hideTitile: boolean;
  @Prop({ required: false, type: Boolean, default: false })
  isParentElement: boolean;
  @Prop({ required: false, type: String, default: null })
  forcedPreviousComponent: string;

  goToRoute() {
    if (this.isParentElement)
      this.$router.replace({
        name: "home",
      });
    else {
      if (this.forcedPreviousComponent) {
        this.$router.replace({
          name: this.forcedPreviousComponent,
        });
      } else {
        this.$router.go(-1);
      }
    }
  }
}
</script>
<style>
.reduce-back-btn {
  min-height: 96px !important;
  max-width: 150px !important;
  font-size: 20px !important;
  border-radius: 13px !important;
}

.title-h1-refactor {
  font-size: 52px !important;
}

.title-h1-refactor-down {
  font-size: 44px !important;
}
</style>
