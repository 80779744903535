/* eslint-disable */
// @ts-nocheck
/* eslint-enable */
import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import OverlayModule from "./modules/overlay.module";
import SnackbarModule from "./modules/snackbar.module";
import AuthModule from "@/store/modules/auth.module";
import SpicyImagesModule from "@/store/modules/spicyImages.module";
import MaintenanceModule from "@/store/modules/maintenance.module";

Vue.use(Vuex);

const vuexAuth = new VuexPersistence({
  key:
    process.env.NODE_ENV === "production" &&
    (process.env.VUE_APP_IS_PRODUCTION_TEST === 1 ||
      process.env.VUE_APP_IS_PRODUCTION_TEST === "1")
      ? "vuex-auth-digita-test"
      : "vuex-auth-digita",
  restoreState: (key) => {
    return JSON.parse(
      localStorage.getItem(key) ? localStorage.getItem(key) : null
    );
  },
  saveState: (key, state) => {
    localStorage.setItem(key, JSON.stringify(state));
  },
});

const vuexMaintenance = new VuexPersistence({
  key:
    process.env.NODE_ENV === "production" &&
    (process.env.VUE_APP_IS_PRODUCTION_TEST === 1 ||
      process.env.VUE_APP_IS_PRODUCTION_TEST === "1")
      ? "vuex-maintenance-digita-test"
      : "vuex-maintenance-digita",
  restoreState: (key) => {
    return JSON.parse(
      localStorage.getItem(key) ? localStorage.getItem(key) : null
    );
  },
  saveState: (key, state) => {
    localStorage.setItem(key, JSON.stringify(state));
  },
  modules: ["maintenance"],
});

const store = new Vuex.Store({
  modules: {
    auth: AuthModule,
    snackbar: SnackbarModule,
    overlay: OverlayModule,
    spicyImages: SpicyImagesModule,
    maintenance: MaintenanceModule,
  },
  plugins: [vuexAuth.plugin, vuexMaintenance.plugin],
});
export default store;
