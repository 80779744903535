import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
// import "@/services/api.service";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import draggable from "vuedraggable";
import CheckRow from "@/components/CheckRow.vue";
import InputRow from "@/components/InputRow.vue";
import SelectRow from "@/components/SelectRow.vue";
import PasswordRow from "@/components/PasswordRow.vue";
import TitleBar from "@/components/TitleBar.vue";
import InputCenter from "@/components/InputCenter.vue";
import AdminTableRow from "@/components/AdminTableRow.vue";
import { SpreadsheetPlugin } from "@syncfusion/ej2-vue-spreadsheet";
import { PdfViewerPlugin } from "@syncfusion/ej2-vue-pdfviewer";
import { registerLicense } from "@syncfusion/ej2-base";
import { i18n } from "./i18n/config";
import ZPdfViewer from "@zelando/pdf-viewer";

Vue.config.productionTip = false;

Vue.component("check-row", CheckRow);
Vue.component("input-row", InputRow);
Vue.component("password-row", PasswordRow);
Vue.component("select-row", SelectRow);
Vue.component("admin-table-row", AdminTableRow);
Vue.component("title-bar", TitleBar);
Vue.component("input-center", InputCenter);
Vue.component("zelando-pdf-viewer", ZPdfViewer);

/*eslint-disable*/
Vue.component("draggable", draggable);
/*eslint-enable*/
// Vue.component("spreadsheet-plugin", SpreadsheetPlugin);

Vue.use(SpreadsheetPlugin);
Vue.use(PdfViewerPlugin);

// Registering Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0ViUX5fdHJVTmNVV0E=;Mgo+DSMBMAY9C3t2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0ViUX5fdHJVTmRdUkE="
);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  // SpreadsheetPlugin,
  render: (h) => h(App),
}).$mount("#app");
