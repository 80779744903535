<template>
  <div style="height: 100%">
    <!-- :allowAutoFill="allowEdit"
      :allowConditionalFormat="allowEdit"
      :allowDataValidation="allowEdit"
      :allowDelete="allowEdit"
      :allowFiltering="allowEdit"
      :allowFindAndReplace="allowEdit"
      :allowFreezePane="allowEdit"
      :allowMerge="allowEdit"
      :allowResizing="allowEdit"
      :allowSave="allowEdit"
      :allowScrolling="allowEdit"
      :allowSorting="allowEdit"
      :allowUndoRedo="allowEdit"
      :allowWrap="allowEdit"
      :allowChart="allowEdit"
      :allowInsert="allowEdit"
      :allowHyperlink="allowEdit"
      :allowImage="allowEdit"
      :allowCellFormatting="allowEdit"
      :allowNumberFormatting="allowEdit"
      :enableClipboard="allowEdit" -->
    <ejs-spreadsheet
      :allowOpen="true"
      :allowEditing="allowEdit"
      :showRibbon="false"
      :showFormulaBar="false"
      ref="spreadsheet"
      :created="spreadsheetCreated"
      :openUrl="openUrl"
      height="92%"
    ></ejs-spreadsheet>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import { equipmentService } from "@/services/api/equipment.service";
import AuthModule from "@/store/modules/auth.module";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { ApiEndpoint, endpointDict } from "@/services/api.enum";
import { fileLogService } from "@/services/api/fileLog.service";
import { watch } from "@syncfusion/ej2-vue-pdfviewer/src/pdfviewer/pdfviewer.component";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class EquipmentDetailsPage extends Vue {
  private allowEdit = true;
  private openUrl = endpointDict[ApiEndpoint.SPREADSHEET_OPEN];
  private fileString: File = new File([], "");
  private fileId: string = this.$route.query.id as string;
  private pb = "";

  private created() {
    window.addEventListener("orientationchange", this.myFunction);
  }

  private spreadsheetCreated(): void {
    overlayModule.showOverlay();
    const orientation = window.screen.orientation.type;
    equipmentService
      .getExcel(this.fileId)
      .then((res) => {
        Vue.nextTick(() => {
          document.title = res.data.name;
        });
        // fileLogService.insertLog({
        //   fileName: res.data.name,
        //   fileHash: res.data.fileHash,
        //   action: "R",
        //   userName: authModule.userName,
        // });
        const b64String =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        fetch(b64String + res.data.value).then((response) => {
          response.blob().then((fileBlob) => {
            var file = new File([fileBlob], "Output.xlsx");
            (
              this.$refs.spreadsheet as Vue & {
                open: (obj: { [key: string]: File }) => unknown;
              }
            ).open({ file: file });
            this.allowEdit = false;
            overlayModule.hideOverlay();
          });
        });
      })
      .catch((err) => {
        overlayModule.hideOverlay();
      });
  }
  private myFunction(event: unknown): void {
    this.spreadsheetCreated();
  }

  private getTitle(vm) {
    const { title } = vm.$options;
    if (title) {
      return typeof title === "function" ? title.call(vm) : title;
    }
  }
}
</script>

<style>
.e-sheet-panel {
  height: calc(100vh - 0px);
}
</style>
