import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";
// import { RanaLoginRequest } from "@/models/entities/ranaApi/ranaApi.interface";

const CONTROLLER = "users";
class UsersService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }

  logout() {
    return this.getAll(`${CONTROLLER}/logout`);
  }
}

export const usersService = new UsersService();
