export enum ApiEndpoint {
  STANDARD,
  DLL,
  SPREADSHEET_OPEN,
  SPREADSHEET_SAVE,
  SIGNATURE,
}

export const endpointDict = {
  [ApiEndpoint.STANDARD]: process.env.VUE_APP_API_STANDARD,
  [ApiEndpoint.DLL]: process.env.VUE_APP_API_DLL,
  [ApiEndpoint.SPREADSHEET_OPEN]:
    process.env.VUE_APP_API_STANDARD + "spreadsheet/open",
  [ApiEndpoint.SPREADSHEET_SAVE]:
    process.env.VUE_APP_API_STANDARD + "spreadsheet/save",
  // [ApiEndpoint.USERS]: process.env.VUE_APP_API_USERS,
};
