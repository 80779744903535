import { render, staticRenderFns } from "./DialogGroupDelete.vue?vue&type=template&id=38fbd3be&%3Akey=true"
import script from "./DialogGroupDelete.vue?vue&type=script&lang=ts"
export * from "./DialogGroupDelete.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports