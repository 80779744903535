import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";
import { FileLogItem } from "@/models/entities/fileFormats/fileLogs.interface";
import { AssetBaseInterface } from "@/models/entities/maintenance/assetBase.interface";
import { FileResponse } from "@/models/entities/fileFormats/fileResponse.interface";
import { RequestManualFileInterface } from "@/models/entities/maintenance/requestManualFile.interface";
import { DepartmentItemInterface } from "@/models/entities/maintenance/departmentItem.interface";
import {
  AssetsByDepartmentRequest,
  CheckAllowPlanCompilationRequest,
  SaveSmallMaintenanceAnswerRequest,
  SaveSmallMaintenanceNotesRequest,
  SaveSmallMaintenanceOutcomeRequest,
  SmallMaintenancePlanRequest,
} from "@/models/entities/maintenance/smallMaintenanceRequests.interface";
import {
  SmallMaintenanceAnswerTypes,
  SmallMaintenancePlan,
  SmallMaintenanceRowBase,
} from "@/models/entities/maintenance/smallMaintenancePlan.interface";

const CONTROLLER = "maintenance";
class MaintenanceService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }

  getAssetsByDepartment(deparmentId: string, location: string) {
    return this.getAll<string[]>(
      `${CONTROLLER}/manuals/assetsbydepartment/${deparmentId}?location=${location}`
    );
  }

  getDocumentsNames(assetName: string, folderName?: string) {
    return this.getAll<string[]>(
      `${CONTROLLER}/manuals/documentsnames?assetName=${assetName}&folderName=${folderName}`
    );
  }

  getFoldersByAsset(assetName: string) {
    return this.getAll<string[]>(
      `${CONTROLLER}/manuals/foldersbyasset?assetName=${assetName}`
    );
  }

  getFile(requestManualFile: RequestManualFileInterface) {
    return this.getAll<FileResponse>(`${CONTROLLER}/manuals/getfile`, {
      params: requestManualFile,
    });
  }

  loadDepartments() {
    return this.getAll<DepartmentItemInterface[]>(`${CONTROLLER}/departments`);
  }

  getDepartmentsByUser() {
    return this.getAll<DepartmentItemInterface[]>(
      `${CONTROLLER}/smallmaintenance/departmentsbyuser`
    );
  }

  getAssetsByDepartmentSmallMaintenance(
    departmentId: string,
    request: AssetsByDepartmentRequest
  ) {
    return this.post<AssetsByDepartmentRequest, string[]>(
      `${CONTROLLER}/smallmaintenance/assetsbydepartment/${departmentId}?`,
      request
    );
  }

  getSmallMaintenancePlan(assetId: string) {
    return this.getAll<SmallMaintenancePlan[]>(
      `${CONTROLLER}/smallmaintenance/plan/${assetId}`
    );
  }

  checkAllowPlanCompilation(request: CheckAllowPlanCompilationRequest) {
    return this.post<CheckAllowPlanCompilationRequest, boolean>(
      `${CONTROLLER}/smallmaintenance/checkallowcompilation`,
      request
    );
  }

  insertIfNotExists(request: CheckAllowPlanCompilationRequest) {
    return this.post<CheckAllowPlanCompilationRequest, null>(
      `${CONTROLLER}/smallmaintenance/insertifnotexists`,
      request
    );
  }

  getSmallMaintenanceById(request: SmallMaintenancePlanRequest) {
    return this.post<SmallMaintenancePlanRequest, SmallMaintenanceRowBase[]>(
      `${CONTROLLER}/smallmaintenance/getbyid`,
      request
    );
  }

  getSmallMaintenanceAnswerTypes() {
    return this.getAll<SmallMaintenanceAnswerTypes[]>(
      `${CONTROLLER}/smallmaintenance/answertypes`
    );
  }

  saveSmallMaintenanceAnswer(request: SaveSmallMaintenanceAnswerRequest) {
    return this.post<SaveSmallMaintenanceAnswerRequest, void>(
      `${CONTROLLER}/smallmaintenance/saveanswer`,
      request
    );
  }

  saveSmallMaintenanceOutcome(request: SaveSmallMaintenanceOutcomeRequest) {
    return this.post<SaveSmallMaintenanceOutcomeRequest, void>(
      `${CONTROLLER}/smallmaintenance/saveoutcome`,
      request
    );
  }

  saveSmallMaintenanceNotes(request: SaveSmallMaintenanceNotesRequest) {
    return this.post<SaveSmallMaintenanceNotesRequest, void>(
      `${CONTROLLER}/smallmaintenance/savenotes`,
      request
    );
  }

  smallMaintenanceStoppedMachine(answerGroupId: number) {
    return this.post<null, void>(
      `${CONTROLLER}/smallmaintenance/massivestoppedmachine/${answerGroupId}`,
      null
    );
  }

  smallMaintenanceCompleteCompilation(request: SmallMaintenancePlanRequest) {
    return this.post<SmallMaintenancePlanRequest, void>(
      `${CONTROLLER}/smallmaintenance/completecompilation`,
      request
    );
  }
}

export const maintenanceService = new MaintenanceService();
