<template>
  <v-bottom-sheet
    v-model="show"
    transition="dialog-bottom-transition"
    width="100vw"
  >
    <v-card class="pa-6">
      <v-row class="pl-6" style="font-size: 16px">
        <v-col
          cols="6"
          style="text-align: center"
          v-for="(item, idx) in answerTypes"
          :key="idx"
        >
          <span style="font-weight: bold">{{ item.risposta }}</span>
          <div
            class="default-layout"
            :class="getBackgroundColor(item.idRisposta)"
          >
            {{ getLegendDescription(item.idRisposta) }}
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-bottom-sheet>
</template>

<script lang="ts">
import { Vue, Component, VModel, Prop } from "vue-property-decorator";
import { SmallMaintenanceAnswerTypes } from "@/models/entities/maintenance/smallMaintenancePlan.interface";

@Component({})
export default class LegendDialog extends Vue {
  @VModel()
  show: boolean;

  @Prop({ required: false, type: Array, default: [] })
  answerTypes: SmallMaintenanceAnswerTypes[];

  getBackgroundColor(idRisposta: number): string {
    switch (idRisposta) {
      case 1: //Effettuato
        return "answer-made";
      case 2: //Non effettuabile
        return "answer-not-feasible";
      case 3: //Non effettuato
        return "answer-not-made";
      case 4: //Macchina ferma
        return "answer-stopped";
    }
  }

  getLegendDescription(idRisposta: number): string {
    switch (idRisposta) {
      case 1: //Effettuato
        return this.$tc("smallMaintenance.smallMaintenancePlan.Legend.Made");
      case 2: //Non effettuabile
        return this.$tc(
          "smallMaintenance.smallMaintenancePlan.Legend.NotFeasible"
        );
      case 3: //Non effettuato
        return this.$tc("smallMaintenance.smallMaintenancePlan.Legend.NotMade");
      case 4: //Macchina ferma
        return this.$tc("smallMaintenance.smallMaintenancePlan.Legend.Stopped");
    }
  }
}
</script>

<style scoped>
.default-layout {
  width: 100%;
  height: 150px;
}

.answer-made {
  background: green;
  color: white;
}

.answer-not-feasible {
  background: yellow;
}

.answer-not-made {
  background: orange;
}

.answer-stopped {
  background: red;
  color: white;
}
</style>
