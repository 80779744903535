import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { SmallMaintenanceHeader } from "@/models/entities/maintenance/smallMaintenanceHeader.interface";
import { SmallMaintenanceFiltersConfiguration } from "@/models/entities/maintenance/smallMaintenanceFiltersConfiguration.interface";
import {
  AssetsByDepartmentRequest,
  CheckAllowPlanCompilationRequest,
} from "@/models/entities/maintenance/smallMaintenanceRequests.interface";
import { DepartmentItemInterface } from "@/models/entities/maintenance/departmentItem.interface";

@Module({ namespaced: true, name: "maintenance" })
class MaintenanceModule extends VuexModule {
  private _smallMaintenanceHeader: SmallMaintenanceHeader = {
    assetId: null,
    planDate: null,
    resourceType: null,
    user: null,
    smallMaintenancePlan: null,
  };
  // private _selectedDepartment: DepartmentItemInterface = null;
  // private _assetsByDepartmentFilters: AssetsByDepartmentRequest = {
  //   location: null,
  //   plumbingPlan: null,
  // };
  // private _requestCompilationFilters: CheckAllowPlanCompilationRequest = {
  //   assetId: "",
  //   smallMaintenancePlan: null,
  //   planDate: new Date().toISOString().split("T")[0],
  // };

  // get assetsByDepartmentFilters(): AssetsByDepartmentRequest {
  //   return this._assetsByDepartmentFilters;
  // }
  //
  // get selectedDepartment(): DepartmentItemInterface {
  //   return this._selectedDepartment;
  // }
  //
  // get requestCompilationFilters(): CheckAllowPlanCompilationRequest {
  //   return this._requestCompilationFilters;
  // }

  get smallMaintenanceHeader(): SmallMaintenanceHeader {
    return this._smallMaintenanceHeader;
  }

  @Mutation
  private _setSmallMaintenanceHeader(header: SmallMaintenanceHeader): void {
    this._smallMaintenanceHeader = header;
  }

  @Action
  public setSmallMaintenanceHeader(header: SmallMaintenanceHeader): void {
    this.context.commit("_setSmallMaintenanceHeader", header);
  }

  // @Mutation
  // private _setFiltersConfiguration(
  //   config: SmallMaintenanceFiltersConfiguration
  // ): void {
  //   this._assetsByDepartmentFilters = config.assetsByDepartmentFilters;
  //   this._requestCompilationFilters = config.requestCompilationFilters;
  //   this._selectedDepartment = config.selectedDepartment;
  // }
  //
  // @Action
  // public setFiltersConfiguration(
  //   config: SmallMaintenanceFiltersConfiguration
  // ): void {
  //   this.context.commit("_setFiltersConfiguration", config);
  // }

  @Mutation
  private _resetModule(): void {
    this._smallMaintenanceHeader = {
      assetId: null,
      planDate: null,
      resourceType: null,
      user: null,
      smallMaintenancePlan: null,
    };
  }

  @Action
  public resetModule(): void {
    this.context.commit("_resetModule");
  }
}

export default MaintenanceModule;
