<template>
  <v-row :style="isCenterd && !removeSpacer && 'width: ' + width">
    <v-col :cols="isCenterd ? 'auto' : 4" class="d-flex align-center">
      <strong
        :style="
          $vuetify.breakpoint.mdAndDown ? 'font-size: 26px' : 'font-size: 18px'
        "
        >{{ inTitle }}</strong
      >
    </v-col>
    <v-spacer v-if="!removeSpacer"></v-spacer>
    <v-col :cols="isCenterd ? 'auto' : 8">
      <v-checkbox
        dense
        v-model="valueModel"
        color="primary"
        :readonly="inIsReadonly"
        class="mod--check"
        @change="inChanged"
      ></v-checkbox>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Vue, Prop, VModel } from "vue-property-decorator";

@Component
export default class CheckRow extends Vue {
  @VModel({ default: false })
  valueModel: undefined;
  @Prop({ required: true, type: String, default: "" })
  private inTitle: string;
  @Prop({ required: false, type: Boolean, default: false })
  private inIsReadonly: boolean;
  @Prop({ required: false, type: Boolean, default: false })
  private isCenterd: boolean;
  @Prop({ required: false, type: Boolean, default: false })
  private removeSpacer: boolean;
  @Prop({ required: false, type: String, default: "" })
  private width: string;

  private inChanged(): void {
    this.$emit("inChanged");
  }
}
</script>
