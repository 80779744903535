<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" class="pb-12">
        <title-bar
          :title="$tc('datasheetList.title')"
          :is-parent-element="true"
        ></title-bar>
      </v-col>
    </v-row>
    <v-row v-if="buttonListEnabled.length">
      <v-col
        v-for="btn in buttonListEnabled"
        :key="btn.id"
        @click="goToPage(btn.r, btn.id)"
        style="text-align: center"
        :cols="isOrientationPortrait ? 4 : 3"
      >
        <v-img
          :src="
            btn.id == 'SMP'
              ? require('@/assets/smp.png')
              : btn.id == 'SAT'
              ? require('@/assets/Scheda Tecnica Attrezzatura.png')
              : btn.id == 'SDS'
              ? require('@/assets/SDS.png')
              : btn.id == 'STT'
              ? require('@/assets/Scheda Tecnica TT&FIN.png')
              : btn.id == 'STS'
              ? require('@/assets/Scheda Tecnica Saldatura.png')
              : ''
          "
        ></v-img>
        <span style="font-weight: bold">{{ $tc(btn.t) }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { HomeButton } from "@/models/entities/components/components.interface";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";
import { buttonSortService } from "@/services/api/buttonSort.service";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component({})
export default class DatasheetList extends Vue {
  buttonListEnabled: HomeButton[] = [];

  buttonList = [
    {
      id: "SAT",
      r: "datasheetList/equipment",
      t: "home.buttonEquipment",
      c: "mod--color_btn_equipment",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "SAT";
        }).length > 0,
      sort: 0,
    },
    {
      id: "SMP",
      r: "datasheetList/mp",
      t: "home.buttonMP",
      c: "mod--color_btn_mp",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "SMP";
        }).length > 0,
      sort: 0,
    },
    {
      id: "SDS",
      r: "datasheetList/products",
      t: "home.buttonProducts",
      c: "mod--color_btn_products",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "SDS";
        }).length > 0,
      sort: 0,
    },
    {
      id: "STT",
      r: "datasheetList/stt",
      t: "home.buttonSTT",
      c: "mod--color_btn_products",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "STT";
        }).length > 0,
      sort: 0,
    },
    {
      id: "STS",
      r: "datasheetList/sts",
      t: "home.buttonSTS",
      c: "mod--color_btn_products",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "STS";
        }).length > 0,
      sort: 0,
    },
  ];

  get isOrientationPortrait() {
    const orientation = window.screen.orientation.type;
    return orientation === "portrait-primary";
  }

  goToPage(path: string, id: string) {
    if (id != "SAP") {
      this.$router.push({ path: path });
    } else {
      window.open(path);
    }
  }

  async created() {
    overlayModule.showOverlay();
    const response = await buttonSortService.getAuthsByParent("ST");

    this.buttonListEnabled = this.buttonList.filter((value) =>
      response.data.includes(value.id)
    );
    overlayModule.hideOverlay();
  }
}
</script>

<style scoped></style>
