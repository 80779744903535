<template>
  <div id="page">
    <div style="padding: 12px">
      <!-- TABS -->
      <v-tabs class="mod--fill_height mod--tab" v-model="selectedTab" grow>
        <!-- TITLE BAR -->
        <v-row class="align-center my-0">
          <v-col cols="auto" class="text-center">
            <h1 class="mod--h1">{{ $tc("userManagement.title") }}</h1>
          </v-col>
          <v-col cols="auto">
            <v-btn to="/home">
              <v-icon left class="mod--icon"> mdi-arrow-left </v-icon>
              <span class="ml-4">{{ $tc("general.back") }}</span>
            </v-btn>
          </v-col>

          <v-col class="d-flex flex-grow-1 py-0" style="height: 40px">
            <v-tab href="#user">
              {{ $tc("userManagement.tabUser") }}
            </v-tab>
            <v-tab href="#group">
              {{ $tc("userManagement.tabGroup") }}
            </v-tab>
            <v-tab href="#department">
              {{ $tc("userManagement.departmentGroup") }}
            </v-tab>
          </v-col>
        </v-row>

        <!-- USERS PAGE -->
        <v-tab-item v-if="selectedTab === 'user'" value="user" class="mt-3">
          <admin-table-row
            v-model="tableUser.search"
            :btn-import="$tc('userManagement.btnImportUsers')"
            :btn-export="$tc('userManagement.btnExportUsers')"
            :btnName="$tc('userManagement.btnAddUser')"
            :btnNameLeft="$tc('adminComponents.defaultPassword')"
            :filters.sync="filters"
            @filtersChanged="onFiltersChange"
            @click:import="activateFileInput"
            @click:export="exportUsers"
            @tableAction="createUser"
            @buttonLeft="defaultPasswordDialog = true"
          ></admin-table-row>
          <!--          @filtersChanged="test"-->

          <v-file-input
            v-model="file"
            v-show="false"
            id="fileUpload"
            @change="importUsers"
          ></v-file-input>
          <v-row v-if="tableUser.items.length > 0">
            <v-col class="px-0">
              <v-data-table
                :loading="tableUser.isLoading"
                :headers="tableUser.headers"
                :items="tableUser.items"
                :items-per-page="$vuetify.breakpoint.mdAndDown ? 5 : 10"
                :class="
                  $vuetify.breakpoint.mdAndDown
                    ? 'elevation-1 mod--table_admin'
                    : 'elevation-1 mod--table_admin v-data-table-small'
                "
                :search="tableUser.search"
              >
                <!-- Username -->
                <template v-slot:[`item.userName`]="{ item }">
                  <span :style="item.isEnabled ? '' : 'color: red'">{{
                    item.userName
                  }}</span>
                </template>
                <!-- Auths -->
                <template v-slot:[`item.actionsAuth`]="{ item }">
                  <div v-if="item.auths.length > 0 && item.auths[0].authId">
                    <v-chip
                      v-for="chip in item.auths"
                      :key="chip.authId"
                      style="margin: 3px"
                      :class="
                        'mr-2 mod--chip ' + chip.authId
                          ? chipColor(chip.authId)
                          : ''
                      "
                    >
                      {{ chip.authName }}
                    </v-chip>
                  </div>
                </template>
                <!-- Actions -->
                <template v-slot:[`item.actionsCRUD`]="{ item }">
                  <v-btn
                    icon
                    v-if="item.userId"
                    @click="editUser(item)"
                    color="primary"
                  >
                    <v-icon left color="primary" class="mx-auto" :size="24">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    v-if="item.userId"
                    @click="resetUserPassword(item)"
                    color="primary"
                  >
                    <v-icon left color="primary" class="mx-auto" :size="24">
                      mdi-lock
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    v-if="item.userId"
                    @click="duplicateUser(item)"
                    color="primary"
                  >
                    <v-icon left color="primary" class="mx-auto" :size="24">
                      mdi-content-duplicate
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    v-if="item.userId"
                    @click="deleteUser(item)"
                    color="primary"
                  >
                    <v-icon left color="primary" class="mx-auto" :size="24">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- GROUP PAGE -->
        <v-tab-item v-if="selectedTab === 'group'" value="group" class="mt-3">
          <admin-table-row
            :btnName="$tc('userManagement.btnAddGroup')"
            v-model="tableGroup.search"
            @tableAction="createGroup"
          ></admin-table-row>
          <v-row v-if="tableGroup.items.length > 0">
            <v-col class="px-0">
              <v-data-table
                :loading="tableGroup.isLoading"
                :headers="tableGroup.headers"
                :items="tableGroup.items"
                :items-per-page="5"
                :search="tableGroup.search"
                :class="
                  $vuetify.breakpoint.mdAndDown
                    ? 'elevation-1 mod--table_admin'
                    : 'elevation-1 mod--table_admin v-data-table-small'
                "
              >
                <!-- Auths -->
                <template v-slot:[`item.actionsAuth`]="{ item }">
                  <div v-if="item.auths.length > 0 && item.auths[0].authId">
                    <v-chip
                      v-for="chip in item.auths"
                      :key="chip.authId"
                      style="margin-left: 2px; margin-right: 2px"
                      :class="
                        'mr-2 mod--chip ' + chip.authId
                          ? chipColor(chip.authId)
                          : ''
                      "
                    >
                      {{ chip.authName }}
                    </v-chip>
                  </div>
                </template>
                <!-- Show users -->
                <template v-slot:[`item.actionsUsers`]="{ item }">
                  <v-btn
                    text
                    v-if="item.userGroupId"
                    @click="seeGroupUsers(item.userGroupId, item.userGroupName)"
                    class="mod--btn_text"
                  >
                    {{ $tc("userManagement.btnDetails") }}
                  </v-btn>
                </template>
                <!-- Actions -->
                <template v-slot:[`item.actionsCRUD`]="{ item }">
                  <v-btn
                    icon
                    v-if="item.userGroupId"
                    @click="editGroup(item.userGroupId)"
                    color="primary"
                  >
                    <v-icon left color="primary" class="mx-auto" :size="24">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    v-if="item.userGroupId"
                    @click="deleteGroup(item)"
                    color="primary"
                  >
                    <v-icon left color="primary" class="mx-auto" :size="24">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- DEPARTMENT PAGE -->
        <v-tab-item
          v-if="selectedTab === 'department'"
          value="department"
          class="mt-3"
        >
          <department-tab></department-tab>
        </v-tab-item>
      </v-tabs>
      <!-- MODALS -->
      <dialog-user-new
        v-if="dialogItems.userNew.isOpen"
        v-model="dialogItems.userNew.isOpen"
        :key="'DUN' + dialogItems.userNew.key"
        :_userId="dialogItems.userNew.d.userId"
        :_isEdit="dialogItems.userNew.isEdit"
        :_userName="dialogItems.userNew.d.userName"
        :_password="dialogItems.userNew.d.password"
        :_userDescription="dialogItems.userNew.d.userDescription"
        :_name="dialogItems.userNew.d.name"
        :_surname="dialogItems.userNew.d.surname"
        :_windowsUser="dialogItems.userNew.d.windowsUser"
        :_email="dialogItems.userNew.d.email"
        :_userRole="dialogItems.userNew.d.userRole"
        :_auths="dialogItems.userNew.d.auths"
        :_userGroupId="dialogItems.userNew.d.userGroupId"
        :_isEnabled="dialogItems.userNew.d.isEnabled"
        :_plant="plantId"
        :_serial-number="dialogItems.userNew.d.serialNumber"
        @closeModal="rerenderModals('userNew')"
      ></dialog-user-new>
      <dialog-reset-password
        v-if="dialogItems.userRetePassword.isOpen"
        v-model="dialogItems.userRetePassword.isOpen"
        :key="'DRP' + dialogItems.userRetePassword.key"
        :_userId="dialogItems.userRetePassword.d.userId"
        :title="dialogItems.userRetePassword.title"
        @closeModal="rerenderModals('userNew')"
      ></dialog-reset-password>
      <dialog-user-delete
        v-if="dialogItems.userDelete.isOpen"
        v-model="dialogItems.userDelete.isOpen"
        :key="'DUD' + dialogItems.userDelete.key"
        :_userId="dialogItems.userDelete.userId"
        :_userName="dialogItems.userDelete.userName"
        :_userDescription="dialogItems.userDelete.userDescription"
        @closeModal="dialogItems.userDelete.isOpen = false"
        @SuccessfullCancellation="rerenderModals('userNew')"
      ></dialog-user-delete>
      <dialog-group-new
        v-if="isOpenNewGroup"
        v-model="isOpenNewGroup"
        :userGroupId="userGroupId"
        @closeModal="rerenderModals('groupEdit')"
        @updateUsers="rerenderModals('userNew')"
      ></dialog-group-new>
      <dialog-group-user-list
        v-if="showUserGroupList"
        v-model="showUserGroupList"
        :userGroupId="userGroupId"
        :groupName="userGroupName"
        @closeModal="rerenderModals('userNew')"
      >
      </dialog-group-user-list>
      <dialog-delete-group
        v-if="groupShow"
        v-model="groupShow"
        :key="'DUD' + groupId"
        :_groupId="groupId"
        :_nameGroup="groupName"
        :_gruopDescription="$tc('userManagement.sureToDeleteGroup') + groupName"
        @closeModal="rerenderModals('groupEdit')"
      ></dialog-delete-group>
      <dialog-default-password
        v-model="defaultPasswordDialog"
        @closeModal="defaultPasswordDialog = false"
      ></dialog-default-password>
      <v-dialog
        v-model="confirmImportDialog"
        persistent
        transition="dialog-top-transition"
        :width="700"
        class="custom-dialog"
      >
        <v-card-title>{{
          $tc("adminComponents.ImportDialogTitle")
        }}</v-card-title>
        <v-card-text style="font-size: 0.8em">
          <span v-if="importUsersResponse?.importedUsers?.length">
            {{
              $t("adminComponents.ImportDialogTextAddedUsers", {
                added: importUsersResponse?.importedUsers?.length,
              })
            }}
          </span>
          <span v-if="importUsersResponse?.updatedUsers?.length">
            {{
              $t("adminComponents.ImportDialogTextNotAddedUsers", {
                updated: importUsersResponse?.updatedUsers?.length,
              })
            }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-row
            style="margin: 0; gap: 15px; padding-right: 15px"
            justify="end"
          >
            <v-btn @click="toggleConfirmImportDialog"
              >{{ $tc("general.close") }}
            </v-btn>
            <v-btn
              style="background: #007bc1; color: white"
              @click="importReportDialog = true"
              >{{ $tc("adminComponents.ShowImportReport") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-dialog>

      <v-dialog
        v-model="importReportDialog"
        transition="dialog-top-transition"
        :width="700"
      >
        <v-card-text style="font-size: 0.8em">
          <v-row no-gutters style="margin-bottom: 12px"
            ><span style="margin-bottom: 12px; font-weight: bold">{{
              $tc("adminComponents.AddedUsers")
            }}</span></v-row
          >

          <div
            v-if="importUsersResponse?.importedUsers?.length"
            style="display: flex; flex-direction: column; gap: 4px"
          >
            <v-row
              v-for="(user, idx) in importUsersResponse?.importedUsers"
              :key="idx"
              no-gutters
              >{{ user.username }}
              <span v-if="user.descrizione">
                -{{ user.descrizione }}
              </span></v-row
            >
          </div>
          <span v-else>-</span>
          <v-row no-gutters style="margin-bottom: 12px">
            <span
              style="margin-bottom: 12px; margin-top: 12px; font-weight: bold"
              >{{ $tc("adminComponents.NotAddedUsers") }}</span
            >
          </v-row>
          <div
            v-if="importUsersResponse?.updatedUsers?.length"
            style="display: flex; flex-direction: column; gap: 12px"
          >
            <v-row
              v-for="(user, idx) in importUsersResponse?.updatedUsers"
              :key="idx"
              no-gutters
              >{{ user.username
              }}<span v-if="user.descrizione">
                -{{ user.descrizione }}
              </span></v-row
            >
          </div>

          <span v-else>-</span>
          <v-row no-gutters style="margin-bottom: 12px"
            ><span
              style="margin-bottom: 12px; margin-top: 12px; font-weight: bold"
              >{{ $tc("adminComponents.NotOverwrittenUsers") }}</span
            ></v-row
          >
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters justify="end">
            <v-btn @click="importReportDialog = false"
              >{{ $tc("general.close") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Ref } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import {
  GroupAuthInfoElab,
  UserAuthInfoAuths,
  UserAuthInfoElab,
} from "@/models/entities/admin/admin.interface";
import { adminService } from "@/services/api/admin.service";
import DialogUserNew from "@/components/admin/DialogUserNew.vue";
import DialogUserDelete from "@/components/admin/DialogUserDelete.vue";
import DialogResetPassword from "@/components/admin/DialogResetPassword.vue";
import { custom } from "@/helpers/customMethods";
import { AuthItemNamed } from "@/models/entities/auth/authItem.interface";
import DialogGroupNew from "@/components/admin/DialogGroupNew.vue";
import DialogGroupUserList from "@/components/admin/DialogGroupUserList.vue";
import DialogDeleteGroup from "@/components/admin/DialogGroupDelete.vue";
import DialogDefaultPassword from "@/components/admin/DialogDefaultPassword.vue";
import { VFileInput } from "vuetify/lib/components";
import { UserImportResponseInterface } from "@/models/entities/admin/UserImportResponse.interface";
import VueI18n from "vue-i18n";
import { UserManagementFiltersInterface } from "@/models/entities/admin/UserManagementFilters.interface";
import { AxiosResponse } from "axios";
import AuthModule from "@/store/modules/auth.module";
import DepartmentTab from "@/components/admin/Tabs/DepartmentTab.vue";

const overlayModule = getModule(OverlayModule, store);
const authModule = getModule(AuthModule, store);

@Component({
  computed: {
    VueI18n() {
      return VueI18n;
    },
  },
  components: {
    DepartmentTab,
    "dialog-user-new": DialogUserNew,
    "dialog-reset-password": DialogResetPassword,
    "dialog-user-delete": DialogUserDelete,
    "dialog-group-new": DialogGroupNew,
    "dialog-group-user-list": DialogGroupUserList,
    "dialog-delete-group": DialogDeleteGroup,
    "dialog-default-password": DialogDefaultPassword,
  },
})
export default class UserManagementPage extends Vue {
  selectedTab = "user";
  isOpenNewGroup = false;
  userGroupId = 0;
  userGroupName = "";
  showUserGroupList = false;
  groupName = "";
  groupId = 0;
  groupShow = false;
  defaultPasswordDialog = false;
  confirmImportDialog = false;
  plantId = 0;
  file = null;
  importReportDialog = false;
  importUsersResponse: UserImportResponseInterface = null;
  filters: UserManagementFiltersInterface = {
    onlyActive: true,
    userRoleId: null,
    userGroupId: null,
  };

  @Ref("fileInput")
  fileInput: InstanceType<typeof VFileInput>;

  rerenderModals(modal: string): void {
    switch (modal) {
      case "userNew":
        this.dialogItems[modal].key++;
        this.reloadUserTable();
        break;
      case "groupNew":
      case "groupEdit":
        this.dialogItems[modal].key++;
        this.reloadGroupTable();
        break;
      default:
        break;
    }
  }

  created(): void {
    // load both data from users & from groups
    this.reloadGroupTable();
    this.reloadUserTable();
  }

  reloadGroupTable(): void {
    this.tableGroup.isLoading = true;
    adminService
      .getGroupDefault()
      .then((res) => {
        this.tableGroup.items = res.data;
      })
      .finally(() => {
        this.tableGroup.isLoading = false;
      });
  }
  reloadUserTable(): void {
    this.tableUser.isLoading = true;
    adminService
      .getUserDefault(this.filters)
      .then((res) => {
        this.tableUser.items = res.data;
      })
      .finally(() => {
        this.tableUser.isLoading = false;
      });
  }

  chipColor(authId: string): string {
    return custom.defineChipColor(authId);
  }

  tableUserItems: UserAuthInfoElab[] = [];
  tableUser = {
    isLoading: true,
    search: "",
    headers: [
      {
        text: this.$tc("userManagement.tblHeadUserName"),
        align: "start",
        sortable: false,
        value: "userName",
      },
      // {
      //   text: this.$tc("userManagement.tblHeadUserDescription"),
      //   align: "start",
      //   sortable: false,
      //   value: "userDescription",
      // },
      {
        text: this.$tc("userManagement.tblHeadName"),
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: this.$tc("userManagement.tblHeadSurname"),
        align: "start",
        sortable: false,
        value: "surname",
      },
      {
        text: this.$tc("userManagement.tblHeadGroupName"),
        align: "start",
        sortable: false,
        value: "userGroupName",
      },
      {
        text: this.$tc("userManagement.tblHeadAuth"),
        align: "start",
        sortable: false,
        value: "actionsAuth",
      },
      {
        text: this.$tc("userManagement.tblHeadActions"),
        align: "start",
        sortable: false,
        value: "actionsCRUD",
      },
    ],
    items: this.tableUserItems,
  };

  tableGroupItems: GroupAuthInfoElab[] = [];
  tableGroup = {
    isLoading: true,
    search: "",
    headers: [
      {
        text: this.$tc("userManagement.tblHeadGroupName"),
        align: "start",
        value: "userGroupName",
      },
      {
        text: this.$tc("userManagement.tblHeadAuth"),
        align: "start",
        sortable: false,
        value: "actionsAuth",
      },
      {
        text: this.$tc("userManagement.tblHeadGroupList"),
        align: "start",
        sortable: false,
        value: "actionsUsers",
      },
      {
        text: this.$tc("userManagement.tblHeadActions"),
        align: "start",
        sortable: false,
        value: "actionsCRUD",
      },
    ],
    items: this.tableGroupItems,
  };

  // REGION GROUPS
  seeGroupUsers(groupId: number, groupName: string): void {
    this.userGroupName = groupName;
    this.userGroupId = groupId;
    this.showUserGroupList = true;
  }
  createGroup(): void {
    this.userGroupId = 0;
    this.isOpenNewGroup = true;
  }
  editGroup(groupId: number): void {
    this.userGroupId = groupId;
    this.isOpenNewGroup = true;
  }

  // REGION USERS
  async createUser(): Promise<void> {
    let data = await adminService.getDefaultPassword();
    this.plantId = 0;
    let defaultPassword = data.data;
    this.dialogItems.userNew.isEdit = false;
    this.dialogItems.userNew.d.isEnabled = true;
    this.dialogItems.userNew.d.userId = -1;
    this.dialogItems.userNew.d.userName = "";
    this.dialogItems.userNew.d.password = defaultPassword;
    this.dialogItems.userNew.d.userDescription = "";
    this.dialogItems.userNew.d.name = "";
    this.dialogItems.userNew.d.surname = "";
    this.dialogItems.userNew.d.windowsUser = "";
    this.dialogItems.userNew.d.email = "";
    this.dialogItems.userNew.d.userRole = "";
    this.dialogItems.userNew.d.auths = [];
    this.dialogItems.userNew.d.userGroupId = -1;
    this.dialogItems.userNew.key++;
    this.dialogItems.userNew.isOpen = true;
    this.dialogItems.userNew.d.serialNumber = null;
  }
  editUser(item: UserAuthInfoElab): void {
    this.dialogItems.userNew.isEdit = true;
    this.dialogItems.userNew.d.userId = item.userId;
    this.dialogItems.userNew.d.userName = item.userName;
    this.dialogItems.userNew.d.password = "";
    this.dialogItems.userNew.d.userDescription = item.userDescription;
    this.dialogItems.userNew.d.name = item.name;
    this.dialogItems.userNew.d.surname = item.surname;
    this.dialogItems.userNew.d.windowsUser = item.windowsUser;
    this.dialogItems.userNew.d.isEnabled = item.isEnabled;
    this.dialogItems.userNew.d.email = item.email;
    this.dialogItems.userNew.d.userRole = item.userRoleId;
    this.dialogItems.userNew.d.auths = item.auths;
    this.dialogItems.userNew.d.userGroupId = item.userGroupId
      ? item.userGroupId
      : -1;
    this.dialogItems.userNew.key++;
    this.dialogItems.userNew.isOpen = true;
    this.dialogItems.userNew.d.serialNumber = item.serialNumber;
  }
  deleteUser(item: UserAuthInfoElab): void {
    this.dialogItems.userDelete.userId = item.userId;
    this.dialogItems.userDelete.userDescription = item.userDescription;
    this.dialogItems.userDelete.userName = item.userName;
    this.dialogItems.userDelete.isOpen = true;
  }

  resetUserPassword(item: UserAuthInfoElab): void {
    this.dialogItems.userRetePassword.d.userId = item.userId;
    this.dialogItems.userRetePassword.title =
      "Stai resettando la password per l'utente " +
      item.userName +
      " - " +
      item.userDescription;
    this.dialogItems.userRetePassword.isOpen = true;
  }

  deleteGroup(group: GroupAuthInfoElab): void {
    this.groupId = group.userGroupId;
    this.groupName = group.userGroupName;
    // this.groupDescriptiom = this.$tc('userManagement.sureToDeleteGroup') + groupName;
    this.groupShow = true;
  }

  // REGION USERS - MODALS
  dialogItems = {
    userNew: {
      key: 0,
      isOpen: false,
      isEdit: false,
      d: {
        userId: -1,
        userGroupId: -1,
        userName: "",
        password: "",
        userDescription: "",
        name: "",
        surname: "",
        windowsUser: "",
        isEnabled: true,
        email: "",
        userRole: "",
        serialNumber: null,
        auths: [] as UserAuthInfoAuths[],
      },
    },
    userEdit: {
      key: 0,
      isOpen: false,
    },
    userDelete: {
      key: 0,
      isOpen: false,
      userId: -1,
      userDescription: "",
      userName: "",
    },
    userRetePassword: {
      key: 0,
      isOpen: false,
      title: "",
      d: {
        userId: -1,
      },
    },
    groupNew: {
      key: 0,
      isOpen: false,
    },
    groupEdit: {
      key: 0,
      isOpen: false,
    },
    groupDelete: {
      key: 0,
      isOpen: false,
    },
  };

  async duplicateUser(item: UserAuthInfoElab) {
    const response = await adminService.getPlanById(item.userId);
    this.plantId = response.data ? response.data : 0;
    let data = await adminService.getDefaultPassword();
    let defaultPassword = data.data;
    this.dialogItems.userNew.isEdit = false;
    this.dialogItems.userNew.d.isEnabled = true;
    this.dialogItems.userNew.d.userId = -1;
    this.dialogItems.userNew.d.userName = item.userName;
    this.dialogItems.userNew.d.password = defaultPassword;
    this.dialogItems.userNew.d.userDescription = "";
    this.dialogItems.userNew.d.name = item.name;
    this.dialogItems.userNew.d.surname = item.surname;
    this.dialogItems.userNew.d.windowsUser = item.windowsUser;
    this.dialogItems.userNew.d.email = "";
    this.dialogItems.userNew.d.userRole = item.userRoleId;
    this.dialogItems.userNew.d.auths = item.auths;
    this.dialogItems.userNew.d.userGroupId = item.userGroupId
      ? item.userGroupId
      : -1;
    this.dialogItems.userNew.d;
    this.dialogItems.userNew.key++;
    this.dialogItems.userNew.isOpen = true;
  }

  async exportUsers() {
    overlayModule.showOverlay();
    const response = await adminService.exportUsers(this.filters);
    const fileName = this.getFileNameFromResponse(response);

    const blob = new Blob([response.data], {
      type: "application/octet-stream",
    });

    const a = document.createElement("a");

    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(a.href);
    overlayModule.hideOverlay();
  }

  activateFileInput() {
    this.file = null;
    let fileUpload = document.getElementById("fileUpload");
    fileUpload.click();
  }

  getFileNameFromResponse(responseData: AxiosResponse<ArrayBuffer>) {
    let filename = "";
    const disposition = responseData.headers["content-disposition"];
    if (disposition && disposition.indexOf("attachment") !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }
    return filename;
  }

  onFiltersChange() {
    this.reloadUserTable();
  }

  async importUsers() {
    if (this.file) {
      overlayModule.showOverlay();
      const response = await adminService.importUsers(this.file);
      if (response.status === 200) {
        this.importUsersResponse = response.data;
        this.confirmImportDialog = true;
      }
      overlayModule.hideOverlay();
    }
  }

  toggleConfirmImportDialog() {
    this.confirmImportDialog = !this.confirmImportDialog;
    this.reloadUserTable();
  }
}
</script>
<style lang="scss">
#app {
  .mod-- {
    // Tabs
    &tab {
      .v-tabs-bar {
        height: auto;
      }
      .v-window {
        min-height: 100%;
        background: transparent;
      }
    }
  }
}
.v-data-table-small > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 18px !important;
}
.v-data-table-small > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 18px !important;
}

.v-dialog {
  background: white;
}
</style>
