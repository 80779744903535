<template>
  <div v-if="$vuetify.breakpoint.mdAndDown" class="container-header-buttons">
    <v-row>
      <!--      <v-spacer></v-spacer>-->
      <v-col cols="auto" v-if="btnNameLeft">
        <v-btn
          rounded
          class="mod--btn_primary mod--btn_primary white--text"
          :style="
            $vuetify.breakpoint.mdAndDown
              ? 'border-radius: 24px; height: 75px'
              : 'border-radius: 24px; height: 40px'
          "
          elevation="1"
          block
          @click="$emit('click:import')"
        >
          <span>{{ btnImport }}</span>
        </v-btn>
      </v-col>
      <v-col cols="auto" v-if="btnNameLeft">
        <v-btn
          outlined
          class="mod--btn_primary mod--btn_primary white--text"
          :style="
            $vuetify.breakpoint.mdAndDown
              ? 'border-radius: 24px; height: 75px'
              : 'border-radius: 24px; height: 40px'
          "
          elevation="1"
          block
          @click="$emit('click:export')"
        >
          <span>{{ btnExport }}</span>
        </v-btn>
      </v-col>
      <v-col cols="auto" v-if="btnNameLeft">
        <v-btn
          outlined
          class="mod--btn_primary mod--btn_primary white--text"
          :style="
            $vuetify.breakpoint.mdAndDown
              ? 'border-radius: 24px; height: 75px'
              : 'border-radius: 24px; height: 40px'
          "
          elevation="1"
          block
          @click="$emit('buttonLeft')"
        >
          <span>{{ btnNameLeft }}</span>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          outlined
          class="mod--btn_primary mod--btn_primary white--text"
          :style="
            $vuetify.breakpoint.mdAndDown
              ? 'border-radius: 24px; height: 75px'
              : 'border-radius: 24px; height: 40px'
          "
          elevation="1"
          block
          @click="tableAction"
        >
          <span>{{ btnName }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="pb-4" style="flex-wrap: nowrap">
      <v-col cols="auto">
        <!-- TODO: CAMBIARE PLACEHOLDER -->
        <v-text-field
          v-model="valueModel"
          :label="$tc('general.searchTable')"
          outlined
          hide-details
          @input="searchChanged"
          :class="
            $vuetify.breakpoint.mdAndDown
              ? 'mod--input; v-text-field--filled-big'
              : 'mod--input; v-text-field--filled-small'
          "
        ></v-text-field>
      </v-col>
      <v-col cols="auto">
        <user-management-filters
          :filters-prop.sync="filters"
          @filtersChanged="$emit('filtersChanged')"
        >
        </user-management-filters>
      </v-col>
    </v-row>
  </div>
  <v-row v-else class="pb-4">
    <!-- DA QUI È LA VISUALIZZAZIONE DA PC -->
    <v-col cols="auto" class="py-1">
      <!-- TODO: CAMBIARE PLACEHOLDER -->
      <v-text-field
        v-model="valueModel"
        :label="$tc('general.searchTable')"
        solo
        outlined
        hide-details
        @input="searchChanged"
        :class="
          $vuetify.breakpoint.mdAndDown
            ? 'mod--input; v-text-field--filled-big'
            : 'mod--input; v-text-field--filled-small'
        "
        :height="$vuetify.breakpoint.mdAndDown ? 96 : 40"
      ></v-text-field>
      <user-management-filters
        :filters-prop.sync="filters"
        @filtersChanged="$emit('filtersChanged')"
      >
      </user-management-filters>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="auto" v-if="btnNameLeft">
      <v-btn
        rounded
        class="mod--btn_primary mod--btn_primary white--text"
        :style="
          $vuetify.breakpoint.mdAndDown
            ? 'border-radius: 24px; height: 75px'
            : 'border-radius: 24px; height: 40px'
        "
        elevation="1"
        block
        @click="$emit('click:import')"
      >
        <span>{{ btnImport }}</span>
      </v-btn>
    </v-col>
    <v-col cols="auto" v-if="btnNameLeft">
      <v-btn
        outlined
        class="mod--btn_primary mod--btn_primary white--text"
        :style="
          $vuetify.breakpoint.mdAndDown
            ? 'border-radius: 24px; height: 75px'
            : 'border-radius: 24px; height: 40px'
        "
        elevation="1"
        block
        @click="$emit('click:export')"
      >
        <span>{{ btnExport }}</span>
      </v-btn>
    </v-col>
    <v-col cols="auto" v-if="btnNameLeft">
      <v-btn
        outlined
        class="mod--btn_primary mod--btn_primary white--text"
        :style="
          $vuetify.breakpoint.mdAndDown
            ? 'border-radius: 24px; height: 75px'
            : 'border-radius: 24px; height: 40px'
        "
        elevation="1"
        block
        @click="$emit('buttonLeft')"
      >
        <span>{{ btnNameLeft }}</span>
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn
        outlined
        class="mod--btn_primary mod--btn_primary white--text"
        :style="
          $vuetify.breakpoint.mdAndDown
            ? 'border-radius: 24px; height: 75px'
            : 'border-radius: 24px; height: 40px'
        "
        elevation="1"
        block
        @click="tableAction"
      >
        <span>{{ btnName }}</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Vue, Prop, VModel, Model } from "vue-property-decorator";
import UserManagementFilters from "@/components/admin/UserManagementFilters.vue";
import { UserManagementFiltersInterface } from "@/models/entities/admin/UserManagementFilters.interface";

@Component({
  components: { UserManagementFilters },
})
export default class AdminTableRow extends Vue {
  @VModel({ default: null })
  valueModel: undefined;
  // Defines btn name:
  @Prop({ required: false, type: String, default: "Importa" })
  private btnImport: string | undefined;
  @Prop({ required: false, type: String, default: "Esporta" })
  private btnExport: string | undefined;

  @Prop({ required: false, type: String, default: "Nuovo" })
  private btnName: string | undefined;

  @Prop({ required: false, type: Object })
  filters: UserManagementFiltersInterface;

  @Prop({ required: false, type: String, default: "" })
  public btnNameLeft: string;
  // Emitted events:
  private searchChanged(): void {
    this.$emit("searchChanged");
  }
  private tableAction(): void {
    this.$emit("tableAction");
  }

  // test() {
  //   console.log(this.filters);
  // }
}
</script>
<style lang="scss">
.v-text-field--filled-small > .v-input__control > .v-input__slot {
  min-height: 18px;
  border-radius: 24px;
  box-shadow: #{0px 2px 4px};
}

.v-text-field--filled-big > .v-input__control > .v-input__slot {
  border-radius: 24px;
  box-shadow: #{0px 2px 4px};
}

.container-header-buttons {
  padding: 3px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
</style>
