import { FileResponse } from "@/models/entities/fileFormats/fileResponse.interface";
import { EquipmentFile } from "@/models/entities/fileFormats/fileFormats.interface";

import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";

const CONTROLLER = "equipment";
class EquipmentService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }

  getFile(id: string) {
    return this.getAll<EquipmentFile[]>(
      `${CONTROLLER}/getfile?equipmentCode=${id}`
    );
  }

  getEquipmentLocation(id: string) {
    return this.getAll<string>(
      `${CONTROLLER}/getequipmentlocation?equipmentCode=${id}`
    );
  }

  getExcel(id: string) {
    return this.getAll<FileResponse>(
      `${CONTROLLER}/getexcel?equipmentCode=${id}`
    );
  }

  getPdf(id: string) {
    return this.getAll<FileResponse>(`${CONTROLLER}/getpdf?code=${id}`);
  }
}

export const equipmentService = new EquipmentService();
