import { FileResponse } from "@/models/entities/fileFormats/fileResponse.interface";
import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";

const CONTROLLER = "mp";
class MPService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }
  getPdfNames(id: string) {
    return this.getAll<string[]>(`${CONTROLLER}/getPdfNames?MPcode=${id}`);
  }

  getPdf(id: string) {
    return this.getAll<FileResponse>(`${CONTROLLER}/getpdf?MPcode=${id}`);
  }
}

export const mpService = new MPService();
