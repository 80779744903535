import { render, staticRenderFns } from "./LegendDialog.vue?vue&type=template&id=39e6105c&scoped=true"
import script from "./LegendDialog.vue?vue&type=script&lang=ts"
export * from "./LegendDialog.vue?vue&type=script&lang=ts"
import style0 from "./LegendDialog.vue?vue&type=style&index=0&id=39e6105c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39e6105c",
  null
  
)

export default component.exports