<template :key="">
  <v-dialog v-model="isOpen" :width="700">
    <v-card class="pa-6">
      <v-row>
        <v-col v-if="this.userGroupId == 0" cols="12">
          <strong>{{ $tc("adminComponents.addNewGroup") }}</strong>
        </v-col>
        <v-col v-else cols="12">
          <strong>{{ $tc("adminComponents.modifyGroup") }}</strong>
        </v-col>
      </v-row>
      <v-row class="pt-8">
        <v-col cols="12">
          <input-row
            :inTitle="$tc('adminComponents.groupName')"
            v-model="saveGroupAuthRequest.userGroupName"
          ></input-row
        ></v-col>
        <v-col cols="4">
          <strong
            :style="
              $vuetify.breakpoint.mdAndDown
                ? 'font-size: 26px'
                : 'font-size: 18px'
            "
            >{{ $tc("adminComponents.inAuth") }}</strong
          >
        </v-col>
        <v-col cols="8">
          <div
            class="d-flex"
            v-for="auth in saveGroupAuthRequest.authorizationsGroup"
            :key="auth.authId"
          >
            <div v-if="auth.authId !== 'USR'" class="d-flex">
              <v-checkbox
                class="ma-0"
                dense
                v-model="auth.isChecked"
                color="primary"
                :key="auth.authId"
              ></v-checkbox>
              <v-chip :class="'mr-2 mod--chip ' + chipColor(auth.authId)">
                {{ auth.authName }}
              </v-chip>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn color="primary" outlined block @click="closeModal">
              {{ $tc("general.cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              v-if="this.userGroupId == 0"
              color="primary"
              block
              @click="addGroup"
            >
              {{ $tc("adminComponents.createNewGroup") }}
            </v-btn>
            <v-btn v-else color="primary" block @click="modifyGroup">
              {{ $tc("adminComponents.modifyGroup") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { adminService } from "@/services/api/admin.service";
import OverlayModule from "@/store/modules/overlay.module";
import {
  Component,
  Vue,
  Prop,
  VModel,
  Watch,
  PropSync,
} from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import { AuthItemNamed } from "@/models/entities/auth/authItem.interface";
import { custom } from "@/helpers/customMethods";
import { UserCreateModal } from "@/models/entities/admin/admin.interface";
import { authRequestService } from "@/services/api/authRequest.service";
import { GroupCreateItem } from "@/models/entities/admin/groupItem.interface";
import { SaveGroupAuthRequest } from "@/models/entities/auth/saveGroupAuthRequest.interface";

const overlayModule = getModule(OverlayModule, store);

@Component
export default class DialogGroupNew extends Vue {
  @VModel({ default: false })
  isOpen: boolean | undefined;

  @Prop({ default: 0, required: false, type: Number })
  private userGroupId: number;

  // Defines wether dialog is persistent.
  private authItemNamedCheck: AuthItemNamed[] = [];
  private userGroupName = "";
  private saveGroupAuthRequest: SaveGroupAuthRequest = {
    authorizationsGroup: [],
    userGroupId: 0,
    userGroupName: "",
  };
  private modalData = {} as UserCreateModal;
  // Emitted events:
  private async created(): Promise<void> {
    if (this.userGroupId == 0) {
      this.saveGroupAuthRequest.authorizationsGroup = (
        await adminService.getAllAuths()
      ).data;
    } else {
      this.saveGroupAuthRequest.authorizationsGroup = (
        await adminService.getAllAuths()
      ).data;
      if (this.userGroupId != 0) {
        this.authItemNamedCheck = (
          await authRequestService.getAuthsGroup(this.userGroupId)
        ).data;
        this.saveGroupAuthRequest.authorizationsGroup.forEach((x) => {
          this.authItemNamedCheck.forEach((y) => {
            if (x.authId == y.authId) {
              x.isChecked = true;
            }
          });
        });
        this.saveGroupAuthRequest.userGroupName = (
          await authRequestService.getGroupName(this.userGroupId)
        ).data;
      } else {
        this.saveGroupAuthRequest.userGroupName = "";
      }
    }
  }

  // @Watch("userGroupId")
  // private async getAuthItemNamed() {
  //   this.saveGroupAuthRequest.authorizationsGroup = (
  //     await adminService.getAllAuths()
  //   ).data;
  //   if (this.userGroupId != 0) {
  //     this.authItemNamedCheck = (
  //       await authRequestService.getAuthsGroup(this.userGroupId)
  //     ).data;
  //     this.saveGroupAuthRequest.authorizationsGroup.forEach((x) => {
  //       this.authItemNamedCheck.forEach((y) => {
  //         if (x.authId == y.authId) {
  //           x.isChecked = true;
  //         }
  //       });
  //     });
  //     this.saveGroupAuthRequest.userGroupName = (
  //       await authRequestService.getGroupName(this.userGroupId)
  //     ).data;
  //   } else {
  //     this.saveGroupAuthRequest.userGroupName = "";
  //   }
  // }

  private closeModal(): void {
    this.isOpen = false;
    this.saveGroupAuthRequest.userGroupName = "";
    this.saveGroupAuthRequest.userGroupId = 0;
    this.saveGroupAuthRequest.authorizationsGroup = [];
    this.$emit("closeModal");
    this.$emit("updateUsers");
  }

  private chipColor(authId: string): string {
    return custom.defineChipColor(authId);
  }

  private async modifyGroup() {
    overlayModule.showOverlay();
    // await adminService.groupsCreate(this.groupCreateItem);
    this.saveGroupAuthRequest.userGroupId = this.userGroupId;
    await authRequestService.changeGroupAuth(this.saveGroupAuthRequest);
    overlayModule.hideOverlay();
    this.closeModal();
  }

  private async addGroup() {
    overlayModule.showOverlay();
    // await adminService.groupsCreate(this.groupCreateItem);
    try {
      await authRequestService.newGroupsAdd(this.saveGroupAuthRequest);
    } catch (err) {
      overlayModule.hideOverlay();
    }
    overlayModule.hideOverlay();
    this.closeModal();
  }
}
</script>
