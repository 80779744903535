import { AuthItem } from "@/models/entities/auth/authItem.interface";
import { LoginResponse } from "@/models/entities/auth/loginResponse.interface";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "auth" })
class AuthModule extends VuexModule {
  /* eslint-disable */
  private authState: LoginResponse = null;
  /* eslint-enable */
  private enabledSectionsList: AuthItem[] = [];
  private _usernameToResetPassword = "";

  private _currentAuthId = "";

  get userID(): number {
    return this.authState
      ? this.authState.user
        ? this.authState.user.userID
        : -1
      : -1;
  }

  get usernameToResetPassword(): string {
    return this._usernameToResetPassword;
  }
  get userName(): string {
    return this.authState
      ? this.authState.user
        ? this.authState.user.userName
        : ""
      : "";
  }
  get userDescription(): string {
    return this.authState
      ? this.authState.user
        ? this.authState.user.userDescription
        : ""
      : "";
  }
  get email(): string {
    return this.authState
      ? this.authState.user
        ? this.authState.user.email
        : ""
      : "";
  }
  get isEnabled(): boolean {
    return this.authState
      ? this.authState.user
        ? this.authState.user.isEnabled
        : false
      : false;
  }
  get needspasswordReset(): boolean {
    return this.authState
      ? this.authState.user
        ? this.authState.user.needspasswordReset
        : false
      : false;
  }
  get userRoleId(): string {
    return this.authState
      ? this.authState.user
        ? this.authState.user.userRoleId
        : ""
      : "";
  }

  get currentAuthId() {
    return this._currentAuthId;
  }
  get userGroupId(): number {
    return this.authState
      ? this.authState.user
        ? this.authState.user.userGroupId
        : -1
      : -1;
  }
  get token(): boolean {
    return this.authState ? this.authState.token : false;
  }

  get isUserQA(): boolean {
    if (!this.authState) return false;
    return this.authState.user.userGroupName === "Qualità";
  }

  get sectionsList(): AuthItem[] {
    return this.enabledSectionsList;
  }

  @Mutation
  public setLogin(state: LoginResponse): void {
    this.authState = state;
  }

  @Mutation
  private _setCurrentAuthId(authId: string): void {
    this._currentAuthId = authId;
  }

  @Action
  public login(state: LoginResponse): void {
    this.context.commit("setLogin", state);
  }

  @Mutation
  public setLogout(): void {
    this.authState = null;
    this.enabledSectionsList = [];
  }
  @Action
  public logout(): void {
    this.context.commit("setLogout");
  }

  @Mutation
  public setEnabledSections(state: AuthItem[]): void {
    this.enabledSectionsList = state;
  }
  @Action
  public enabledSections(state: AuthItem[]): void {
    this.context.commit("setEnabledSections", state);
  }

  @Mutation
  private addSingleEnabledSection$(state: AuthItem): void {
    this.enabledSectionsList.push(state);
  }
  @Action
  public addSingleEnabledSection(state: AuthItem): void {
    this.context.commit("addSingleEnabledSection$", state);
  }

  @Action
  public setCurrentAuthId(authId: string): void {
    this.context.commit("_setCurrentAuthId", authId);
  }
}

export default AuthModule;
