import { render, staticRenderFns } from "./Normalized.vue?vue&type=template&id=04a90a5a&scoped=true"
import script from "./Normalized.vue?vue&type=script&lang=ts"
export * from "./Normalized.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a90a5a",
  null
  
)

export default component.exports