<template>
  <div style="height: 100%">
    <!-- :allowAutoFill="allowEdit"
      :allowConditionalFormat="allowEdit"
      :allowDataValidation="allowEdit"
      :allowDelete="allowEdit"
      :allowFiltering="allowEdit"
      :allowFindAndReplace="allowEdit"
      :allowFreezePane="allowEdit"
      :allowMerge="allowEdit"
      :allowResizing="allowEdit"
      :allowSave="allowEdit"
      :allowScrolling="allowEdit"
      :allowSorting="allowEdit"
      :allowUndoRedo="allowEdit"
      :allowWrap="allowEdit"
      :allowChart="allowEdit"
      :allowInsert="allowEdit"
      :allowHyperlink="allowEdit"
      :allowImage="allowEdit"
      :allowCellFormatting="allowEdit"
      :allowNumberFormatting="allowEdit"
      :enableClipboard="allowEdit" -->
    <!--    <pdf-viewer v-if="base64 != ''" :base64pdf="base64"> </pdf-viewer>-->
    <zelando-pdf-viewer
      ref="pdfViewer"
      :filePath="`data:application/pdf;base64,${base64}`"
      @on-success="onLoad"
    >
    </zelando-pdf-viewer>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Provide, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { mpService } from "@/services/api/mp.service";
import { productService } from "@/services/api/product.service";
import { msaService } from "@/services/api/msa.service";
import { equipmentService } from "@/services/api/equipment.service";
import { sttService } from "@/services/api/stt.service";
import { RequestSTSFileInterface } from "@/models/entities/sts/RequestSTSFile.interface";
import { stsService } from "@/services/api/sts.service";
import { maintenanceService } from "@/services/api/maintenance.service";
import { RequestManualFileInterface } from "@/models/entities/maintenance/requestManualFile.interface";
import { rapidLinkService } from "@/services/api/rapidLink.service";
import ZelandoPdfViewer from "@zelando/pdf-viewer/src/components/ZelandoPdfViewer.vue";
import { activePartsAndNormalizedService } from "@/services/api/activePartsAndNormalized.service";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class PdfOpener extends Vue {
  private base64 = "";
  loaded = false;

  private async created() {
    let type = this.$route.query.type;
    let code: string | string[];
    let fileName: string | string[];
    let assetName: string | string[];
    let folderName: string | string[];
    let sheetType: string | string[];
    let lang: string | string[];
    let productId: string | string[];
    let request: RequestManualFileInterface;
    let isPdm: boolean;
    let pdmCode: string | string[];
    let revision: string | string[];
    let equipmentCode: string | string[];
    let position: number;
    let underPosition: string | string[];

    switch (type) {
      case "MP":
        code = this.$route.query.code;
        document.title = String(this.$route.query.code);
        this.base64 = (await mpService.getPdf(code.toString())).data.value;
        break;
      case "Product":
        sheetType = this.$route.query.sheetType;
        lang = this.$route.query.lang;
        productId = this.$route.query.productId;
        document.title = String(this.$route.query.productName);
        this.base64 = (
          await productService.getFilePath({
            sheetType: String(sheetType),
            lang: String(lang),
            productId: Number(productId),
          })
        ).data.value;
        break;
      case "MSA":
        code = this.$route.query.code;
        document.title = String(this.$route.query.code);
        this.base64 = (await msaService.getPdf(code.toString())).data.value;
        break;
      case "STT":
        code = this.$route.query.code;
        document.title = String(this.$route.query.code);
        this.base64 = (await sttService.getPdf(code.toString())).data.value;
        break;
      case "STS": {
        code = this.$route.query.code;
        folderName = this.$route.query.folderName;
        document.title = String(this.$route.query.code);
        const request: RequestSTSFileInterface = {
          folderName: folderName?.toString(),
          msaCode: code?.toString(),
        };
        this.base64 = (await stsService.getPdf(request)).data.value;
        break;
      }
      case "Equipment":
        code = this.$route.query.code;
        document.title = String(this.$route.query.code);
        this.base64 = (
          await equipmentService.getPdf(code.toString())
        ).data.value;
        break;

      case "MANMNT":
        assetName = this.$route.query.assetName;
        fileName = this.$route.query.fileName;
        folderName = this.$route.query.folderName;
        document.title = String(this.$route.query.fileName);
        request = {
          assetName: assetName,
          fileName: fileName,
          folderName: folderName,
        };
        this.base64 = (await maintenanceService.getFile(request)).data.value;
        break;
      case "SECCHART":
        this.base64 = (
          await rapidLinkService.getFileSecurityChart()
        ).data.value;
        break;
      case "ACTIVEPARTS":
        isPdm = this.$route.query.isPdm === "true";
        pdmCode = this.$route.query.pdmCode;
        revision = this.$route.query.revision;
        equipmentCode = this.$route.query.equipmentCode;
        position = parseInt(this.$route.query.position?.toString());
        underPosition = this.$route.query.underPosition;
        if (isPdm) {
          try {
            overlayModule.showOverlay();
            this.base64 = (
              await activePartsAndNormalizedService.searchFilePdm(
                pdmCode?.toString(),
                revision?.toString()
              )
            ).data;
            overlayModule.hideOverlay();
          } catch (e) {
            if (e.response.status === 404) {
              snackbarModule.showSnackbar({
                message: e.response.data,
                type: "error",
              });
              setTimeout(() => {
                snackbarModule.hideSnackbar();
              }, 8000);
            }
          }
        } else {
          try {
            overlayModule.showOverlay();
            this.base64 = (
              await activePartsAndNormalizedService.searchFileNONPdm(
                equipmentCode?.toString(),
                position,
                underPosition?.toString()
              )
            ).data;
            overlayModule.hideOverlay();
          } catch (e) {
            if (e.response.status === 404) {
              snackbarModule.showSnackbar({
                message: e.response.data,
                type: "error",
              });
              setTimeout(() => {
                snackbarModule.hideSnackbar();
              }, 8000);
            }
          }
        }
        break;
      case "NORMALIZED":
        pdmCode = this.$route.query.pdmCode;
        revision = this.$route.query.revision;
        try {
          overlayModule.showOverlay();
          this.base64 = (
            await activePartsAndNormalizedService.searchFilePdm(
              pdmCode?.toString(),
              revision?.toString()
            )
          ).data;
          overlayModule.hideOverlay();
        } catch (e) {
          if (e.response.status === 404) {
            snackbarModule.showSnackbar({
              message: e.response.data,
              type: "error",
            });
            setTimeout(() => {
              snackbarModule.hideSnackbar();
            }, 8000);
          }
        }

        break;
      default:
        break;
    }
  }

  async onLoad() {
    const pdfViewer = this.$refs.pdfViewer as InstanceType<
      typeof ZelandoPdfViewer
    >;
    const canvasParent = document.getElementsByClassName(
      "canvasWrapper"
    )[0] as HTMLElement;
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const pdf = pdfViewer.getPDF();
    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 1 });
    const pdfWidth = viewport.width;
    const pdfHeight = viewport.height;

    const canvasRect = canvasParent.getBoundingClientRect();
    const canvasWidth = canvasRect.width;
    const canvasHeight = canvasRect.height;

    const scaleX = screenWidth / pdfWidth;
    const scaleY = screenHeight / pdfHeight;
    const scale = Math.min(scaleX, scaleY);

    pdfViewer.changeScale(scale);

    canvasParent.style.width = `${canvasWidth * scale}px`;
    canvasParent.style.height = `${canvasHeight * scale}px`;
  }
}
</script>

<style>
.e-sheet-panel {
  height: calc(95vh - 16px);
}
</style>
