<template>
  <div class="mod--fill_height d-flex flex-column">
    <div class="pa-4 flex-grow-1">
      <v-row justify="center">
        <v-col cols="3" style="text-align: left">
          <v-btn
            v-if="hideBtn != 'true'"
            to="/home"
            class="mod--btn reduce-back-btn ma-3"
            x-large
          >
            <v-icon left :size="32" class="mod--icon"> mdi-arrow-left </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6" style="text-align: center" class="pb-12 pt-6">
          <h1 class="primary--text">{{ $tc("changePassword.title") }}</h1>
        </v-col>
        <v-col cols="3"></v-col>
        <v-col cols="12">
          <v-row justify="center">
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <v-form v-model="isFormValid" @submit.prevent="onSubmit">
                <v-row
                  v-if="hideBtn != 'true' && !isChangePasswordByRecovery"
                  justify="center"
                >
                  <v-col style="max-width: 450px">
                    <password-row
                      v-model="userResetPassword.oldPassword"
                      :rules="[required]"
                      class="pb-3"
                      :label="$tc('changePassword.oldPassword')"
                    ></password-row>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col style="max-width: 450px">
                    <password-row
                      v-model="passwordChanged"
                      :rules="[required, password]"
                      class="pb-3"
                      :label="$tc('changePassword.label')"
                    ></password-row>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col style="max-width: 450px">
                    <password-row
                      iconName="mdi-lock"
                      :rules="[
                        required,
                        password,
                        sameValue(passwordChanged, userResetPassword.password),
                      ]"
                      v-model="userResetPassword.password"
                      inType="text"
                      :label="$tc('changePassword.labelConfirm')"
                    ></password-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3"></v-col>
                  <v-col cols="6">
                    <v-btn
                      v-if="!isChangePasswordByRecovery"
                      outlined
                      class="mod--btn mod--btn_primary white--text mt-4"
                      elevation="1"
                      block
                      x-large
                      type="submit"
                    >
                      <span>{{ $tc("changePassword.changePassword") }}</span>
                    </v-btn>
                    <v-btn
                      v-else
                      outlined
                      class="mod--btn mod--btn_primary white--text mt-4"
                      elevation="1"
                      block
                      x-large
                      type="submit"
                    >
                      <span>{{ $tc("changePassword.changePassword") }}</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="3"></v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col cols="3">
              <div
                style="display: flex; flex-direction: column; column-gap: 12px"
              >
                <h2 style="margin-bottom: 12px">
                  {{ $tc("changePassword.PasswordRequirements") }}
                </h2>
                <span
                  :class="{
                    'password-has-capital-letter': passwordHasCapitalLetter,
                    'password-has-not-capital-letter':
                      !passwordHasCapitalLetter,
                  }"
                  >{{ $tc("changePassword.CapitalLetter") }}</span
                >
                <span
                  :class="{
                    'password-has-lowercase-letter': passwordHasLowercaseLetter,
                    'password-has-not-lowercase-letter':
                      !passwordHasLowercaseLetter,
                  }"
                  >{{ $tc("changePassword.LowerCaseLetter") }}</span
                >
                <span
                  :class="{
                    'password-has-6chars': passwordChanged?.length > 5,
                    'password-has-not-6chars': passwordChanged?.length <= 5,
                  }"
                  >{{ $tc("changePassword.6Chars") }}</span
                >
                <span
                  :class="{
                    'password-has-number': passwordHasNumber,
                    'password-has-not-number': !passwordHasNumber,
                  }"
                  >{{ $tc("changePassword.Number") }}</span
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { password, required, sameValue } from "@/helpers/rules";
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { adminService } from "@/services/api/admin.service";
import {
  RequestResetPasswordByRecovery,
  UserResetPassword,
} from "@/models/entities/admin/admin.interface";

const snackBarModule = getModule(SnackbarModule, store);
const authModule = getModule(AuthModule, store);

@Component
export default class ChangePassword extends Vue {
  @Prop({ type: String, default: "", required: false })
  private hideBtn: string;

  @Prop({ type: Boolean, default: false, required: false })
  isChangePasswordByRecovery: boolean;

  @Prop({ type: String, default: "", required: false })
  username: string;
  @Prop({ type: String, default: "", required: false })
  question: string;
  @Prop({ type: String, default: "", required: false })
  answer: string;

  userName = "";
  pw = "";
  isFormValid = "";
  passwordChanged = "";
  required = required;
  password = password;
  sameValue = sameValue;

  private userResetPassword: UserResetPassword = {
    password: "",
    userId: 0,
    oldPassword: "",
  };

  get passwordHasCapitalLetter() {
    return /[A-Z]/.test(this.passwordChanged);
  }

  get passwordHasLowercaseLetter() {
    return /[a-z]/.test(this.passwordChanged);
  }

  get passwordHasNumber() {
    return /\d/.test(this.passwordChanged);
  }

  onSubmit() {
    this.isChangePasswordByRecovery
      ? this.changePasswordByRecovery()
      : this.changePassword();
  }

  async changePassword(): Promise<void> {
    if (this.isFormValid) {
      if (this.hideBtn == "true") {
        const data = await adminService.getDefaultPassword();
        this.userResetPassword.oldPassword = data.data;
      }
      this.userResetPassword.userId = authModule.userID;
      try {
        await adminService.usersResetPassword(this.userResetPassword);
        this.$router.push("home");
        snackBarModule.appearSnackbar({
          message: this.$tc("changePassword.success"),
          type: "success",
        });
      } catch (e) {
        snackBarModule.appearSnackbar({
          message: this.$tc("changePassword.error"),
          type: "error",
        });
      }
    } else {
      snackBarModule.appearSnackbar({
        message: this.$tc("changePassword.alert"),
        type: "error",
      });
    }
  }

  async changePasswordByRecovery() {
    if (this.isFormValid) {
      const requestResetPasswordByRecovery: RequestResetPasswordByRecovery = {
        username: this.username,
        question: this.question,
        answer: this.answer,
        password: this.userResetPassword.password,
      };
      await adminService.resetPasswordByRecovery(
        requestResetPasswordByRecovery
      );
      await this.$router.push({
        name: "login",
      });
      snackBarModule.appearSnackbar({
        message: this.$tc("general.savedSuccess"),
        type: "success",
      });
    } else {
      snackBarModule.appearSnackbar({
        message: this.$tc("changePassword.alert"),
        type: "error",
      });
    }
  }
}
</script>

<style scoped>
.login-bg-img {
  background-image: url("@/assets/background/Sfondo_schermate-05.jpg") !important;
  background-size: 100%;
}

.password-has-capital-letter {
  color: black;
}

.password-has-not-capital-letter {
  color: red;
}

.password-has-lowercase-letter {
  color: black;
}

.password-has-not-lowercase-letter {
  color: red;
}

.password-has-6chars {
  color: black;
}

.password-has-not-6chars {
  color: red;
}

.password-has-number {
  color: black;
}

.password-has-not-number {
  color: red;
}
</style>
