export const required = (v: string): boolean | string =>
  !!v || "Campo obbligatorio";
export const email = (v: string): boolean =>
  !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v);
export const sameValue = (v: string, vSame: string): boolean =>
  !v || !vSame || v === vSame;

export const password = (v: string): boolean | string =>
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/.test(v) ||
  "Password non valida";
