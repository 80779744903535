<template>
  <div id="page" style="padding: 12px">
    <v-row no-gutters>
      <v-col cols="12" class="pb-12">
        <title-bar
          :title="$tc('activeParts.Title')"
          :is-parent-element="false"
        ></title-bar>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="step > 1">
      <div style="width: 450px">
        <v-row no-gutters>
          <v-container style="cursor: pointer" @click="backToFirstStep">
            <v-icon color="black">mdi-keyboard-backspace</v-icon>
            <span>{{ $tc("general.back") }}</span>
          </v-container>
        </v-row>
      </div>
    </v-row>
    <v-container v-if="step === 1">
      <v-row justify="center">
        <v-col cols="auto" style="width: 450px" class="pb-5">
          <input-center
            inPlaceHolder="INSERISCI CODICE ATTREZZATURA"
            v-model="equipmentCode"
          ></input-center>
        </v-col>
      </v-row>
      <v-row class="pt-10" justify="center">
        <v-col style="width: 270px" cols="auto">
          <v-btn
            class="mod--btn flex-grow-1"
            style="width: 100%"
            x-large
            @click="submitSearch"
          >
            {{ $tc("general.search") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="step === 2">
      <v-container v-if="isPdm">
        <v-row justify="center">
          <v-col cols="auto" style="width: 450px" class="pb-5">
            <input-center
              inPlaceHolder="INSERISCI CODICE PDM"
              v-model="pdmCode"
            ></input-center>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="revision">
          <v-col cols="auto" style="width: 450px" class="pb-5">
            <input-center
              inPlaceHolder="REVISIONE"
              in-is-readonly
              clearable
              v-model="revision"
            ></input-center>
          </v-col>
        </v-row>
        <v-row class="pt-10" justify="center" v-if="!revision">
          <v-col cols="auto">
            <v-btn
              class="mod--btn flex-grow-1"
              style="width: 100%"
              x-large
              @click="getRevision"
            >
              {{ $tc("general.getRevision") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pt-10" justify="center" v-if="revision">
          <v-col cols="auto">
            <v-btn
              class="mod--btn flex-grow-1"
              style="width: 100%"
              x-large
              @click="getFile"
            >
              {{ $tc("general.getFile") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row justify="center">
          <v-col cols="auto" style="width: 450px" class="pb-5">
            <input-center
              inPlaceHolder="INSERISCI POSIZIONE"
              type="number"
              v-model="position"
            ></input-center>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto" style="width: 450px" class="pb-5">
            <input-center
              inPlaceHolder="INSERISCI SOTTO POSIZIONE"
              v-model="underPosition"
            ></input-center>
          </v-col>
        </v-row>
        <v-row class="pt-10" justify="center">
          <v-col style="width: 270px" cols="auto">
            <v-btn
              class="mod--btn flex-grow-1"
              style="width: 100%"
              x-large
              @click="getFile"
            >
              {{ $tc("general.getFile") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <!-- DIALOGS -->
    <select-revision-dialog
      v-model="showSelectRevisionDialog"
      :revision-list="revisionList"
      @submitRevision="onSubmitRevision"
    ></select-revision-dialog>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import { equipmentManagementService } from "@/services/api/equipmentManagement.service";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
import { PdmRevisionRequest } from "@/models/entities/equipmentManagement/PdmRevisionRequest.interface";
import SelectRevisionDialog from "@/components/equipmentManagement/SelectRevisionDialog.vue";
import { ActivePartRevisionItem } from "@/models/entities/equipmentManagement/ActivePartRevisionItem.interface";
import { AxiosResponse } from "axios";
import { activePartsAndNormalizedService } from "@/services/api/activePartsAndNormalized.service";

const snackbarModule = getModule(SnackbarModule, store);

@Component({
  components: { SelectRevisionDialog },
})
export default class ActiveParts extends Vue {
  equipmentCode = "";
  revision = "";
  revisionList: ActivePartRevisionItem[] = [];
  showSelectRevisionDialog = false;
  pdmCode = "";
  isPdm = false;
  position: number = null;
  underPosition = "";
  step = 1;

  async getPdm() {
    if (!this.equipmentCode) {
      snackbarModule.appearSnackbar({
        message: this.$tc("activeParts.EquipmentCodeFieldRequired"),
        type: "warning",
      });
      return;
    }
    const response = await equipmentManagementService.getPdm(
      this.equipmentCode
    );
    if (response.data) {
      this.isPdm = response.data;
    }
    this.step = 2;
  }

  async getRevision() {
    if (!this.pdmCode) {
      snackbarModule.appearSnackbar({
        message: this.$tc("activeParts.PdmCodeFieldRequired"),
        type: "warning",
      });
      return;
    }
    const request: PdmRevisionRequest = {
      stCodPDM: this.pdmCode,
      stCdAtrAs: this.equipmentCode,
    };
    const response = await equipmentManagementService.getPDMRevisionActiveParts(
      request
    );
    if (response.data.length === 1) {
      this.revision = response.data[0].revisionePDM;
    } else {
      this.showSelectRevisionDialog = true;
      this.revisionList = response.data;
    }
  }

  submitSearch() {
    this.revision = "";
    this.getPdm();
  }

  onSubmitRevision(revisionSelected: string) {
    if (revisionSelected) this.revision = revisionSelected;
  }

  backToFirstStep() {
    this.step = 1;
    this.revision = "";
  }

  async getFile() {
    if (!this.isPdm) {
      if (!this.position) {
        snackbarModule.appearSnackbar({
          message: this.$tc("activeParts.PositionRequired"),
          type: "error",
        });
        return;
      }
    } else {
      if (!this.pdmCode || !this.revision) {
        snackbarModule.appearSnackbar({
          message: this.$tc("activeParts.ImpossibleGetFile"),
          type: "error",
        });
        return;
      }
    }
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: {
        isPdm: this.isPdm.toString(),
        pdmCode: this.pdmCode,
        revision: this.revision,
        equipmentCode: this.equipmentCode,
        position: this.position?.toString(),
        underPosition: this.underPosition,
        type: "ACTIVEPARTS",
      },
    });
    window.open(routeData.href, "_blank");
  }
}
</script>

<style scoped></style>
