import { AuthItem } from "@/models/entities/auth/authItem.interface";
import { LoginRequest } from "@/models/entities/auth/loginRequest.interface";
import { LoginResponse } from "@/models/entities/auth/loginResponse.interface";
import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";
import {
  CheckAnswerValidity,
  CheckAnswerValidityResponse,
} from "@/models/entities/auth/CheckAnswerValidity.interface";

const CONTROLLER = "users";
class AuthenticateService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }

  login(credentials: LoginRequest) {
    return this.post<LoginRequest, LoginResponse>(
      `${CONTROLLER}/login`,
      credentials
    );
  }

  logout() {
    return this.getAll<void>(`${CONTROLLER}/logout`);
  }

  checkLogin() {
    return this.getAll<boolean>(`${CONTROLLER}/checkLogin`);
  }

  getAuthsForId(id: number) {
    return this.getAll<AuthItem[]>(`${CONTROLLER}/getAuthsForID?userID=${id}`);
  }

  saveQuestion(request: { question: string; answer: string }) {
    return this.post<{ question: string; answer: string }, void>(
      `${CONTROLLER}/securityquestion`,
      request
    );
  }

  getQuestionByUsername(username: string) {
    return this.getAll<string>(
      `${CONTROLLER}/securityquestionbyuser?username=${username}`
    );
  }

  checkAnswerValidity(request: CheckAnswerValidity) {
    return this.post<CheckAnswerValidity, CheckAnswerValidityResponse>(
      `${CONTROLLER}/isanswervalid`,
      request
    );
  }
}

export const authService = new AuthenticateService();
