<template :key="">
  <v-dialog v-model="isOpen" :persistent="persistent" :width="700">
    <v-card class="pa-6">
      <v-card-title>Vuoi procedere?</v-card-title>
      <span class="pl-6">
        {{ _gruopDescription + "?" }}
      </span>
      <!-- TODO: MAKE INTO A SELECT -->
      <v-card-actions class="pt-4">
        <v-row>
          <v-col cols="6">
            <v-btn color="primary" outlined block @click="closeModal">
              {{ $tc("general.cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn color="primary" block @click="submitUserDelete">
              {{ $tc("general.confirm") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { adminService } from "@/services/api/admin.service";
import { Component, Vue, Prop, VModel } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
const snackbarModule = getModule(SnackbarModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component
export default class DialogDeleteGroup extends Vue {
  @VModel({ default: false })
  isOpen: boolean;
  // Defines wether dialog is persistent.
  @Prop({ required: false, type: Boolean, default: false })
  persistent: boolean;

  // Defines variables, case edit.
  @Prop({ required: true, type: Number })
  _groupId: number;

  @Prop({ required: true, type: String })
  _nameGroup: string;

  @Prop({ required: true, type: String })
  _gruopDescription: string;

  private closeModal(): void {
    this.isOpen = false;
    this.$emit("closeModal");
  }

  private async submitUserDelete(): Promise<void> {
    overlayModule.showOverlay();
    try {
      await adminService.deleteGroup(this._groupId);
    } catch (Exception) {
      snackbarModule.appearSnackbar({
        message: this.$tc("userManagement.notDeletedGroup"),
        type: "error",
      });
      overlayModule.hideOverlay();
      return;
    }

    snackbarModule.appearSnackbar({
      message: this.$tc("adminComponents.successfullCancellation"),
      type: "success",
    });
    this.$emit("closeModal");
    this.isOpen = false;
    overlayModule.hideOverlay();
  }
}
</script>
