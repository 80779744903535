import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";
import { EquipmentFile } from "@/models/entities/fileFormats/fileFormats.interface";
import { PdmRevisionRequest } from "@/models/entities/equipmentManagement/PdmRevisionRequest.interface";
import { ActivePartRevisionItem } from "@/models/entities/equipmentManagement/ActivePartRevisionItem.interface";

const CONTROLLER = "equipmentManagement";
class EquipmentManagementService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }

  getPdm(equipmentCode: string) {
    return this.getAll<boolean>(`${CONTROLLER}/getpdm/${equipmentCode}`);
  }

  getPDMRevisionActiveParts(request: PdmRevisionRequest) {
    return this.getAll<ActivePartRevisionItem[]>(
      `${CONTROLLER}/activeparts/revision`,
      {
        params: request,
      }
    );
  }

  getPDMRevisionNormalized(pdm: string) {
    return this.getAll<string>(`${CONTROLLER}/normalized/revision/${pdm}`);
  }
}

export const equipmentManagementService = new EquipmentManagementService();
