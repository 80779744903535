<template>
  <div class="mod--fill_height">
    <v-col cols="12" class="pb-12">
      <title-bar
        :title="$tc('normalized.title')"
        :is-parent-element="false"
      ></title-bar>
    </v-col>
    <v-row justify="center">
      <v-col cols="auto" style="width: 450px" class="pb-5">
        <input-center
          inPlaceHolder="INSERISCI CODICE PDM"
          v-model="pdmCode"
        ></input-center>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="revision">
      <v-col cols="auto" style="width: 450px" class="pb-5">
        <input-center
          inPlaceHolder="REVISIONE"
          in-is-readonly
          clearable
          v-model="revision"
        ></input-center>
      </v-col>
    </v-row>
    <v-row class="pt-10" justify="center" v-if="!revision">
      <v-col cols="auto">
        <v-btn
          class="mod--btn flex-grow-1"
          style="width: 100%"
          x-large
          @click="getRevision"
        >
          {{ $tc("general.getRevision") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="pt-10" justify="center" v-if="revision">
      <v-col cols="auto">
        <v-btn
          class="mod--btn flex-grow-1"
          style="width: 100%"
          x-large
          @click="getFile"
        >
          {{ $tc("general.getFile") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { equipmentManagementService } from "@/services/api/equipmentManagement.service";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";

const snackbarModule = getModule(SnackbarModule, store);

@Component({})
export default class Normalized extends Vue {
  revision = "";
  pdmCode = "";

  async getRevision() {
    if (!this.pdmCode) {
      snackbarModule.appearSnackbar({
        message: this.$tc("normalized.PdmCodeFieldRequired"),
        type: "warning",
      });
      return;
    }
    const response = await equipmentManagementService.getPDMRevisionNormalized(
      this.pdmCode
    );
    this.revision = response.data;
  }

  getFile() {
    if (!this.pdmCode || !this.revision) {
      snackbarModule.appearSnackbar({
        message: this.$tc("activeParts.ImpossibleGetFile"),
        type: "error",
      });
      return;
    }
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: {
        pdmCode: this.pdmCode,
        revision: this.revision,
        type: "NORMALIZED",
      },
    });
    window.open(routeData.href, "_blank");
  }
}
</script>

<style scoped></style>
