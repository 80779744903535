import format from "date-fns/format";
import it from "date-fns/locale/it";

export const custom = {
  getHeaderDate(locale = it): string {
    return this.toCapitalized(
      format(new Date(), "EEEE dd/MM/yyyy.HH:mm", {
        locale: locale,
      })
    );
  },
  toCapitalized(s: string): string {
    const sa = s.split("");
    sa[0] = sa[0].toUpperCase();
    return sa.join("");
  },
  defineChipColor(authId: string): string {
    switch (authId) {
      case "CFG":
        return "";
      case "MSA":
        return "mod--color_btn_msa";
      case "SAP":
        return "mod--color_btn_sap";
      case "SAT":
        return "mod--color_btn_sat";
      case "SDS":
        return "mod--color_btn_products";
      case "SMP":
        return "mod--color_btn_mp";
      case "USR":
        return "mod--color_btn_management";
      case "STT":
        return "mod--color_btn_stt";
      case "STS":
        return "mod--color_btn_sts";
      case "STAIN":
        return "mod--color_btn_stain";
      case "HSE":
        return "mod--color_btn_hse";
      case "BO":
        return "mod--color_btn_bo";
      case "MANMNT":
        return "mod--color_btn_manmnt";
      case "SMALLMNT":
        return "mod--color_btn_smallmnt";
      case "SGQ":
        return "mod--color_btn_products";
      case "RUBRIC":
        return "mod--color_btn_rubric";
      case "WEB":
        return "mod--color_btn_web";
      case "SECCHART":
        return "mod--color_btn_rubric";
      case "ACTIVEPARTS":
        return "mod--color_btn_activeParts";
      default:
        return "";
    }
  },
};
