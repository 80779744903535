<template>
  <v-dialog v-model="show" :width="700">
    <v-card class="pa-6">
      <v-data-table
        :items="plans"
        item-key="value"
        :headers="headers"
        single-select
      >
        <template v-slot:item="{ item }">
          <tr
            :class="{
              'row-selected': actualSelection?.idPicMan === item.idPicMan,
            }"
            @click="toggleSelection(item)"
          >
            <td v-for="header in headers" :key="header.value">
              <span>{{ item[header.value] }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-row justify="end">
          <v-btn color="primary" @click="confirmSelection">{{
            $tc("general.confirm")
          }}</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, VModel, Prop } from "vue-property-decorator";
import { SmallMaintenancePlan } from "@/models/entities/maintenance/smallMaintenancePlan.interface";

@Component({})
export default class ChoicePlanDialog extends Vue {
  @VModel()
  show: boolean;

  @Prop({ type: Array, required: true })
  plans: SmallMaintenancePlan[];

  headers = [
    {
      text: this.$tc("smallMaintenance.choiceplandialog.Plan"),
      align: "start",
      sortable: false,
      value: "idPicMan",
    },
    {
      text: this.$tc("smallMaintenance.choiceplandialog.AssetCode"),
      align: "start",
      sortable: false,
      value: "codiceBene",
    },
  ];
  actualSelection: SmallMaintenancePlan = {
    idPicMan: null,
    descrizione: null,
    codiceBene: null,
  };

  toggleSelection(itemClicked: SmallMaintenancePlan): void {
    this.actualSelection =
      this.actualSelection.idPicMan === itemClicked.idPicMan
        ? null
        : itemClicked;
  }

  confirmSelection(): void {
    this.$emit("onChoicePlan", this.actualSelection);
  }
}
</script>

<style scoped>
.row-selected {
  background-color: #007bc1 !important;
}
</style>
