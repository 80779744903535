import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        col_base: {
          base: "#F5F5F5",
        },
        col_SAP: { base: "#75D0F2" },
        col_MSA: { base: "#FA8B8B" },
        col_equipment: { base: "#91A7F5" },
        col_MP: { base: "#FAD76D" },
        col_products: { base: "#447F99" },
        col_smallMnt: { base: "#ff504e" },
        // col_smallMnt: { base: "#f10301" },
        col_manMnt: { base: "#FF604E" },
        // col_manMnt: { base: "#d01301" },

        primary: {
          base: "#007bc1",
          /*
          lighten1: "#4B545E",
          lighten2: "#646C75",
          lighten3: "#ACB0B4",
          lighten4: "#D8DBDE",
          lighten5: "#F2F3F4",
          darken1: "#1E252D",
          darken2: "#131920",
          darken3: "#080B0F",
          */
        },
        secondary: {
          base: "#00B4ED",
          lighten1: "#26D2F8",
          lighten2: "#63E1FF",
          lighten3: "#A9EFFF",
          lighten4: "#CDF6FF",
          lighten5: "#E6FAFF",
          darken1: "#0091E3",
          darken2: "#005EA2",
          darken3: "#02417B",
        },
        warning: {
          base: "#D58000",
          lighten1: "#FEDC99",
          lighten2: "#FFEDCA",
          darken1: "#8C5400",
          darken2: "#3E1803",
        },
        error: {
          base: "#D1183A",
          lighten1: "#FFB5BE",
          lighten2: "#FFD9D9",
          darken1: "#761016",
          darken2: "#480509",
          darken3: "#D32F2F", //Cancel Btn
        },
        success: {
          base: "#007B37",
          lighten1: "#8FEFBA",
          lighten2: "#CDFAE1",
          darken1: "#0A572C",
          darken2: "#033419",
          darken3: "#388E3C", //Save Btn
        },
        background: {
          base: "#F2F3F4",
          lighten1: "#F3F3F3",
        },
        light: {
          base: "#FFFFFF",
        },
        dark: {
          base: "#000000",
        },
      },
    },
  },
});
