import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import router from "@/router";
import { getModule } from "vuex-module-decorators";
// import AuthModule from "@/framework/store/modules/auth.module";
import store from "@/store";
import SnackbarModule from "@/store/modules/snackbar.module";
// import OverlayModule from "@/framework/store/modules/overlay.module";
import { ApiEndpoint, endpointDict } from "./api.enum";
import qs from "qs";
// const authModule = getModule(AuthModule, store);
const snackbarModule = getModule(SnackbarModule, store);
// const overlayModule = getModule(OverlayModule, store);

axios.interceptors.request.use(
  async (config) => {
    config.withCredentials = true;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // overlayModule.hideOverlay();
    return response;
  },
  function (error) {
    // overlayModule.resetOverlay();
    if (!axios.isCancel(error)) {
      if (error.response && error.response.status) {
        switch (error.response.status) {
          case 404:
            snackbarModule.appearSnackbar({
              message:
                "Errore " + error.response.status + ": Metodo non trovato",
              type: "error",
            });
            break;
          case 403:
            snackbarModule.appearSnackbar({
              message: "Errore " + error.response.status + ": Permesso negato",
              type: "error",
            });
            break;
          case 400:
            if (router.currentRoute.path !== "/login") {
              /* Bad Request */
              snackbarModule.appearSnackbar({
                message:
                  "Errore " +
                  error.response.status +
                  ": " +
                  error.response.data,
                type: "error",
              });
            }
            break;
          case 401:
            if (
              router.currentRoute.path !== "/login" &&
              router.currentRoute.name !== "changePassword"
            ) {
              router.push({ name: "Login" });
            }
            break;
          default:
            snackbarModule.appearSnackbar({
              message:
                "Errore " + error.response.status + ": " + error.response.data,
              type: "error",
            });
        }
      } else {
        if (error.message) {
          snackbarModule.appearSnackbar({
            message: "Si è verificato un errore: " + error.message,
            type: "error",
          });
        } else {
          snackbarModule.appearSnackbar({
            message: "Si è verificato un errore.",
            type: "error",
          });
        }
      }
    }

    return Promise.reject(error);
  }
);

export abstract class ApiService {
  protected baseUrl: string;

  constructor(endpoint?: ApiEndpoint) {
    this.baseUrl = endpointDict[endpoint ? endpoint : ApiEndpoint.STANDARD];
  }

  protected urlencodedForm<ResponseType>(
    uri: string,
    params: string
  ): Promise<AxiosResponse<ResponseType>> {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    return axios.post<ResponseType>(this.baseUrl + uri, params, config);
  }

  protected getAll<ResponseType>(
    uri: string,
    params?: AxiosRequestConfig,
    dateFormat = "yyyy-MM-dd"
  ): Promise<AxiosResponse<ResponseType>> {
    if (params) {
      params.paramsSerializer = (params) => {
        return qs.stringify(params, {
          arrayFormat: "repeat",
          skipNulls: true,
          serializeDate: (date: Date) => date.toFormat(dateFormat),
        });
      };
    }

    return axios.get<ResponseType>(this.baseUrl + uri, params);
  }

  protected getByID<IdentifierType, ResponseType>(
    uri: string,
    id: IdentifierType,
    params: AxiosRequestConfig,
    dateFormat = "yyyy-MM-dd"
  ): Promise<AxiosResponse<ResponseType>> {
    if (params) {
      params.paramsSerializer = (params) => {
        return qs.stringify(params, {
          arrayFormat: "repeat",
          skipNulls: true,
          serializeDate: (date: Date) => date.toFormat(dateFormat),
        });
      };
    }

    return axios.get<ResponseType>(`${this.baseUrl}${uri}/${id}`, params);
  }

  protected post<BodyType, ResponseType>(
    uri: string,
    body: BodyType,
    params?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.post<ResponseType>(`${this.baseUrl}${uri}`, body, params);
  }

  protected delete<IdentifierType, ResponseType>(
    uri: string,
    id: IdentifierType
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.delete(`${this.baseUrl}${uri}/${id}`);
  }

  protected upload<ResponseType>(
    uri: string,
    formdata: FormData
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.post(`${this.baseUrl}${uri}`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  protected patch<BodyType, ResponseType>(
    uri: string,
    params: BodyType
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.patch(`${this.baseUrl}${uri}`, params);
  }

  protected update<BodyType, ResponseType>(
    uri: string,
    params: BodyType
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.put(`${this.baseUrl}${uri}`, params);
  }

  protected updateById<IdentifierType, BodyType, ResponseType>(
    uri: string,
    id: IdentifierType,
    params: BodyType
  ): Promise<AxiosResponse<ResponseType>> {
    return axios.put(`${this.baseUrl}${uri}/${id}`, params);
  }
}
