import { AuthItemNamed } from "@/models/entities/auth/authItem.interface";
import {
  AuthUserRequestItem,
  AuthGroupRequestItem,
} from "@/models/entities/auth/authRequest.interface";
import { SaveGroupAuthRequest } from "@/models/entities/auth/saveGroupAuthRequest.interface";
import { UsersByGroup } from "@/models/entities/auth/usersByGroup.interface";
import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";

const CONTROLLER_USER = "authorizations/users";
const CONTROLLER_GROUP = "authorizations/groups";
class AuthRequestService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }

  addToUser(credentials: AuthUserRequestItem) {
    return this.post<AuthUserRequestItem, void>(
      `${CONTROLLER_USER}/add`,
      credentials
    );
  }
  removeFromUser(credentials: AuthUserRequestItem) {
    return this.post<AuthUserRequestItem, void>(
      `${CONTROLLER_USER}/remove`,
      credentials
    );
  }
  addToGroup(credentials: AuthGroupRequestItem) {
    return this.post<AuthGroupRequestItem, void>(
      `${CONTROLLER_GROUP}/add`,
      credentials
    );
  }
  removeFromGroup(credentials: AuthGroupRequestItem) {
    return this.post<AuthGroupRequestItem, void>(
      `${CONTROLLER_GROUP}/remove`,
      credentials
    );
  }

  getAuthsGroup(userGroupId: number | undefined) {
    return this.getAll<AuthItemNamed[]>(
      `${CONTROLLER_GROUP}/get?userGroupId=${userGroupId}`
    );
  }

  getGroupName(userGroupId: number | undefined) {
    return this.getAll<string>(
      `${CONTROLLER_GROUP}/getName?userGroupId=${userGroupId}`
    );
  }

  changeGroupAuth(userGroupId: SaveGroupAuthRequest) {
    return this.post<SaveGroupAuthRequest, void>(
      `${CONTROLLER_GROUP}/addAuthorization`,
      userGroupId
    );
  }

  newGroupsAdd(userGroup: SaveGroupAuthRequest) {
    return this.post<SaveGroupAuthRequest, void>(
      `${CONTROLLER_GROUP}/insertAuthorization`,
      userGroup
    );
  }

  getAllUsersByGroup(userGroupId: number) {
    return this.getAll<UsersByGroup[]>(
      `${CONTROLLER_GROUP}/getAllUsersByGroup?userGroupId=${userGroupId}`
    );
  }

  removeUserFromGroup(userId: number) {
    return this.post<number, void>(
      `${CONTROLLER_GROUP}/removeUserFromGroup/${userId}`,
      userId
    );
  }
}

export const authRequestService = new AuthRequestService();
