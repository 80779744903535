<template :key="">
  <v-dialog v-model="isOpen" :width="700">
    <v-card class="pa-6">
      <v-row>
        <v-col cols="12">
          <strong>{{ groupName }}</strong>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-data-table
              :loading="tableUser.isLoading"
              :headers="tableUser.headers"
              :items="userList"
              :items-per-page="5"
              :search="tableUser.search"
            >
              <!-- Actions -->
              <!-- <template v-slot:[`item.actionsCRUD`]="{ item }">
                <v-btn
                  icon
                  v-if="item.userGroupId"
                  @click="deleteUser(item.userId)"
                  color="primary"
                >
                  <v-icon left color="primary" class="mx-auto" :size="24">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template> -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import OverlayModule from "@/store/modules/overlay.module";
import { Component, Vue, Prop, VModel } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import { authRequestService } from "@/services/api/authRequest.service";
import { UsersByGroup } from "@/models/entities/auth/usersByGroup.interface";

const overlayModule = getModule(OverlayModule, store);

@Component
export default class DialogGroupUserList extends Vue {
  @VModel({ default: false })
  isOpen: boolean | undefined;

  @Prop({ default: 0, required: true, type: Number })
  private userGroupId: number;

  @Prop({ default: "", required: true, type: String })
  private groupName: string;

  private userList: UsersByGroup[] = [];

  // Defines wether dialog is persistent.
  private tableUser = {
    isLoading: true,
    search: "",
    headers: [
      {
        text: this.$tc("userManagement.tblHeadUserName"),
        align: "start",
        sortable: false,
        value: "userName",
      },
      {
        text: this.$tc("userManagement.tblHeadUserDescription"),
        align: "start",
        sortable: false,
        value: "userDescription",
      },
      // {
      //   text: this.$tc("userManagement.tblHeadActions"),
      //   align: "start",
      //   sortable: false,
      //   value: "actionsCRUD",
      // },
    ],
  };

  private created(): void {
    // load both data from users & from groups
    authRequestService
      .getAllUsersByGroup(this.userGroupId)
      .then((res) => {
        this.userList = res.data;
      })
      .finally(() => {
        this.tableUser.isLoading = false;
      });
  }

  private async deleteUser(userId: number) {
    overlayModule.showOverlay();
    await authRequestService.removeUserFromGroup(userId);
    await authRequestService
      .getAllUsersByGroup(this.userGroupId)
      .then((res) => {
        this.userList = res.data;
      })
      .finally(() => {
        this.tableUser.isLoading = false;
      });
    overlayModule.hideOverlay();
  }
}
</script>
