<template>
  <v-dialog v-model="show" :width="700">
    <v-card class="pa-2">
      <v-card-title>{{
        $tc("smallMaintenance.smallMaintenancePlan.ConfirmStoppedMachineTitle")
      }}</v-card-title>
      <v-col class="pl-6">
        <span>{{
          $tc("smallMaintenance.smallMaintenancePlan.ConfirmStoppedMachineText")
        }}</span>
      </v-col>
      <v-card-actions>
        <v-row justify="end" no-gutters style="gap: 12px">
          <v-btn @click="$emit('closeModal')">{{ $tc("general.close") }}</v-btn>
          <v-btn color="primary" @click="$emit('confirmStoppedMachine')">{{
            $tc("general.confirm")
          }}</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";

@Component({})
export default class ConfirmStoppedMachineDialog extends Vue {
  @VModel()
  show: boolean;
}
</script>

<style scoped></style>
