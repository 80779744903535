import { FileResponse } from "@/models/entities/fileFormats/fileResponse.interface";
import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";
import { MSAFile } from "@/models/entities/fileFormats/fileFormats.interface";

const CONTROLLER = "msa";
class MSAService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }

  getSAPCode(MSACode: string) {
    return this.getAll<MSAFile[]>(
      `${CONTROLLER}/getSAPCode?MSACode=${MSACode}`
    );
  }

  getSAPCodesStartingFromSAP(SAPCode: string) {
    return this.getAll<MSAFile[]>(
      `${CONTROLLER}/getsapcodesstartingfromsap?SAPCode=${SAPCode}`
    );
  }

  getPdf(fileCode: string) {
    return this.getAll<FileResponse>(
      `${CONTROLLER}/getpdf?fileCode=${fileCode}`
    );
  }

  getPdfNames(SAPCode: string) {
    return this.getAll<MSAFile[]>(
      `${CONTROLLER}/getpdfnames?SAPCode=${SAPCode}`
    );
  }
}

export const msaService = new MSAService();
