<template>
  <div id="page">
    <v-row>
      <v-col cols="12" class="pb-12">
        <title-bar :title="$tc('sts.title')"></title-bar>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters class="pt-12">
      <v-col cols="auto" class="pb-10">
        <!--        <input-center-->
        <!--          v-model="selectedDropdownItem"-->
        <!--          :inPlaceHolder="$tc('sts.dropdownTitle')"-->
        <!--          @click:textField="showSelect = true"-->
        <!--          @onBlur="showSelect = false"-->
        <!--        ></input-center>-->
        <!--        <v-select-->
        <!--          v-model="selectedDropdownItem"-->
        <!--          v-show="showSelect"-->
        <!--          ref="select"-->
        <!--          :items="dropdownItems"-->
        <!--          :menu-props="{ value: showSelect }"-->
        <!--          @change="showSelect = false"-->
        <!--        ></v-select>-->
        <select-row
          style="width: 450px"
          v-model="selectedDropdownItem"
          :inTitle="$tc('products.inputProducts')"
          :inItems="dropdownItems"
          hideTitle
          :in-place-holder="'SISTEMA DI CONTROLLO SALDATURA'"
          :padding-zero="true"
        ></select-row>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-col cols="auto" style="width: 450px" class="pb-5">
        <input-center
          v-model="MSACode"
          inPlaceHolder="INSERISCI CODICE MSA"
        ></input-center>
      </v-col>
    </v-row>
    <v-row v-if="btnVisible" class="pt-10" justify="center">
      <v-col style="width: 270px" cols="auto">
        <v-btn
          class="mod--btn flex-grow-1"
          style="width: 100%"
          x-large
          @click="handleSearch"
        >
          {{ $tc("general.search") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mod--table_row" v-if="tableDrawItems.length > 0">
      <v-col class="px-0">
        <v-data-table
          id="sts-table"
          :headers="tableDrawHeaders"
          :items="tableDrawItemsObject"
          disable-pagination
          class="elevation-1 header-bold brack-world-custom"
          hide-default-footer
          :height="
            btnVisible
              ? windowHeight < 900
                ? 'calc(100vh - 500px)'
                : 'calc(100vh - 720px)'
              : 'calc(100vh - 400px)'
          "
          @click:row="clickRow"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn text v-if="item" class="mod--btn_text">
              <v-icon left color="primary" class="ml-1" :size="38">
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { stsService } from "@/services/api/sts.service";
import { RequestSTSFileInterface } from "@/models/entities/sts/RequestSTSFile.interface";
import { VSelect } from "vuetify/lib/components";

const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class Sts extends Vue {
  windowScroll = 0;
  btnVisible = true;
  scrollTop = 0;
  btnPosition = "110px";
  showSelect = false;
  selectedDropdownItem = "";

  async created(): Promise<void> {
    window.addEventListener("scroll", this.handleScroll);
    await this.getDirectoryNames();
  }

  MSACode = "";
  tableDrawItems: string[] = [];
  tableDrawHeaders = [
    {
      text: this.$tc("stt.document"),
      align: "start",
      sortable: false,
      value: "value",
    },
    {
      text: "",
      align: "start",
      sortable: false,
      value: "actions",
    },
  ];

  dropdownItems: string[] = [];

  get tableDrawItemsObject(): Array<{ value: string }> {
    if (this.tableDrawItems && this.tableDrawItems.length > 0) {
      return this.tableDrawItems.map((x) => ({
        value: x,
      }));
    }
    return [];
  }

  get windowHeight(): number {
    return window.innerHeight;
  }

  async getDirectoryNames() {
    const response = await stsService.getDirectoryNames();
    this.dropdownItems = response.data;
  }

  handleScroll() {
    if (this.tableDrawItems.length > 0) {
      const ris = this.windowScroll - window.top.scrollY;
      if (ris == 0) {
        this.btnVisible = true;
        this.windowScroll = window.top.scrollY;
        this.btnPosition = "110px";
      }
    } else {
      this.btnVisible = true;
      this.btnPosition = "110px";
    }
  }

  clickRow(row: any): void {
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: {
        folderName: this.selectedDropdownItem,
        code: row.value,
        type: "STS",
      },
    });
    window.open(routeData.href, "_blank");
  }

  async handleSearch() {
    if (!this.MSACode || !this.selectedDropdownItem) {
      snackbarModule.appearSnackbar({
        message: this.$tc("sts.notAllowedSearch"),
        type: "error",
      });
      return;
    }
    overlayModule.showOverlay();
    const request: RequestSTSFileInterface = {
      folderName: this.selectedDropdownItem,
      msaCode: this.MSACode,
    };
    try {
      const response = await stsService.getPdfNames(request);
      this.tableDrawItems = response.data;
    } catch (e) {
      this.tableDrawItems = [];
    }
    overlayModule.hideOverlay();
  }

  // getFolderName() {
  //   return this.dropdownItems.find(
  //     (el) => el.value === this.selectedDropdownItem
  //   ).text;
  // }

  onScroll(e: any) {
    const children = document.getElementById("stt-table").children;
    this.scrollTop = children[0].scrollTop;
    if (this.scrollTop > 100) {
      setTimeout(() => (this.btnVisible = false), 0);
      this.btnPosition = "134px";
      setTimeout(() => this.$vuetify.goTo(240), 0);
    }
  }
}
</script>
<style>
.title-h1-refactor {
  font-size: 52px !important;
}
.header-bold > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: bold !important;
}
.brack-world-custom > .v-data-table__wrapper > table > tbody > tr > td {
  word-break: break-word;
}

.v-select__selection {
  font-size: 20px;
}
</style>
