import { FileResponse } from "@/models/entities/fileFormats/fileResponse.interface";
import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";
import { MSAFile } from "@/models/entities/fileFormats/fileFormats.interface";

const CONTROLLER = "stt";
class STTService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }

  getSAPCode(MSAcode: string) {
    return this.getAll<MSAFile[]>(
      `${CONTROLLER}/getSAPcode?MSAcode=${MSAcode}`
    );
  }

  getPdf(SAPcode: string) {
    return this.getAll<FileResponse>(`${CONTROLLER}/getpdf?SAPcode=${SAPcode}`);
  }

  getPdfNames(SAPcode: string) {
    return this.getAll<MSAFile[]>(
      `${CONTROLLER}/getpdfnames?SAPcode=${SAPcode}`
    );
  }
}

export const sttService = new STTService();
