<template>
  <div id="page">
    <v-row>
      <v-col cols="12" class="pb-12">
        <title-bar
          :title="$tc('manuals.title')"
          :is-parent-element="false"
        ></title-bar>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters class="pt-12">
      <v-col cols="auto" style="width: 450px" class="pb-10">
        <!--        <input-center-->
        <!--          v-model="inputCenterVModel"-->
        <!--          :inPlaceHolder="$tc('manuals.dropdownTitle')"-->
        <!--          @click:textField="showSelect = true"-->
        <!--          @onBlur="showSelect = false"-->
        <!--        ></input-center>-->
        <!--        <v-select-->
        <!--          v-model="selectedDepartment"-->
        <!--          v-show="showSelect"-->
        <!--          color="black"-->
        <!--          ref="select"-->
        <!--          :items="departments"-->
        <!--          item-text="denominazione"-->
        <!--          item-value="codreparto"-->
        <!--          background-color="white"-->
        <!--          return-object-->
        <!--          hide-details-->
        <!--          :menu-props="{ value: showSelect }"-->
        <!--          @change="showSelect = false"-->
        <!--        ></v-select>-->
        <select-row
          v-model="selectedDepartment"
          :inTitle="$tc('manuals.dropdownTitle')"
          :inItems="departments"
          hideTitle
          :in-place-holder="$tc('manuals.dropdownTitle')"
          :padding-zero="true"
          :clearable="true"
          :return-object="true"
          :item-text="'denominazione'"
          :item-value="'codreparto'"
        ></select-row>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-col cols="auto" style="width: 450px">
        <input-center
          :inPlaceHolder="$tc('manuals.locationTextFiledTitle')"
          v-model="location"
          type="number"
          @submitSearch="submitSearch"
        ></input-center>
      </v-col>
    </v-row>
    <v-row v-if="btnVisible" class="pt-10" justify="center">
      <v-col style="width: 270px" cols="auto">
        <v-btn
          class="mod--btn flex-grow-1"
          style="width: 100%"
          x-large
          @click="submitSearch"
        >
          {{ $tc("general.search") }}
        </v-btn>
      </v-col>
    </v-row>
    <!--    &lt;!&ndash;    <v-row class="mod&#45;&#45;table_row" v-if="assetList.length > 0">&ndash;&gt;-->

    <v-row
      class="mod--table_row"
      v-if="assetList.length && selectedDatatable === 1"
    >
      <v-col class="px-3">
        <v-data-table
          id="assets-table"
          :headers="assetsTableHeaders"
          :items="assetsFiltered"
          disable-pagination
          fixed-header
          class="elevation-1 header-bold brack-world-custom"
          hide-default-footer
          @click:row="clickAsset"
          :height="
            btnVisible
              ? windowHeight < 900
                ? 'calc(100vh - 500px)'
                : 'calc(100vh - 720px)'
              : 'calc(100vh - 400px)'
          "
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn text v-if="item" class="mod--btn_text">
              <v-icon left color="primary" class="ml-1" :size="38">
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row
      class="mod--table_row"
      v-if="foldersByAsset.length > 1 && selectedDatatable === 2"
    >
      <v-col class="px-3">
        <div class="back-button" @click="backToAssets">
          <v-icon>mdi-arrow-left</v-icon>
          <span>{{ $tc("manuals.backToAssets") }}</span>
        </div>
        <v-data-table
          id="folder-table"
          :headers="folderTableHeader"
          :items="folderNamesFiltered"
          disable-pagination
          fixed-header
          hide-default-footer
          class="elevation-1 header-bold brack-world-custom"
          @click:row="clickFolder"
          :height="
            btnVisible
              ? windowHeight < 900
                ? 'calc(100vh - 500px)'
                : 'calc(100vh - 720px)'
              : 'calc(100vh - 400px)'
          "
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn text v-if="item" class="mod--btn_text">
              <v-icon left color="primary" class="ml-1" :size="38">
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row
      class="mod--table_row"
      v-if="documentsNames.length && selectedDatatable === 3"
    >
      <v-col class="px-3">
        <div class="back-button" @click="backToAssets">
          <v-icon>mdi-arrow-left</v-icon>
          <span>{{ $tc("manuals.backToAssets") }}</span>
        </div>
        <v-data-table
          id="documents-table"
          :headers="documentsTableHeaders"
          :items="documentsNamesFiltered"
          disable-pagination
          fixed-header
          hide-default-footer
          class="elevation-1 header-bold brack-world-custom"
          @click:row="clickDocument"
          :height="
            btnVisible
              ? windowHeight < 900
                ? 'calc(100vh - 500px)'
                : 'calc(100vh - 720px)'
              : 'calc(100vh - 400px)'
          "
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn text v-if="item" class="mod--btn_text">
              <v-icon left color="primary" class="ml-1" :size="38">
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { maintenanceService } from "@/services/api/maintenance.service";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
import { AssetBaseInterface } from "@/models/entities/maintenance/assetBase.interface";
import { click } from "@syncfusion/ej2-vue-spreadsheet";
import { stsService } from "@/services/api/sts.service";
import { DepartmentItemInterface } from "@/models/entities/maintenance/departmentItem.interface";

const snackbarModule = getModule(SnackbarModule, store);

interface TableHeaderInterface {
  text: string;
  align: string;
  sortable: boolean;
  value: string;
}

@Component({
  methods: {
    click() {
      return click;
    },
  },
})
export default class Manuals extends Vue {
  btnPosition = "110px";
  showSelect = false;
  selectedDepartment: DepartmentItemInterface = null;
  assetsTableHeaders: TableHeaderInterface[] = [
    {
      text: this.$tc("manuals.asset"),
      align: "start",
      sortable: false,
      value: "value",
    },
    {
      text: "",
      align: "start",
      sortable: false,
      value: "actions",
    },
  ];
  documentsTableHeaders: TableHeaderInterface[] = [
    {
      text: this.$tc("manuals.document"),
      align: "start",
      sortable: false,
      value: "value",
    },
    {
      text: "",
      align: "start",
      sortable: false,
      value: "actions",
    },
  ];
  folderTableHeader: TableHeaderInterface[] = [
    {
      text: this.$tc("manuals.FolderName"),
      align: "start",
      sortable: false,
      value: "value",
    },
    {
      text: "",
      align: "start",
      sortable: false,
      value: "actions",
    },
  ];
  location = "";
  btnVisible = true;
  assetList: string[] = [];
  selectedDatatable = 0;
  documentsNames: string[] = [];
  assetNameSelected = "";
  folderNameSelected = "";
  departments: DepartmentItemInterface[] = [];
  foldersByAsset: string[] = [];

  get inputCenterVModel(): string | null {
    return this.selectedDepartment
      ? this.selectedDepartment.denominazione
      : null;
  }
  get windowHeight(): number {
    return window.innerHeight;
  }

  get documentsNamesFiltered() {
    if (this.documentsNames) {
      return this.documentsNames.map((x) => ({
        value: x,
      }));
    }
    return [];
  }

  get assetsFiltered() {
    if (this.assetList) {
      return this.assetList.map((x) => ({
        value: x,
      }));
    }
    return [];
  }

  get folderNamesFiltered() {
    if (this.foldersByAsset) {
      return this.foldersByAsset.map((x) => ({
        value: x,
      }));
    }
    return [];
  }

  created() {
    this.loadDepartments();
  }

  async loadDepartments() {
    const response = await maintenanceService.loadDepartments();
    this.departments = response.data;
  }

  async clickAsset(row: TableHeaderInterface): Promise<void> {
    const foldersResponse = await maintenanceService.getFoldersByAsset(
      row.value
    );
    this.assetNameSelected = row.value;
    if (foldersResponse.data.length > 1) {
      this.foldersByAsset = foldersResponse.data;
      this.selectedDatatable = 2;
      return;
    }
    const response = await maintenanceService.getDocumentsNames(row.value);
    this.documentsNames = response.data;
    if (this.documentsNames) {
      this.selectedDatatable = 3;
    }
  }

  clickDocument(row: TableHeaderInterface) {
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: {
        assetName: this.assetNameSelected,
        fileName: row.value,
        folderName: this.folderNameSelected,
        type: "MANMNT",
      },
    });
    window.open(routeData.href, "_blank");
  }

  async submitSearch(): Promise<void> {
    if (!this.selectedDepartment) {
      snackbarModule.appearSnackbar({
        type: "warning",
        message: this.$tc("manuals.requireDepartment"),
      });
      this.assetList = [];
      return;
    }
    this.selectedDatatable = 1;
    const response = await maintenanceService.getAssetsByDepartment(
      this.selectedDepartment.codreparto,
      this.location
    );
    if (response.status === 204) {
      snackbarModule.appearSnackbar({
        type: "error",
        message: this.$tc("manuals.NotFoundAnyAssetByDepartment"),
      });
    }
    this.assetList = response.data;
  }

  async clickFolder(folder: TableHeaderInterface) {
    const response = await maintenanceService.getDocumentsNames(
      this.assetNameSelected,
      folder.value
    );
    this.folderNameSelected = folder.value;
    this.documentsNames = response.data;
    if (this.documentsNames) {
      this.selectedDatatable = 3;
    }
  }

  backToAssets() {
    this.selectedDatatable = 1;
    this.documentsNames = [];
    this.foldersByAsset = [];
    this.folderNameSelected = "";
  }
}
</script>

<style scoped>
.manuals-container {
  height: calc(100svh - 105px);
  width: 100%;
  padding: 12px;
}

.back-button {
  cursor: pointer;
  width: fit-content;
  padding: 6px;
  border-radius: 6px;
}

.back-button:hover {
  background-color: lightgray;
}

.header-bold > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: bold !important;
}
</style>
