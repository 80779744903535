import { FileResponse } from "@/models/entities/fileFormats/fileResponse.interface";
import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";
import { RequestSTSFileInterface } from "@/models/entities/sts/RequestSTSFile.interface";

const CONTROLLER = "sts";
class STSService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }

  getDirectoryNames() {
    return this.getAll<string[]>(`${CONTROLLER}/getdirectorynames`);
  }

  getPdfNames(requestSTSFile: RequestSTSFileInterface) {
    return this.getAll<string[]>(`${CONTROLLER}/getpdfNames`, {
      params: requestSTSFile,
    });
  }

  getPdf(requestSTSFile: RequestSTSFileInterface) {
    return this.getAll<FileResponse>(`${CONTROLLER}/getpdf`, {
      params: requestSTSFile,
    });
  }
}

export const stsService = new STSService();
