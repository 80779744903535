import {
  InsertItem,
  OrderButtonItem,
} from "@/models/entities/buttonSort/buttonSort.interface";

import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";

const CONTROLLER = "userbuttonsorts";
class ButtonSortService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }

  getOrderedAuths() {
    return this.getAll<OrderButtonItem[]>(`${CONTROLLER}/getOrderedAuths`);
  }

  getAuthsByParent(parentAuthId: string) {
    return this.getAll<string[]>(
      `${CONTROLLER}/getAuthsByParent?parentAuthId=${parentAuthId}`
    );
  }

  insertSorts(item: InsertItem[]) {
    return this.post<InsertItem[], void>(`${CONTROLLER}/insertSorts`, item);
  }
}

export const buttonSortService = new ButtonSortService();
