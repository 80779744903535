<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" class="pb-12">
        <title-bar :title="$tc('equipment.title')"></title-bar>
      </v-col>
      <v-col :class="!btnVisible && 'pt-12'" style="width: 450px" cols="auto">
        <input-center
          :inTitle="$tc('equipment.labelEquipment')"
          :inPlaceHolder="$tc('equipment.addCode')"
          v-model="equipmentCode"
          id="input_centre"
          @submitSearch="submitSearch"
        ></input-center>
      </v-col>
    </v-row>
    <v-row class="pt-10" v-if="btnVisible" justify="center">
      <v-col style="width: 270px" cols="auto">
        <v-btn
          class="mod--btn flex-grow-1"
          style="width: 100%"
          x-large
          @click="submitSearch"
        >
          {{ $tc("general.search") }}
        </v-btn>
      </v-col>
    </v-row>
    <input-row
      v-if="tableDraw.items.length > 0 && equipmentLocation"
      :inTitle="$tc('equipment.labelLocation')"
      v-model="equipmentLocation"
      inIsReadonly
    ></input-row>
    <v-row v-if="tableDraw.items.length > 0">
      <v-col class="px-0">
        <v-data-table
          id="equipment-table"
          :headers="tableDraw.headers"
          :items="tableDraw.items"
          class="elevation-1 background-header brack-world-custom"
          disable-pagination
          hide-default-footer
          fixed-header
          :height="btnVisible ? 'calc(100vh - 670px)' : 'calc(100vh - 300px)'"
          @click:row="clickRow"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              :key="item.name"
              text
              v-if="item.name"
              target="_blank"
              class="mod--btn_text"
            >
              <v-icon left color="primary" class="ml-1" :size="38">
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import { equipmentService } from "@/services/api/equipment.service";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { EquipmentFile } from "@/models/entities/fileFormats/fileFormats.interface";

const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class EquipmentPage extends Vue {
  private equipmentCode = "";
  private equipmentLocation = "";
  private locationCode = "";
  private windowScroll = 0;
  private btnVisible = true;
  private tableDrawItems: EquipmentFile[] = [];
  private scrollTop = 0;
  private tableDraw = {
    headers: [
      {
        text: this.$tc("equipment.tableHeadDataSheet"),
        align: "start",
        sortable: false,
        value: "name",
        style: "font-weight: bold",
      },
      {
        text: "Ubicazione",
        align: "start",
        sortable: false,
        value: "location",
      },
      {
        text: this.$tc("mp.position"),
        align: "start",
        sortable: false,
        value: "position",
      },
      {
        text: "",
        align: "start",
        sortable: false,
        value: "actions",
      },
    ],
    items: this.tableDrawItems,
  };

  private created() {
    window.addEventListener("scroll", this.handleScroll);
  }

  private submitSearch(): void {
    if (this.equipmentCode.length > 0) {
      overlayModule.showOverlay();
      equipmentService
        .getFile(this.equipmentCode)
        .then((res) => {
          this.tableDraw.items = res.data;
          overlayModule.hideOverlay();
          this.submitSearchCode();
          setTimeout(() => {
            const children =
              document.getElementById("equipment-table").children;
            if (children.length) {
              children[0].addEventListener("scroll", this.onScroll);
            }
          }, 0);
        })
        .catch((err) => {
          this.tableDraw.items = [];
          overlayModule.hideOverlay();
        });
    } else {
      snackbarModule.appearSnackbar({
        message: this.$tc("equipment.snackNoCode"),
        type: "warning",
      });
      this.tableDraw.items = [];
    }
  }

  private submitSearchCode(): void {
    overlayModule.showOverlay();
    equipmentService
      .getEquipmentLocation(this.equipmentCode)
      .then((res) => {
        this.equipmentLocation = res.data;
        overlayModule.hideOverlay();
      })
      .catch(() => {
        overlayModule.hideOverlay();
      });
  }

  private handleScroll(event) {
    var ris = this.windowScroll - window.top.scrollY;
    if (this.tableDraw.items.length > 0) {
      if (ris == 0) {
        this.btnVisible = true;
        this.windowScroll = window.top.scrollY;
      }
    } else {
      this.btnVisible = true;
    }
  }

  private clickRow(row: any) {
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: { code: row.name, type: "Equipment" },
    });
    window.open(routeData.href, "_blank");
  }

  private onScroll(e: any) {
    const children = document.getElementById("equipment-table").children;
    this.scrollTop = children[0].scrollTop;
    if (this.scrollTop > 130) {
      this.btnVisible = false;
      // console.log("scroll");
      setTimeout(() => this.$vuetify.goTo(330), 0);
      // setTimeout(
      //   () => this.$vuetify.goTo("#input_centre", { container: "#vappc" }),
      //   0
      // );
    }
  }
}
</script>
<style lang="scss">
.background-header .v-data-table__wrapper > table > thead > tr > th {
  background: #d4dbf9 !important;
  font-weight: bold !important;
}
.brack-world-custom > .v-data-table__wrapper > table > tbody > tr > td {
  word-break: break-word;
}
</style>
