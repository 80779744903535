<template>
  <div>
    <v-row>
      <v-col cols="12" class="pb-12">
        <title-bar
          :title="$tc('organizationalChart.title')"
          :is-parent-element="false"
        ></title-bar>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component
export default class OrganizationalChart extends Vue {
  btnPosition = "110px";
}
</script>
<style></style>
