<template>
  <v-text-field
    v-model="valueModel"
    solo
    :label="label"
    outlined
    :readonly="inIsReadonly"
    @input="inChanged"
    :type="_inType"
    :append-icon="togglePassword ? 'mdi-eye-off' : 'mdi-eye'"
    @click:append="clickPWToggle"
    :rules="rules"
    :height="$vuetify.breakpoint.mdAndDown ? 96 : 40"
    hide-details
    width="500px"
    :class="classValue"
    class="v-text-field--filled-big"
    autocomplete="off"
  ></v-text-field>
  <!-- :class="
      $vuetify.breakpoint.mdAndDown
        ? 'mod--input; v-text-field--filled-big' + togglePassword == false &&
          ' v-textfield-pass'
        : 'mod--input; v-text-field--filled-small' + togglePassword == false &&
          ' v-textfield-pass'
    " -->
</template>

<script lang="ts">
import { Component, Vue, Prop, VModel } from "vue-property-decorator";
@Component
export default class PasswordRow extends Vue {
  @VModel({ default: null })
  valueModel: undefined;
  // Defines input title.
  @Prop({ required: false, type: String, default: "text" })
  private inType: string | undefined;
  // Defines if input is readonly.
  @Prop({ required: false, type: Boolean, default: false })
  private inIsReadonly: boolean | undefined;
  // Defines icon name & image.
  @Prop({ required: false, type: String, default: "" })
  private iconName: string | undefined;
  // Defines icon color.
  @Prop({ required: false, type: String, default: "primary" })
  private iconColor: string | undefined;
  // Defines rules on input.
  @Prop({ required: false, type: Array, default: () => [] })
  private rules!: Array<(v: string) => string | boolean>;
  @Prop({ required: false, type: String, default: "PASSWORD" })
  private label: string;

  private _inType: string | undefined;
  private togglePassword = false;

  private get classValue(): string {
    var ris = "";
    if (this.$vuetify.breakpoint.mdAndDown) ris += " v-text-field--filled-big ";
    else ris += " v-text-field--filled-small ";
    if (this.togglePassword == false) {
      ris += " v-textfield-pass ";
    }
    return ris;
  }

  private created(): void {
    this._inType = this.inType;
  }

  private clickPWToggle(): void {
    this.togglePassword = !this.togglePassword;
    this._inType = this.togglePassword ? "text" : "password";
  }

  private inChanged(): void {
    this.$emit("inChanged");
  }
}
</script>
<style lang="scss">
.v-text-field--filled-small > .v-input__control > .v-input__slot {
  min-height: 18px;
  border-radius: 24px;
  box-shadow: #{0px 2px 4px};
}
.v-text-field--filled-big > .v-input__control > .v-input__slot {
  border-radius: 24px;
  box-shadow: #{0px 2px 4px};
  font-size: 24px;
}
.v-textfield-pass > .v-input__control input {
  font-family: "password";
  letter-spacing: 0.2em;
}
@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: local("password"), url("../fonts/password.ttf") format("truetype");
}
</style>
