<template>
  <div id="page">
    <v-row>
      <v-col cols="12">
        <title-bar
          :title="$tc('msa.title')"
          :is-parent-element="true"
        ></title-bar>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto" style="width: 450px" class="pb-5">
        <input-center
          :disabled.sync="disableMSAField"
          inPlaceHolder="INSERISCI CODICE MSA"
          v-model="MSACode"
          @submitSearch="submitSearch"
        ></input-center>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto" style="width: 450px">
        <input-center
          :disabled.sync="disableSAPField"
          inPlaceHolder="INSERISCI CODICE SAP"
          v-model="SAPCode"
          type="number"
          @submitSearch="submitSearch"
        ></input-center>
      </v-col>
    </v-row>
    <v-row v-if="btnVisible" class="pt-10" justify="center">
      <v-col style="width: 270px" cols="auto">
        <v-btn
          class="mod--btn flex-grow-1"
          style="width: 100%"
          x-large
          @click="submitSearch"
        >
          {{ $tc("general.search") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mod--table_row" v-if="tableDrawItems.length > 0">
      <v-col class="px-0">
        <v-data-table
          id="msa-table"
          :headers="tableDrawHeaders"
          :items="tableDrawItems"
          disable-pagination
          class="elevation-1 header-bold brack-world-custom"
          hide-default-footer
          @click:row="clickRow"
          :height="
            btnVisible
              ? windowHeight < 900
                ? 'calc(100vh - 500px)'
                : 'calc(100vh - 720px)'
              : 'calc(100vh - 400px)'
          "
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn text v-if="item" class="mod--btn_text">
              <v-icon left color="primary" class="ml-1" :size="38">
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import { msaService } from "@/services/api/msa.service";
import { fileLogService } from "@/services/api/fileLog.service";
import { authRequestService } from "@/services/api/authRequest.service";
import AuthModule from "@/store/modules/auth.module";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { fileOpen } from "@/helpers/fileOpen";
import { FileResponse } from "@/models/entities/fileFormats/fileResponse.interface";
import { MSAFile } from "@/models/entities/fileFormats/fileFormats.interface";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class MSAPage extends Vue {
  windowScroll = 0;
  btnVisible = true;
  scrollTop = 0;
  btnPosition = "110px";

  created(): void {
    window.addEventListener("scroll", this.handleScroll);
    authRequestService.addToUser({ userId: 1, authId: "MSA" });
  }

  MSACode = "";
  SAPCode = "";
  tableDrawItems: MSAFile[] = [];
  tableDrawHeaders = [
    {
      text: this.$tc("msa.document"),
      align: "start",
      sortable: false,
      value: "sapCode",
    },
    {
      text: this.$tc("msa.codeMSA"),
      align: "start",
      sortable: false,
      value: "msaCode",
    },
    {
      text: "",
      align: "start",
      sortable: false,
      value: "actions",
    },
  ];

  get disableMSAField(): boolean {
    return this.SAPCode.length > 0;
  }

  get disableSAPField(): boolean {
    return this.MSACode.length > 0;
  }

  get windowHeight(): number {
    return window.innerHeight;
  }

  async submitSearch(): Promise<void> {
    if (this.SAPCode.length > 0) {
      try {
        overlayModule.showOverlay();

        const pdfNamesResponse = await msaService.getPdfNames(this.SAPCode);
        if (pdfNamesResponse.status === 200) {
          this.tableDrawItems = pdfNamesResponse.data;
          setTimeout(() => {
            const children = document.getElementById("msa-table").children;
            if (children.length) {
              children[0].addEventListener("scroll", this.onScroll);
            }
          }, 0);
          overlayModule.hideOverlay();
        }
        if (pdfNamesResponse.status === 204) {
          const SAPCodesResponse = await msaService.getSAPCodesStartingFromSAP(
            this.SAPCode
          );
          if (SAPCodesResponse.status === 200) {
            this.tableDrawItems = SAPCodesResponse.data;
            setTimeout(() => {
              const children = document.getElementById("msa-table").children;
              if (children.length) {
                children[0].addEventListener("scroll", this.onScroll);
              }
            }, 0);
            overlayModule.hideOverlay();
          }
          if (SAPCodesResponse.status === 204) {
            this.tableDrawItems = [];
            snackbarModule.appearSnackbar({
              message: this.$t("msa.NotFoundFileWithSAP", {
                SAPCode: this.SAPCode,
              }),
              type: "error",
            });
          }
        }
      } catch (e) {
        this.tableDrawItems = [];
      }
    } else {
      if (this.MSACode.length > 0) {
        try {
          overlayModule.showOverlay();
          const response = await msaService.getSAPCode(this.MSACode);
          if (response.status === 200) {
            this.tableDrawItems = response.data;
            setTimeout(() => {
              const children = document.getElementById("msa-table").children;
              if (children.length) {
                children[0].addEventListener("scroll", this.onScroll);
              }
            }, 0);
            overlayModule.hideOverlay();
          }
          if (response.status === 204) {
            this.tableDrawItems = [];
            snackbarModule.appearSnackbar({
              message: this.$t("msa.NotFoundFileWithMSA", {
                MSACode: this.MSACode,
              }),
              type: "error",
            });
          }
        } catch (e) {
          this.tableDrawItems = [];
        }
      } else {
        this.tableDrawItems = [];
        snackbarModule.appearSnackbar({
          message: this.$tc("msa.InvalidFields"),
          type: "warning",
        });
      }
    }
  }

  handleScroll(event) {
    if (this.tableDrawItems.length > 0) {
      let ris = this.windowScroll - window.top.scrollY;
      if (ris == 0) {
        this.btnVisible = true;
        this.windowScroll = window.top.scrollY;
        this.btnPosition = "110px";
      }
    } else {
      this.btnVisible = true;
      this.btnPosition = "110px";
    }
  }

  clickRow(row: MSAFile): void {
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: { code: row.sapCode, type: "MSA" },
    });
    window.open(routeData.href, "_blank");
  }

  onScroll(e: any) {
    const children = document.getElementById("msa-table").children;
    this.scrollTop = children[0].scrollTop;
    if (this.scrollTop > 100) {
      setTimeout(() => (this.btnVisible = false), 0);
      this.btnPosition = "134px";
      setTimeout(() => this.$vuetify.goTo(240), 0);
    }
  }
}
</script>
<style>
.title-h1-refactor {
  font-size: 52px !important;
}
.header-bold > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: bold !important;
}
.brack-world-custom > .v-data-table__wrapper > table > tbody > tr > td {
  word-break: break-word;
}
</style>
