import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true, name: "spicyImages" })
class SpicyImagesModule extends VuexModule {
  private _images: string[] = null;

  get images() {
    return this._images;
  }

  @Mutation
  private setSpicyImagesMutation(images: string[]) {
    this._images = images;
  }

  @Action
  public async setSpicyImages(images: string[]): Promise<void> {
    this.context.commit("setSpicyImagesMutation", images);
  }
}

export default SpicyImagesModule;
