import { FileResponse } from "@/models/entities/fileFormats/fileResponse.interface";
import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";

const CONTROLLER = "rapidLink";
class RapidLinkService extends ApiService {
  constructor() {
    super(ApiEndpoint.STANDARD);
  }
  getLinkByAuth(authId: string) {
    return this.getAll<string>(`${CONTROLLER}/linktoopen?authId=${authId}`);
  }

  getFileSecurityChart() {
    return this.getAll<FileResponse>(`${CONTROLLER}/filesecuritychart`);
  }

  getRubricFile() {
    return this.getAll<FileResponse>(`${CONTROLLER}/rubricfile`);
  }
}

export const rapidLinkService = new RapidLinkService();
