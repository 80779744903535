<template>
  <v-dialog v-model="show" :width="700">
    <v-card class="pa-6">
      <v-card-title>{{
        $tc("smallMaintenance.smallMaintenancePlan.ChoiceAnswerTypeTitle")
      }}</v-card-title>
      <v-col>
        <v-row
          v-for="(item, idx) in answerTypesAllowed"
          :key="idx"
          align="center"
          class="mt-0"
          :class="{
            selected: item.idRisposta === actualTypeInternal,
          }"
          @click="selectedActualType(item)"
        >
          <v-col cols="4">
            <div
              class="standard-answer-type"
              :class="getClassByType(item)"
            ></div>
          </v-col>
          <v-col cols="8">
            {{ item.risposta }}
          </v-col>
        </v-row>
      </v-col>
      <v-card-actions>
        <v-row justify="end" no-gutters style="gap: 12px">
          <v-btn @click="show = false">{{ $tc("general.close") }}</v-btn>
          <v-btn color="primary" @click="saveAnswer">{{
            $tc("general.confirm")
          }}</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
import {
  SmallMaintenanceAnswerTypes,
  SmallMaintenanceRowBase,
} from "@/models/entities/maintenance/smallMaintenancePlan.interface";
import { SaveSmallMaintenanceAnswerRequest } from "@/models/entities/maintenance/smallMaintenanceRequests.interface";
import { maintenanceService } from "@/services/api/maintenance.service";

const snackbarModule = getModule(SnackbarModule, store);

@Component({})
export default class ChoiceAnswerTypeDialog extends Vue {
  @VModel()
  show: boolean;

  @Prop({ required: false, type: Array, default: [] })
  answerTypes: SmallMaintenanceAnswerTypes[];

  @Prop({ required: false, type: Object, default: {} })
  answer: SmallMaintenanceRowBase;

  actualTypeInternal: number = null;

  created() {
    this.actualTypeInternal = this.answer.answerType;
  }

  get answerTypesAllowed(): SmallMaintenanceAnswerTypes[] {
    if (this.answer.isSecurityQuestion) {
      return this.answerTypes.filter(
        (el) => el.idRisposta === 1 || el.idRisposta === 4
      );
    }
    return this.answerTypes;
  }

  getClassByType(answerType: SmallMaintenanceAnswerTypes): string {
    switch (answerType.idRisposta) {
      case 1: //Effettuato
        return "answer-made";
      case 2: //Non effettuabile
        return "answer-not-feasible";
      case 3: //Non effettuato
        return "answer-not-made";
      case 4: //Macchina ferma
        return "answer-stopped";
    }
  }

  selectedActualType(actualType: SmallMaintenanceAnswerTypes) {
    this.actualTypeInternal = actualType.idRisposta;
  }

  async saveAnswer() {
    if (!this.actualTypeInternal) {
      snackbarModule.appearSnackbar({
        message: this.$tc("smallMaintenance.smallMaintenancePlan.TypeNotValid"),
        type: "warning",
      });
      return;
    }
    const request: SaveSmallMaintenanceAnswerRequest = {
      answerGroupId: this.answer.answerGroupId,
      questionId: this.answer.questionId,
      answerType: this.actualTypeInternal,
    };

    await maintenanceService.saveSmallMaintenanceAnswer(request);
    this.show = false;
    this.$emit("saveAnswer");
  }
}
</script>

<style scoped>
.standard-answer-type {
  width: 100%;
  height: 100px;
  border: 1px solid;
}

.answer-made {
  background: green;
}

.answer-not-feasible {
  background: yellow;
}

.answer-not-made {
  background: orange;
}

.answer-stopped {
  background: red;
}

.selected {
  border: 2px solid;
  border-color: var(--v-primary-base);
}
</style>
