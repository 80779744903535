<template>
  <v-dialog v-model="show" :width="700">
    <v-card class="pa-6">
      <v-card-title>{{
        $tc("smallMaintenance.smallMaintenancePlan.ChoiceOutcomeTitle")
      }}</v-card-title>
      <v-col>
        <v-row
          v-for="(item, idx) in Object.keys(outcomeAllowed).map(Number)"
          :key="idx"
          align="center"
          class="mt-0"
          :class="{
            selected: item === actualOutcomeInternal,
          }"
          @click="selectedActualOutcome(item)"
        >
          <v-col cols="4">
            <div
              class="standard-answer-type"
              :class="getClassByType(item)"
            ></div>
          </v-col>
          <v-col cols="8"> {{ getOutcomeDescription(item) }} </v-col>
        </v-row>
      </v-col>
      <v-card-actions>
        <v-row justify="end" no-gutters style="gap: 12px">
          <v-btn @click="show = false">{{ $tc("general.close") }}</v-btn>
          <v-btn color="primary" @click="saveOutcome">{{
            $tc("general.confirm")
          }}</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import {
  SmallMaintenanceAnswerTypes,
  SmallMaintenanceRowBase,
} from "@/models/entities/maintenance/smallMaintenancePlan.interface";
import { SmallMaintenanceOutcomeEnum } from "@/models/enumerations/smallMaintenanceOutcome.enum";
import { SaveSmallMaintenanceOutcomeRequest } from "@/models/entities/maintenance/smallMaintenanceRequests.interface";
import { maintenanceService } from "@/services/api/maintenance.service";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";

const snackbarModule = getModule(SnackbarModule, store);

@Component({})
export default class ChoiceOutcomeDialog extends Vue {
  @VModel()
  show: boolean;

  @Prop({ required: false, type: Object, default: {} })
  answer: SmallMaintenanceRowBase;

  outcomes = SmallMaintenanceOutcomeEnum;
  possibleOutcomes: SmallMaintenanceOutcomeEnum;
  actualOutcomeInternal: SmallMaintenanceOutcomeEnum = null;

  get outcomeAllowed(): SmallMaintenanceOutcomeEnum {
    if (this.answer.isSecurityQuestion) {
      const outcomes = this.possibleOutcomes;
      return Object.keys(this.possibleOutcomes).reduce(function (
        filtered,
        key
      ) {
        if (Number(key) === 1 || Number(key) === 2) {
          filtered[key] = outcomes[key];
        }
        return filtered;
      },
      {} as SmallMaintenanceOutcomeEnum);
    }
    return this.possibleOutcomes;
  }

  created() {
    this.actualOutcomeInternal = this.answer.controlOK;
    const outcomes = this.outcomes;
    this.possibleOutcomes = Object.keys(outcomes).reduce(function (
      filtered,
      key
    ) {
      if (!isNaN(Number(key))) {
        filtered[key] = outcomes[key];
      }
      return filtered;
    },
    {} as SmallMaintenanceOutcomeEnum);
  }

  getClassByType(answerType: SmallMaintenanceOutcomeEnum): string {
    switch (answerType) {
      case SmallMaintenanceOutcomeEnum.OK:
        return "outcome-ok";
      case SmallMaintenanceOutcomeEnum.KO:
        return "outcome-ko";
      case SmallMaintenanceOutcomeEnum.NOT_MADE:
        return "outcome-not-made";
      case SmallMaintenanceOutcomeEnum.STOPPED:
        return "outcome-stopped";
    }
  }

  selectedActualOutcome(actualOutcome: SmallMaintenanceOutcomeEnum) {
    this.actualOutcomeInternal = actualOutcome;
  }

  getOutcomeDescription(item: SmallMaintenanceOutcomeEnum) {
    switch (item) {
      case SmallMaintenanceOutcomeEnum.NOT_MADE:
        return this.$tc("smallMaintenance.smallMaintenancePlan.NotMadeOutcome");
      case SmallMaintenanceOutcomeEnum.STOPPED:
        return this.$tc("smallMaintenance.smallMaintenancePlan.StoppedOutcome");
      default:
        return this.possibleOutcomes[item];
    }
  }

  async saveOutcome() {
    if (!this.actualOutcomeInternal) {
      snackbarModule.appearSnackbar({
        message: this.$tc(
          "smallMaintenance.smallMaintenancePlan.OutcomeNotValid"
        ),
        type: "warning",
      });
      return;
    }
    const request: SaveSmallMaintenanceOutcomeRequest = {
      answerGroupId: this.answer.answerGroupId,
      questionId: this.answer.questionId,
      outcome: this.actualOutcomeInternal,
    };

    await maintenanceService.saveSmallMaintenanceOutcome(request);
    this.show = false;
    this.$emit("saveOutcome");
  }
}
</script>

<style scoped>
.standard-answer-type {
  width: 100%;
  height: 100px;
  border: 1px solid;
}

.outcome-ok {
  background: green;
}

.outcome-not-made {
  background: yellow;
}

.outcome-stopped {
  background: orange;
}

.outcome-ko {
  background: red;
}

.selected {
  border: 2px solid;
  border-color: var(--v-primary-base);
}
</style>
