<template :key="">
  <v-dialog v-model="isOpen" :persistent="persistent" :width="700">
    <v-card class="pa-6">
      <v-form v-model="validForm" ref="formValidation">
        <input-row
          v-model="modalData.userName"
          :inTitle="$tc('adminComponents.inUserName')"
          :rules="[required]"
        ></input-row>
        <!--        <input-row-->
        <!--          :inTitle="$tc('adminComponents.inUserDesc')"-->
        <!--          v-model="modalData.userDescription"-->
        <!--        ></input-row>-->
        <input-row
          :inTitle="$tc('adminComponents.inName')"
          v-model="modalData.name"
        ></input-row>
        <input-row
          :inTitle="$tc('adminComponents.inSurname')"
          v-model="modalData.surname"
        ></input-row>

        <input-row
          :inTitle="$tc('adminComponents.inWindowsUser')"
          v-model="modalData.windowsUser"
        ></input-row>

        <input-row
          :inTitle="$tc('adminComponents.inPassword')"
          v-model="modalData.password"
          v-if="!modalData.isEdit"
          :rules="[password, required]"
        ></input-row>
        <input-row
          :inTitle="$tc('adminComponents.inSerialNumber')"
          v-model="modalData.serialNumber"
        ></input-row>
        <select-row
          :inTitle="$tc('adminComponents.inUserType')"
          :inItems="modalData.userRoleArr"
          :rules="[required]"
          @inChanged="userRoleChanged"
          v-model="modalData.userRole"
        ></select-row>
        <select-row
          :inTitle="$tc('adminComponents.inGroup')"
          :inItems="modalData.userGroupIdArr"
          @inChanged="groupChanged"
          v-model="modalData.userGroupId"
          v-if="modalData.userRole == 'User'"
        ></select-row>
        <select-row
          :inTitle="$tc('adminComponents.plant')"
          :inItems="plantArr"
          v-model="plantId"
        ></select-row>
        <check-row
          v-if="modalData.isEdit"
          :inTitle="$tc('adminComponents.inIsEnabled')"
          v-model="modalData.isEnabled"
        ></check-row>
      </v-form>
      <v-row>
        <v-col cols="4">
          <strong
            :style="
              $vuetify.breakpoint.mdAndDown
                ? 'font-size: 26px'
                : 'font-size: 18px'
            "
            >{{ $tc("adminComponents.inAuth") }}</strong
          >
        </v-col>
        <v-col cols="8">
          <div
            class="d-flex"
            v-for="auth in modalData.auths"
            :key="auth.authId"
          >
            <div class="d-flex">
              <v-checkbox
                class="ma-0"
                dense
                v-model="auth.isChecked"
                color="primary"
                :key="auth.authId"
                v-if="auth.authId !== 'USR' || modalData.userRole == 'Admin'"
              ></v-checkbox>
              <v-chip
                v-if="auth.authId !== 'USR' || modalData.userRole == 'Admin'"
                :class="'mr-2 mod--chip ' + chipColor(auth.authId)"
              >
                {{ auth.authName }}
              </v-chip>
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- TODO: MAKE INTO A SELECT -->
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn color="primary" outlined block @click="closeModal">
              {{ $tc("general.cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              v-if="modalData.isEdit"
              color="primary"
              block
              @click="submitEditUser"
            >
              {{ $tc("adminComponents.btnEditUser") }}
            </v-btn>
            <v-btn v-else color="primary" block @click="submitAddUser">
              {{ $tc("adminComponents.btnCreateUser") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { adminService } from "@/services/api/admin.service";
import OverlayModule from "@/store/modules/overlay.module";
import { Component, Vue, Prop, VModel } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import {
  AuthItem,
  AuthItemNamed,
} from "@/models/entities/auth/authItem.interface";
import { custom } from "@/helpers/customMethods";
import {
  SelectItemNumeric,
  SelectItemString,
} from "@/models/entities/components/components.interface";
import { AuthorizationsUserUpdateRequest } from "@/models/entities/auth/authRequest.interface";
import {
  GroupAuthInfoSelect,
  UserAuthInfoAuths,
  UserCreateModal,
} from "@/models/entities/admin/admin.interface";
import { Plant } from "@/models/entities/admin/plant.interface";
import { required, password } from "@/helpers/rules";
import SnackbarModule from "@/store/modules/snackbar.module";
import { UserCreateItem } from "@/models/entities/admin/userItem.interface";

const overlayModule = getModule(OverlayModule, store);

const snackbarModule = getModule(SnackbarModule, store);

@Component({})
export default class DialogUserNew extends Vue {
  @VModel({ default: false })
  isOpen: boolean | undefined;
  // Defines wether dialog is persistent.
  @Prop({ required: false, type: Boolean, default: false })
  persistent: boolean | undefined;

  // Defines variables, case edit.
  @Prop({ required: false, type: Boolean, default: false })
  _isEdit: boolean | undefined;
  @Prop({ required: false, type: Number, default: -1 })
  _userId: number | undefined;
  @Prop({ required: false, type: String, default: "" })
  _userName: string | undefined;
  @Prop({ required: false, type: String, default: "" })
  _password: string | undefined;
  @Prop({ required: false, type: String, default: "" })
  _userDescription: string | undefined;
  @Prop({ required: false, type: String, default: "" })
  _name: string | undefined;
  @Prop({ required: false, type: String, default: "" })
  _surname: string | undefined;
  @Prop({ required: false, type: String, default: "" })
  _windowsUser: string | undefined;
  @Prop({ required: false, type: String, default: "" })
  _email: string | undefined;
  @Prop({ required: false, type: String, default: "" })
  _userRole: string | undefined;
  @Prop({ required: false, type: Number, default: -1 })
  _userGroupId: number | undefined;
  @Prop({ required: false, type: Array, default: [] as UserAuthInfoAuths[] })
  _auths: UserAuthInfoAuths[] | undefined;
  @Prop({ required: false, type: Boolean, default: true })
  _isEnabled: boolean | undefined;
  @Prop({ required: false, type: Number, default: 0 })
  _plant: number;
  @Prop({ required: false, type: String, default: undefined })
  _serialNumber: string;

  modalData = {} as UserCreateModal;

  plant: Plant[] = [];
  plantArr: SelectItemString[] = [{ value: "", text: "" }];
  plantId = 0;

  password = password;
  required = required;

  validForm = false;

  // Emitted events:
  created(): void {
    this.modalData = {
      isEdit: this._isEdit,
      userId: this._userId,
      userName: this._userName,
      password: this._password,
      userDescription: this._userDescription,
      name: this._name,
      surname: this._surname,
      windowsUser: this._windowsUser,
      email: this._email,
      userRole: this._userRole,
      userRoleArr: [{ value: "", text: "" }] as SelectItemString[],
      userGroupId: this._userGroupId ? this._userGroupId : -1,
      userGroupIdArr: [{ value: -1, text: "" }] as GroupAuthInfoSelect[],
      isEnabled: this._isEnabled,
      auths: [] as AuthItemNamed[],
      serialNumber: this._serialNumber,
    };

    overlayModule.showOverlay();
    // load auths
    if (this._plant !== 0) {
      this.plantId = this._plant;
    }
    adminService
      .getAllAuths()
      .then(async (resAuth) => {
        this.modalData.auths = resAuth.data;
        if (this._auths && this._auths.length > 0) {
          // let checkedAuths = resAuth.data;
          this.modalData.auths = this.modalData.auths.map((i) => {
            if (this._auths) {
              i.isChecked =
                this._auths.filter((j) => j.authId === i.authId)!.length > 0;
            }
            return i;
          });
        }

        // load roles
        adminService.getRoles().then((resRoles) => {
          let rolesArr = [] as SelectItemString[];
          resRoles.data.forEach((r) => {
            rolesArr.push({
              value: r.userRoleId,
              text: r.userRoleName,
            });
          });
          this.modalData.userRoleArr = rolesArr;
          this.modalData.userRole = this._userRole;
          // load groups
          adminService.getGroupDefault().then((resGroup) => {
            resGroup.data.forEach((i) => {
              this.modalData.userGroupIdArr.push({
                value: i.userGroupId,
                text: i.userGroupName,
                auths: i.auths,
              });
            });
          });
        });
        this.plant = (await adminService.getAllPlants()).data;
        this.plant.forEach((x) => {
          this.plantArr.push({
            value: x.plantId,
            text: x.plantDescription,
          } as SelectItemString);
        });

        if (this.modalData.isEdit) {
          this.plantId = (
            await adminService.getPlanById(this.modalData.userId)
          ).data;
        }
      })
      .finally(() => {
        overlayModule.hideOverlay();
      });
  }

  closeModal(): void {
    this.isOpen = false;
    this.$emit("closeModal");
  }

  chipColor(authId: string): string {
    return custom.defineChipColor(authId);
  }

  async submitAddUser(): Promise<void> {
    overlayModule.showOverlay();
    let submitItem: UserCreateItem = {
      userId: this.modalData.userId || -1,
      firstPassword: this.modalData.password,
      userName: this.modalData.userName,
      userDescription: this.modalData.userDescription,
      name: this.modalData.name,
      surname: this.modalData.surname,
      windowsUser: this.modalData.windowsUser,
      email: this.modalData.email,
      userRoleId: this.modalData.userRole,
      userGroupId: this.modalData.userGroupId,
      isEnabled: this.modalData.isEnabled,
      plantId: this.plantId,
      serialNumber: this.modalData.serialNumber
        ? this.modalData.serialNumber
        : null,
    };
    const formElement = this.$refs.formValidation as Vue & {
      validate: () => boolean;
    };
    formElement.validate();
    if (this.validForm) {
      let res = await adminService.usersCreate(submitItem);

      if (res.data) {
        let thingsToPublish = [] as AuthorizationsUserUpdateRequest[];
        this.modalData.auths.forEach((a) => {
          thingsToPublish.push({
            userId: res.data,
            authId: a.authId,
            toAdd: a.isChecked,
          });
        });
        await adminService.usersUpdateAuths(thingsToPublish);
      }

      this.closeModal();
    } else {
      snackbarModule.appearSnackbar({
        message: "Inserisci i campi corretti",
        type: "error",
      });
    }
    overlayModule.hideOverlay();
  }

  async submitEditUser(): Promise<void> {
    const formElement = this.$refs.formValidation as Vue & {
      validate: () => boolean;
    };
    formElement.validate();
    if (this.validForm) {
      overlayModule.showOverlay();
      let submitItem: UserCreateItem = {
        userId: this.modalData.userId || -1,
        firstPassword: "",
        userName: this.modalData.userName,
        userDescription: this.modalData.userDescription,
        name: this.modalData.name,
        surname: this.modalData.surname,
        windowsUser: this.modalData.windowsUser,
        email: this.modalData.email,
        userRoleId: this.modalData.userRole,
        userGroupId: this.modalData.userGroupId,
        isEnabled: this.modalData.isEnabled,
        plantId: this.plantId,
        serialNumber: this.modalData.serialNumber,
      };
      let thingsToPublish = [] as AuthorizationsUserUpdateRequest[];
      this.modalData.auths.forEach((a) => {
        thingsToPublish.push({
          userId: this.modalData.userId || -1,
          authId: a.authId,
          toAdd: a.isChecked,
        });
      });
      await adminService.usersEdit(submitItem);
      await adminService.usersUpdateAuths(thingsToPublish);
      this.closeModal();
    } else {
      snackbarModule.appearSnackbar({
        message: "Inserisci i campi corretti",
        type: "error",
      });
    }
    overlayModule.hideOverlay();
  }

  groupChanged(groupSelected: number): void {
    this.modalData.auths = this.modalData.auths.map((el) => {
      el.isChecked = false;
      const filtered = this.modalData.userGroupIdArr.filter(
        (x) => x.value == groupSelected
      );
      if (
        filtered &&
        filtered.length &&
        filtered[0].auths &&
        filtered[0].auths.length &&
        filtered[0].auths.map((x) => x.authId).includes(el.authId)
      ) {
        el.isChecked = true;
      }
      return el;
    });
  }

  userRoleChanged(roleSelected: string): void {
    if (roleSelected === "Admin") {
      this.modalData.userGroupId = -1;
      for (let auth of this.modalData.auths) {
        auth.isChecked = true;
      }
    }
  }
}
</script>

<style scoped>
.is-son-auth {
  padding-left: 30px;
}
</style>
