var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pb-12",attrs:{"cols":"12"}},[_c('title-bar',{attrs:{"title":_vm.$tc('datasheetList.title'),"is-parent-element":true}})],1)],1),(_vm.buttonListEnabled.length)?_c('v-row',_vm._l((_vm.buttonListEnabled),function(btn){return _c('v-col',{key:btn.id,staticStyle:{"text-align":"center"},attrs:{"cols":_vm.isOrientationPortrait ? 4 : 3},on:{"click":function($event){return _vm.goToPage(btn.r, btn.id)}}},[_c('v-img',{attrs:{"src":btn.id == 'SMP'
            ? require('@/assets/smp.png')
            : btn.id == 'SAT'
            ? require('@/assets/Scheda Tecnica Attrezzatura.png')
            : btn.id == 'SDS'
            ? require('@/assets/SDS.png')
            : btn.id == 'STT'
            ? require('@/assets/Scheda Tecnica TT&FIN.png')
            : btn.id == 'STS'
            ? require('@/assets/Scheda Tecnica Saldatura.png')
            : ''}}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.$tc(btn.t)))])],1)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }