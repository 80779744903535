<template>
  <div class="mod--fill_height d-flex flex-column">
    <v-row class="flex-grow-0">
      <v-col>
        <v-img
          :alt="$tc('alt.logoDigita')"
          class="shrink mx-auto mb-4"
          contain
          src="../../public/img/logos/msa-digita-logo.png"
          transition="scale-transition"
          width="500px"
          max-width="500px"
        />
      </v-col>
    </v-row>
    <div class="ma-n4 mb-n12 pa-4 flex-grow-1">
      <v-row justify="center">
        <v-col cols="6" style="text-align: center" class="pb-12">
          <h1 class="primary--text">{{ $tc("login.title") }}</h1>
        </v-col>
        <v-col cols="12">
          <v-form v-model="isLoginFormValid" @submit.prevent="submitLogin">
            <v-row justify="center">
              <v-col cols="8">
                <v-row justify="center">
                  <v-col style="max-width: 450px">
                    <input-center
                      :inTitle="$tc('mp.user')"
                      :inPlaceHolder="$tc('mp.user')"
                      v-model="userName"
                      :rules="[required]"
                      class="pb-3"
                    ></input-center>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col style="max-width: 450px">
                    <password-row
                      :inTitle="$tc('login.labelPassword')"
                      iconName="mdi-lock"
                      :rules="[required]"
                      v-model="pw"
                      inType="text"
                    ></password-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10">
                <v-row justify="center">
                  <v-col cols="5" style="max-width: 350px">
                    <v-btn
                      outlined
                      class="mod--btn mod--btn_primary white--text mt-4"
                      elevation="1"
                      block
                      small
                      @click="goToRecoveryPassword"
                    >
                      <span>{{ $tc("login.RecoveryPassword") }}</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="3" style="max-width: 200px">
                    <v-btn
                      outlined
                      class="mod--btn mod--btn_primary white--text mt-4"
                      elevation="1"
                      block
                      small
                      type="submit"
                    >
                      <span>{{ $tc("general.login") }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { required } from "@/helpers/rules";
import { authService } from "@/services/api/authenticate.service";
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import OverlayModule from "@/store/modules/overlay.module";

const snackBarModule = getModule(SnackbarModule, store);
const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component
export default class LoginPage extends Vue {
  private userName = "";
  private pw = "";
  private isLoginFormValid = "";
  private required = required;

  get isOrientationPortrait(): boolean {
    return screen.availHeight < screen.availWidth;
  }

  get isDesktop(): boolean {
    return this.$vuetify.breakpoint.smAndUp;
  }

  private async submitLogin(): Promise<void> {
    if (this.isLoginFormValid) {
      overlayModule.showOverlay();
      authService
        .login({ username: this.userName, password: this.pw })
        .then((res) => {
          authService
            .getAuthsForId(res.data.user.userID)
            .then((resAuth) => {
              if (resAuth.data.length > 0) {
                authModule.login(res.data);
                authModule.enabledSections(resAuth.data);
                if (res.data.user.needsSecurityQuestion) {
                  this.$router.push({
                    name: "setSecurityQuestion",
                    params: {
                      _needsPasswordReset: res.data.user.needspasswordReset
                        ? "true"
                        : "false",
                    },
                  });
                } else {
                  if (res.data.user.needspasswordReset) {
                    this.$router.push({
                      name: "changePassword",
                      params: {
                        hideBtn: "true",
                      },
                    });
                  } else {
                    this.$router.push({ name: "home" });
                  }
                }
              } else {
                snackBarModule.appearSnackbar({
                  message: this.$tc("login.errorAuth"),
                  type: "error",
                });
              }
              overlayModule.hideOverlay();
            })
            .catch((err) => {
              snackBarModule.appearSnackbar({
                message: err.response.data,
                type: "error",
              });
              overlayModule.hideOverlay();
            });
        })
        .catch((err) => {
          snackBarModule.appearSnackbar({
            message: err.response.data,
            type: "error",
          });
          overlayModule.hideOverlay();
        });
    }
  }

  goToRecoveryPassword(): void {
    this.$router.push({
      name: "recoveryPassword",
    });
  }
}
</script>

<style scoped>
.login-bg-img {
  background-image: url("@/assets/background/Sfondo_schermate-05.jpg") !important;
  background-size: 100%;
}
</style>
