<template>
  <v-dialog v-model="show" width="700px">
    <v-card>
      <v-card-title>{{ $tc("selectrevisiondialog.Title") }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-simple-table fixed-header height="350">
          <template v-slot:default>
            <thead>
              <tr>
                <th style="text-align: center">
                  <h5>Header 1</h5>
                </th>
                <th style="text-align: center">
                  <h5>Header 2</h5>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(revision, idx) in revisionList"
                :key="idx"
                :class="{ selected: currentRevision === revision }"
                @click="setSelectedRevision(revision)"
              >
                <td style="text-align: center">
                  <span>{{ revision.modulo }}</span>
                </td>
                <td style="text-align: center">
                  <span>{{ revision.revisionePDM }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters>
          <v-col cols="6" style="padding: 12px">
            <v-btn color="primary" outlined block @click="show = false">
              {{ $tc("general.cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="6" style="padding: 12px">
            <v-btn color="primary" block @click="submitRevision">
              {{ $tc("general.confirm") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, VModel, Prop } from "vue-property-decorator";
import { ActivePartRevisionItem } from "@/models/entities/equipmentManagement/ActivePartRevisionItem.interface";

@Component({})
export default class SelectRevisionDialog extends Vue {
  @VModel({ default: false })
  show: boolean | undefined;

  @Prop({ required: true, default: [], type: Array })
  revisionList: ActivePartRevisionItem[];

  currentRevision: ActivePartRevisionItem = null;

  submitRevision() {
    this.$emit("submitRevision", this.currentRevision.revisionePDM);
    this.show = false;
  }

  setSelectedRevision(revisionSelected: ActivePartRevisionItem) {
    if (!this.currentRevision) this.currentRevision = revisionSelected;
    else {
      this.currentRevision === revisionSelected
        ? (this.currentRevision = null)
        : (this.currentRevision = revisionSelected);
    }
  }
}
</script>

<style scoped>
td {
  font-size: 20px !important;
}

.selected {
  background: lightgray;
}
</style>
