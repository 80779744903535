<template>
  <div id="page">
    <v-col cols="12" class="pb-12 fill-height">
      <title-bar
        :title="$tc('activepartsandnormalized.Title')"
        :is-parent-element="false"
      ></title-bar>
      <v-row>
        <v-col cols="3"></v-col>
        <v-col cols="6">
          <v-row class="pt-16" justify="center">
            <v-btn
              min-height="75"
              width="250"
              rounded
              x-large
              @click="goToPage('activeParts')"
              >{{ $tc("activepartsandnormalized.ActiveParts") }}</v-btn
            >
          </v-row>
          <v-row class="pt-6" justify="center">
            <v-btn
              min-height="75"
              width="250"
              rounded
              x-large
              @click="goToPage('normalized')"
              >{{ $tc("activepartsandnormalized.Normalized") }}</v-btn
            >
          </v-row>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component
export default class ActivePartsAndNormalized extends Vue {
  btnPosition = "110px";

  goToPage(routeName: string) {
    this.$router.push({
      name: routeName,
    });
  }
}
</script>
<style></style>
