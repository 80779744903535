import { ApiEndpoint } from "../api.enum";
import { ApiService } from "../api.service";

const CONTROLLER_NON_PDM = "searchnonpdm";
const CONTROLLER_PDM = "searchpdm";

class ActivePartsAndNormalizedService extends ApiService {
  constructor() {
    super(ApiEndpoint.DLL);
  }

  searchFileNONPdm(cdAtr: string, nPosi: number, stSottoP: string) {
    return this.getAll<string>(
      `${CONTROLLER_NON_PDM}?_CdAtr=${cdAtr}&_nPosi=${nPosi}&_stSottoP=${stSottoP}`
    );
  }

  searchFilePdm(codicePDM: string, revisionePDM: string) {
    return this.getAll<string>(
      `${CONTROLLER_PDM}?_stCodicePDM=${codicePDM}&_stRevisionePDM=${revisionePDM}`
    );
  }
}

export const activePartsAndNormalizedService =
  new ActivePartsAndNormalizedService();
