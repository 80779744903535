<template>
  <v-dialog v-model="show" :width="700">
    <v-card class="pa-2">
      <v-card-title>{{
        $tc("smallMaintenance.smallMaintenancePlan.ConfirmCompilationTitle")
      }}</v-card-title>
      <v-col class="pl-6">
        <span>{{
          $tc("smallMaintenance.smallMaintenancePlan.ConfirmCompilationText")
        }}</span>
      </v-col>
      <v-card-actions>
        <v-row justify="end" no-gutters style="gap: 12px">
          <v-btn @click="show = false">{{ $tc("general.close") }}</v-btn>
          <v-btn color="primary" @click="completeCompilation">{{
            $tc("general.confirm")
          }}</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { SmallMaintenanceHeader } from "@/models/entities/maintenance/smallMaintenanceHeader.interface";
import { getModule } from "vuex-module-decorators";
import MaintenanceModule from "@/store/modules/maintenance.module";
import store from "@/store";
import { SmallMaintenancePlanRequest } from "@/models/entities/maintenance/smallMaintenanceRequests.interface";
import { maintenanceService } from "@/services/api/maintenance.service";

const maintenanceModule = getModule(MaintenanceModule, store);

@Component({})
export default class ConfirmCompilationDialog extends Vue {
  @VModel()
  show: boolean;

  get header(): SmallMaintenanceHeader {
    return maintenanceModule.smallMaintenanceHeader;
  }

  async completeCompilation(): Promise<void> {
    const request: SmallMaintenancePlanRequest = {
      assetId: this.header.assetId,
      planDate: this.header.planDate,
      smallMaintenancePlan: this.header.smallMaintenancePlan,
    };

    await maintenanceService.smallMaintenanceCompleteCompilation(request);
    this.show = false;
    this.$emit("completeCompilation");
  }
}
</script>

<style scoped></style>
