<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="2">
        <v-btn color="primary" @click="back"
          ><v-icon>mdi-arrow-left</v-icon>Back</v-btn
        >
      </v-col>
      <v-col cols="6"> </v-col>
      <v-col cols="4">
        <v-row no-gutters justify="end" style="gap: 4px">
          <v-btn color="primary" @click="showConfirmCompilationDialog = true"
            ><v-icon class="mr-2">mdi-content-save-all-outline</v-icon>
            {{
              $tc("smallMaintenance.smallMaintenancePlan.CompleteCompilation")
            }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="2">
        <v-text-field
          v-model="header.assetId"
          readonly
          hide-details
          :label="$tc('smallMaintenance.smallMaintenancePlan.AssetId')"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="planDateToShow"
          readonly
          :label="$tc('smallMaintenance.smallMaintenancePlan.PlanDate')"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="header.resourceType"
          readonly
          :label="$tc('smallMaintenance.smallMaintenancePlan.Plan')"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="header.user"
          readonly
          :label="$tc('smallMaintenance.smallMaintenancePlan.Resource')"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="3" class="px-0 pt-0">
        <v-checkbox
          v-model="stoppedMachine"
          class="mt-0 pl-3"
          :label="$tc('smallMaintenance.smallMaintenancePlan.StoppedMachine')"
          @click="toggleStoppedMachineDialog"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" class="px-0 pt-0"> </v-col>
      <v-col cols="3" class="px-0 pt-0">
        <v-btn
          plain
          style="text-decoration: underline"
          @click="showLegendDialog = true"
          >Mostra legenda</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="smallMaintenancePlan"
      disable-filtering
      disable-sort
      hide-default-footer
      disable-pagination
    >
      <template v-slot:[`item.isSecurityQuestion`]="{ item }">
        <td>
          {{ getSecurityQuestionDescription(item.isSecurityQuestion) }}
        </td>
      </template>
      <template v-slot:[`item.answerType`]="{ item }">
        <td style="padding: 4px; width: 60px">
          <div
            @click="openChoiceAnswerType(item)"
            class="standard-answer-type"
            :class="getClassByType(item.answerType)"
          ></div>
        </td>
      </template>
      <template v-slot:[`item.controlOK`]="{ item }">
        <td
          style="padding: 4px"
          :class="{
            'disabled-outcome': answerTypes[item.answerType] !== answerTypes[1],
          }"
        >
          <div
            @click="openChoiceOutcome(item)"
            class="standard-outcome"
            :class="getClassByOutcome(item.controlOK)"
          >
            <span
              style="
                display: flex;
                padding: 3px;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
                text-align: center;
              "
              >{{ getOutcomeDescription(item.controlOK) }}</span
            >
          </div>
        </td>
      </template>
      <template v-slot:[`item.answerNotes`]="{ item }">
        <v-text-field
          v-model="item.answerNotes"
          class="custom-field pt-0 mt-0"
          type="text"
          hide-details
          hide-spin-buttons
          @blur="saveAnswerNotes(item)"
        ></v-text-field>
      </template>
    </v-data-table>
    <choice-answer-type-dialog
      v-if="showChoiceAnswerTypeDialog"
      v-model="showChoiceAnswerTypeDialog"
      :answer-types="answerTypes"
      :answer="answerSelected"
      @saveAnswer="loadPlan"
    ></choice-answer-type-dialog>
    <choice-outcome-dialog
      v-if="showChoiceOutcomeDialog"
      v-model="showChoiceOutcomeDialog"
      :answer="answerSelected"
      @saveOutcome="loadPlan"
    ></choice-outcome-dialog>
    <confirm-stopped-machine-dialog
      v-model="showStoppedMachineDialog"
      @confirmStoppedMachine="confirmStoppedMachine"
      @closeModal="closeStoppedMachineDialog"
    ></confirm-stopped-machine-dialog>
    <confirm-compilation-dialog
      v-model="showConfirmCompilationDialog"
      @completeCompilation="back"
    ></confirm-compilation-dialog>
    <legend-dialog
      v-model="showLegendDialog"
      :answer-types="answerTypes"
    ></legend-dialog>
  </v-col>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { SmallMaintenanceHeader } from "@/models/entities/maintenance/smallMaintenanceHeader.interface";
import { getModule } from "vuex-module-decorators";
import MaintenanceModule from "@/store/modules/maintenance.module";
import store from "@/store";
import {
  SmallMaintenanceAnswerTypes,
  SmallMaintenanceRowBase,
} from "@/models/entities/maintenance/smallMaintenancePlan.interface";
import { maintenanceService } from "@/services/api/maintenance.service";
import {
  SaveSmallMaintenanceAnswerRequest,
  SaveSmallMaintenanceNotesRequest,
  SmallMaintenancePlanRequest,
} from "@/models/entities/maintenance/smallMaintenanceRequests.interface";
import { DataTableHeader } from "vuetify";
import ChoiceAnswerTypeDialog from "@/components/smallMaintenance/ChoiceAnswerTypeDialog.vue";
import OverlayModule from "@/store/modules/overlay.module";
import ChoiceOutcomeDialog from "@/components/smallMaintenance/ChoiceOutcomeDialog.vue";
import SnackbarModule from "@/store/modules/snackbar.module";
import { SmallMaintenanceOutcomeEnum } from "@/models/enumerations/smallMaintenanceOutcome.enum";
import ConfirmStoppedMachineDialog from "@/components/smallMaintenance/ConfirmStoppedMachineDialog.vue";
import ConfirmCompilationDialog from "@/components/smallMaintenance/ConfirmCompilationDialog.vue";
import LegendDialog from "@/components/smallMaintenance/LegendDialog.vue";

const maintenanceModule = getModule(MaintenanceModule, store);
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component({
  components: {
    LegendDialog,
    ConfirmCompilationDialog,
    ConfirmStoppedMachineDialog,
    ChoiceOutcomeDialog,
    ChoiceAnswerTypeDialog,
  },
})
export default class SmallMaintenancePlan extends Vue {
  smallMaintenancePlan: SmallMaintenanceRowBase[] = [];
  showChoiceAnswerTypeDialog = false;
  showChoiceOutcomeDialog = false;
  showStoppedMachineDialog = false;
  showConfirmCompilationDialog = false;
  showLegendDialog = false;
  outcomes = SmallMaintenanceOutcomeEnum;
  stoppedMachine = false;
  answerTypes: SmallMaintenanceAnswerTypes[] = [];
  answerSelected: SmallMaintenanceRowBase = null;
  headers: DataTableHeader[] = [
    {
      text: this.$tc(
        "smallMaintenance.smallMaintenancePlan.QuestionDescription"
      ),
      value: "questionDescription",
    },
    {
      text: this.$tc("smallMaintenance.smallMaintenancePlan.AnswerType"),
      value: "answerType",
    },
    {
      text: this.$tc(
        "smallMaintenance.smallMaintenancePlan.AnswerTypeDescription"
      ),
      value: "answerTypeDescription",
    },
    {
      text: this.$tc(
        "smallMaintenance.smallMaintenancePlan.IsSecurityQuestion"
      ),
      value: "isSecurityQuestion",
    },
    {
      text: this.$tc("smallMaintenance.smallMaintenancePlan.ControlOK"),
      value: "controlOK",
    },
    {
      text: this.$tc("smallMaintenance.smallMaintenancePlan.QuestionNotes"),
      value: "questionNotes",
    },
    {
      text: this.$tc("smallMaintenance.smallMaintenancePlan.AnswerNotes"),
      value: "answerNotes",
    },
  ];

  get header(): SmallMaintenanceHeader {
    return maintenanceModule.smallMaintenanceHeader;
  }

  get planDateToShow(): string {
    const date = new Date(this.header.planDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Mese con zero iniziale
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}/${month}/${year}`;
  }

  created() {
    this.loadAnswerTypes();
    this.loadPlan();
  }

  getOutcomeDescription(item: SmallMaintenanceOutcomeEnum) {
    switch (item) {
      case SmallMaintenanceOutcomeEnum.NOT_MADE:
        return this.$tc("smallMaintenance.smallMaintenancePlan.NotMadeOutcome");
      case SmallMaintenanceOutcomeEnum.STOPPED:
        return this.$tc("smallMaintenance.smallMaintenancePlan.StoppedOutcome");
      default:
        return this.outcomes[item];
    }
  }

  getSecurityQuestionDescription(isSecurityQuestion: boolean): string {
    return isSecurityQuestion
      ? this.$tc("smallMaintenance.smallMaintenancePlan.SecurityDescription")
      : "";
  }

  async loadAnswerTypes() {
    const response = await maintenanceService.getSmallMaintenanceAnswerTypes();
    this.answerTypes = response.data;
  }

  async loadPlan() {
    overlayModule.showOverlay();
    const request: SmallMaintenancePlanRequest = {
      assetId: this.header.assetId,
      smallMaintenancePlan: this.header.smallMaintenancePlan,
      planDate: this.header.planDate,
    };
    const response = await maintenanceService.getSmallMaintenanceById(request);
    this.smallMaintenancePlan = response.data;
    overlayModule.hideOverlay();
  }

  getClassByType(answerType: number): string {
    switch (answerType) {
      case 1: //Effettuato
        return "answer-made";
      case 2: //Non effettuabile
        return "answer-not-feasible";
      case 3: //Non effettuato
        return "answer-not-made";
      case 4: //Macchina ferma
        return "answer-stopped";
    }
  }

  getClassByOutcome(outcome: SmallMaintenanceOutcomeEnum): string {
    switch (outcome) {
      case SmallMaintenanceOutcomeEnum.OK:
        return "outcome-ok";
      case SmallMaintenanceOutcomeEnum.KO:
        return "outcome-ko";
      case SmallMaintenanceOutcomeEnum.NOT_MADE:
        return "outcome-not-made";
      case SmallMaintenanceOutcomeEnum.STOPPED:
        return "outcome-stopped";
    }
  }

  openChoiceOutcome(item: SmallMaintenanceRowBase) {
    if (!item.isSecurityQuestion) {
      snackbarModule.appearSnackbar({
        message: this.$tc(
          "smallMaintenance.smallMaintenancePlan.NotAllowedOutcome"
        ),
        type: "warning",
      });
      return;
    }
    this.showChoiceOutcomeDialog = true;
    this.answerSelected = item;
  }

  async confirmStoppedMachine() {
    this.showStoppedMachineDialog = false;
    await maintenanceService.smallMaintenanceStoppedMachine(
      this.smallMaintenancePlan[0].answerGroupId
    );
    this.loadPlan();
  }

  async saveAnswerNotes(item: SmallMaintenanceRowBase) {
    const request: SaveSmallMaintenanceNotesRequest = {
      answerGroupId: item.answerGroupId,
      questionId: item.questionId,
      answerNotes: item.answerNotes,
    };

    await maintenanceService.saveSmallMaintenanceNotes(request);
  }

  closeStoppedMachineDialog() {
    this.showStoppedMachineDialog = false;
    this.stoppedMachine = false;
  }

  openChoiceAnswerType(item: SmallMaintenanceRowBase) {
    this.showChoiceAnswerTypeDialog = true;
    this.answerSelected = item;
  }

  toggleStoppedMachineDialog() {
    if (this.stoppedMachine) {
      this.showStoppedMachineDialog = true;
    }
  }

  back() {
    maintenanceModule.resetModule();
    this.$router.push({ name: "smallMaintenance" });
  }
}
</script>

<style scoped>
.v-data-table::v-deep th {
  font-size: 16px !important;
}

.v-data-table::v-deep td {
  font-size: 14px !important;
}

.custom-field :deep(.v-input__slot::before) {
  border-style: none;
}

.standard-answer-type {
  width: 100%;
  height: 100%;
  border: 1px solid;
}

.standard-outcome {
  width: 100%;
  height: 100%;
  border: 1px solid;
  max-width: 70px;
  overflow: hidden;
}

.answer-made,
.outcome-ok {
  background: green;
}

.answer-not-feasible,
.outcome-not-made {
  background: yellow;
}

.answer-not-made,
.outcome-stopped {
  background: orange;
}

.answer-stopped,
.outcome-ko {
  background: red;
}

.disabled-outcome {
  pointer-events: none;
  opacity: 0.8;
}
</style>
