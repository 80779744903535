<template>
  <v-row no-gutters style="gap: 32px; padding-top: 12px" align="center">
    <v-checkbox
      v-model="filtersProp.onlyActive"
      class="myCheckbox"
      style="margin: 0"
      hide-details
      :label="$tc('usermanagementfilters.ToggleLabel')"
      @change="$emit('filtersChanged')"
    ></v-checkbox>
    <v-select
      v-model="filtersProp.userRoleId"
      style="padding: 0; max-width: 250px"
      :items="userRoles"
      item-text="userRoleName"
      clearable
      hide-details
      item-value="userRoleId"
      :label="$tc('usermanagementfilters.FilterRoleLabel')"
      @change="onUserRoleFilterChange"
    >
    </v-select>
    <v-select
      v-model="filtersProp.userGroupId"
      style="padding: 0; max-width: 250px"
      :items="userGroups"
      item-text="userGroupName"
      hide-details
      clearable
      :disabled="disabledGroupFilter"
      item-value="userGroupId"
      :label="$tc('usermanagementfilters.FilterGroupLabel')"
      @change="$emit('filtersChanged')"
    >
    </v-select>
    <!--      @change="$emit('filtersChanged')"-->
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { UserManagementFiltersInterface } from "@/models/entities/admin/UserManagementFilters.interface";
import { GroupEditItem } from "@/models/entities/admin/groupItem.interface";
import { adminService } from "@/services/api/admin.service";
import { RolesItem } from "@/models/entities/admin/admin.interface";

@Component({})
export default class UserManagementFilters extends Vue {
  @Prop({ required: false, type: Object })
  filtersProp: UserManagementFiltersInterface;

  userGroups: GroupEditItem[] = [];
  userRoles: RolesItem[] = [];

  get disabledGroupFilter(): boolean {
    return this.filtersProp.userRoleId === "Admin";
  }

  created() {
    this.loadUserGroups();
    this.loadUserRoles();
  }

  async loadUserGroups() {
    const response = await adminService.groupsGetAll();
    this.userGroups = response.data;
  }

  async loadUserRoles() {
    const response = await adminService.getRoles();
    this.userRoles = response.data;
  }

  onUserRoleFilterChange() {
    this.filtersProp.userGroupId = null;
    this.$emit("filtersChanged");
  }
}
</script>

<style scoped>
.myCheckbox :deep(.v-label) {
  font-size: 20px !important;
}

.myCheckbox :deep(.v-icon) {
  font-size: 30px !important;
}
</style>
