<template :key="">
  <v-dialog
    v-model="isOpen"
    :persistent="persistent"
    transition="dialog-top-transition"
    :width="700"
  >
    <v-card class="pa-6">
      <v-form v-model="validForm">
        <v-row>
          <v-col>
            <span> {{ $tc("adminComponents.manageDefaultPassword") }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="defaultPassword"
              :rules="[required, password]"
              class="mod--input; v-text-field--filled-small"
              solo
              outlined
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn
              outlined
              color="primary"
              elevation="1"
              block
              @click="$emit('closeModal')"
            >
              {{ $tc("general.cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn color="primary" elevation="1" block @click="save">
              {{ $tc("general.save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { adminService } from "@/services/api/admin.service";
import OverlayModule from "@/store/modules/overlay.module";
import { Component, Vue, Prop, VModel } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import { required, password } from "@/helpers/rules";
import SnackbarModule from "@/store/modules/snackbar.module";

const overlayModule = getModule(OverlayModule, store);

const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class DialogDefaultPassword extends Vue {
  @VModel({ default: false })
  isOpen: boolean;
  // Defines wether dialog is persistent.
  @Prop({ required: false, type: Boolean, default: false })
  persistent: boolean;

  // Defines variables, case edit.
  @Prop({ required: false, type: String, default: "" })
  _lable: string;

  private validForm = false;

  private defaultPassword = "";

  private password = password;
  private required = required;

  // Emitted events:
  private async created(): Promise<void> {
    overlayModule.showOverlay();
    // load auths
    let data = await adminService.getDefaultPassword();
    this.defaultPassword = data.data;
    overlayModule.hideOverlay();
  }

  private closeModal(): void {
    this.isOpen = false;
    this.$emit("closeModal");
  }

  private async save(): Promise<void> {
    overlayModule.showOverlay();
    await adminService.saveDefaultPassword(this.defaultPassword);
    overlayModule.hideOverlay();
    this.isOpen = false;
    this.$emit("closeModal");
  }
}
</script>
