<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" class="pb-12">
        <title-bar
          :title="$tc('utility.title')"
          :is-parent-element="true"
        ></title-bar>
      </v-col>
    </v-row>
    <v-row v-if="buttonListEnabled.length">
      <v-col
        v-for="btn in buttonListEnabled"
        :key="btn.id"
        @click="goToPage(btn.id)"
        style="text-align: center"
        :cols="isOrientationPortrait ? 4 : 3"
      >
        <v-img
          :src="
            btn.id == 'RUBRIC'
              ? require('@/assets/RUBRICA.png')
              : btn.id == 'SECCHART'
              ? require('@/assets/PRIMO SOCCORSO.png')
              : ''
          "
        ></v-img>
        <span style="font-weight: bold">{{ $tc(btn.t) }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { HomeButton } from "@/models/entities/components/components.interface";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";
import { buttonSortService } from "@/services/api/buttonSort.service";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { rapidLinkService } from "@/services/api/rapidLink.service";
import { RapidLinkEnum } from "@/models/enumerations/rapidLinkEnum";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component({})
export default class Maintenance extends Vue {
  buttonListEnabled: HomeButton[] = [];
  btnPosition = "110px";

  buttonList = [
    {
      id: "RUBRIC",
      r: "utility/rubric",
      t: "home.buttonRUBRIC",
      c: "mod--color_btn_equipment",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "RUBRIC";
        }).length > 0,
      sort: 0,
    },
    {
      id: "SECCHART",
      r: "utility/secchart",
      t: "home.buttonSECCHART",
      c: "mod--color_btn_mp",
      isExt: false,
      enabled:
        authModule.sectionsList.filter((x) => {
          return x.authId === "SECCHART";
        }).length > 0,
      sort: 0,
    },
  ];

  get isOrientationPortrait() {
    const orientation = window.screen.orientation.type;
    return orientation === "portrait-primary";
  }

  async goToPage(id: string) {
    if (id === "SECCHART") {
      let routeData = this.$router.resolve({
        name: "PdfOpener",
        query: { type: "SECCHART" },
      });
      window.open(routeData.href, "_blank");
      return;
    }
    const response = await rapidLinkService.getLinkByAuth(RapidLinkEnum[id]);
    window.open(response.data, "_blank");
  }

  async created() {
    overlayModule.showOverlay();
    const response = await buttonSortService.getAuthsByParent("UTILITY");

    this.buttonListEnabled = this.buttonList.filter((value) =>
      response.data.includes(value.id)
    );
    overlayModule.hideOverlay();
  }
}
</script>

<style scoped></style>
