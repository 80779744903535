<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" class="pb-12">
        <title-bar :title="$tc('mp.title')"></title-bar>
      </v-col>
      <v-col style="width: 450px" cols="auto">
        <input-center
          :inTitle="$tc('mp.src')"
          :inPlaceHolder="$tc('mp.src')"
          v-model="MPCode"
          @submitSearch="submitSearch"
        ></input-center>
      </v-col>
    </v-row>
    <v-row class="pt-10" justify="center">
      <v-col style="width: 270px" cols="auto">
        <v-btn class="mod--btn" x-large block @click="submitSearch">
          {{ $tc("general.search") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mod--table_row" v-if="tableDrawItemsObject.length > 0">
      <v-col class="px-0">
        <v-data-table
          :headers="tableDraw.headers"
          :items="tableDrawItemsObject"
          disable-pagination
          class="elevation-1 header-bold brack-world-custom"
          hide-default-footer
          @click:row="clickRow"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn text v-if="item.value" class="mod--btn_text">
              <v-icon left color="primary" class="ml-1" :size="38">
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import { mpService } from "@/services/api/mp.service";
import AuthModule from "@/store/modules/auth.module";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";
import { fileOpen } from "@/helpers/fileOpen";
import { MPFile } from "@/models/entities/fileFormats/fileFormats.interface";
import { fileLogService } from "@/services/api/fileLog.service";
import { FileResponse } from "@/models/entities/fileFormats/fileResponse.interface";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class MPPage extends Vue {
  private MPCode = "";

  private tableDrawItems: string[] = [];

  private tableDraw = {
    headers: [
      {
        text: this.$tc("mp.codeSAP"),
        align: "start",
        sortable: false,
        value: "value",
      },
      {
        text: "",
        align: "start",
        sortable: false,
        value: "actions",
      },
    ],
  };

  get tableDrawItemsObject(): Array<{ value: string }> {
    if (this.tableDrawItems && this.tableDrawItems.length > 0) {
      return this.tableDrawItems.map((x) => ({
        value: x,
      }));
    }
    return [];
  }

  private submitSearch(): void {
    if (this.MPCode.length > 0) {
      overlayModule.showOverlay();
      mpService
        .getPdfNames(this.MPCode)
        .then((res) => {
          this.tableDrawItems = res.data;
          overlayModule.hideOverlay();
        })
        .catch(() => {
          this.tableDrawItems = [];
          overlayModule.hideOverlay();
        });
    } else {
      this.tableDrawItems = [];
      snackbarModule.appearSnackbar({
        message: this.$tc("mp.snackNoSAP"),
        type: "warning",
      });
    }
  }

  private openFile(item: string): void {
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: { code: item, type: "MP" },
    });
    window.open(routeData.href, "_blank");
  }

  private clickRow(row: any): void {
    let routeData = this.$router.resolve({
      name: "PdfOpener",
      query: { code: row.value, type: "MP" },
    });
    window.open(routeData.href, "_blank");
  }
}
</script>
<style>
.header-bold > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: bold !important;
}
.brack-world-custom > .v-data-table__wrapper > table > tbody > tr > td {
  word-break: break-word;
}
</style>
