<template>
  <div class="mod--fill_height d-flex flex-column">
    <div class="pa-4 flex-grow-1">
      <v-row justify="center">
        <v-col cols="6" style="text-align: center" class="pb-12 pt-6">
          <h1 class="primary--text">{{ $tc("securityQuestion.Title") }}</h1>
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            <v-col cols="6">
              <v-row justify="center">
                <v-col style="max-width: 500px">
                  <input-center
                    :in-place-holder="$tc('securityQuestion.QuestionTitle')"
                    v-model="question"
                  ></input-center>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col style="max-width: 500px">
                  <input-center
                    :in-place-holder="$tc('securityQuestion.AnswerTitle')"
                    v-model="answer"
                  ></input-center>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3"></v-col>
                <v-col cols="6">
                  <v-btn
                    outlined
                    class="mod--btn mod--btn_primary white--text mt-4"
                    elevation="1"
                    block
                    x-large
                    @click="submitQuestion"
                  >
                    <span>{{ $tc("securityQuestion.SubmitAnswer") }}</span>
                  </v-btn>
                </v-col>
                <v-col cols="3"></v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { authService } from "@/services/api/authenticate.service";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";

const snackbarModule = getModule(SnackbarModule, store);

@Component({})
export default class SecurityQuestion extends Vue {
  @Prop({ type: String, default: "", required: false })
  _needsPasswordReset: string;

  question = "";
  answer = "";

  get needsPasswordReset(): boolean {
    return this._needsPasswordReset === "true";
  }

  async submitQuestion() {
    if (!this.question || !this.answer) {
      snackbarModule.appearSnackbar({
        message: this.$tc("securityQuestion.InvalidParameters"),
        type: "warning",
      });
      return;
    }
    const requestAddQuestion: { question: string; answer: string } = {
      question: this.question,
      answer: this.answer,
    };
    await authService.saveQuestion(requestAddQuestion);
    if (this.needsPasswordReset) {
      this.$router.push({
        name: "changePassword",
        params: {
          hideBtn: "true",
        },
      });
    } else {
      this.$router.push({ name: "home" });
    }
  }
}
</script>

<style scoped></style>
