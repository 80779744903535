<template>
  <div></div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class XsSmallMaintenance extends Vue {}
</script>

<style scoped></style>
