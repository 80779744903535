<template>
  <div class="mod--fill_height d-flex flex-column">
    <div class="pa-4 flex-grow-1" v-if="!isChangePasswordView">
      <v-form @submit.prevent="onSubmit">
        <v-row justify="center">
          <v-col cols="2">
            <v-btn class="mod--btn reduce-back-btn" x-large @click="goToLogin">
              <v-icon left :size="32" class="mod--icon">
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" style="text-align: center" class="pb-12 pt-6">
            <h1 class="primary--text">{{ $tc("recoveryPassword.Title") }}</h1>
          </v-col>
          <v-col cols="2"></v-col>
          <v-col cols="12">
            <v-row justify="center">
              <v-col cols="6" style="max-width: 500px">
                <div style="display: flex; flex-direction: column; gap: 15px">
                  <input-center
                    :in-place-holder="$tc('recoveryPassword.Username')"
                    v-model="username"
                  ></input-center>
                  <span v-if="question">{{ question }}</span>
                  <input-center
                    v-if="question"
                    :in-place-holder="$tc('recoveryPassword.Answer')"
                    v-model="answer"
                  ></input-center>
                </div>

                <v-row>
                  <v-col cols="3"></v-col>
                  <v-col cols="6">
                    <v-btn
                      v-if="!question"
                      style="min-height: 75px"
                      outlined
                      class="mod--btn mod--btn_primary white--text mt-4"
                      elevation="1"
                      block
                      small
                      type="submit"
                    >
                      <!--                      @click="getQuestion"-->
                      <span>{{ $tc("recoveryPassword.Confirm") }}</span>
                    </v-btn>
                    <v-btn
                      v-else
                      style="min-height: 75px"
                      outlined
                      class="mod--btn mod--btn_primary white--text mt-4"
                      elevation="1"
                      block
                      small
                      type="submit"
                    >
                      <!--                      @click="checkAnswerValidity"-->
                      <span>{{ $tc("recoveryPassword.Send") }}</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="3"></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <change-password
      v-if="isChangePasswordView"
      :is-change-password-by-recovery="true"
      :answer="answer"
      :question="question"
      :username="username"
    ></change-password>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { authService } from "@/services/api/authenticate.service";
import { CheckAnswerValidity } from "@/models/entities/auth/CheckAnswerValidity.interface";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/modules/snackbar.module";
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import ChangePassword from "@/views/ChangePassword.vue";

const snackbarModule = getModule(SnackbarModule, store);
const authModule = getModule(AuthModule, store);
@Component({
  components: { ChangePassword },
})
export default class RecoveryPassword extends Vue {
  username = "";
  answer = "";
  isChangePasswordView = false;

  question = null;

  async getQuestion() {
    if (!this.username) {
      snackbarModule.appearSnackbar({
        message: this.$tc("recoveryPassword.UsernameRequired"),
        type: "error",
      });
      return;
    }
    const response = await authService.getQuestionByUsername(this.username);
    if (response.data) {
      this.question = response.data;
    }
  }

  async checkAnswerValidity() {
    const request: CheckAnswerValidity = {
      username: this.username,
      question: this.question,
      answer: this.answer,
    };
    const response = await authService.checkAnswerValidity(request);
    if (!response.data.isAnswerValid) {
      snackbarModule.appearSnackbar({
        message: this.$tc("recoveryPassword.WrongAnswer"),
        type: "error",
      });
      return;
    }
    this.isChangePasswordView = true;
  }

  onSubmit() {
    this.question ? this.checkAnswerValidity() : this.getQuestion();
  }

  goToLogin() {
    this.$router.push({ name: "login" });
  }
}
</script>

<style scoped></style>
